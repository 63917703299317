<template>
    <div id="discounts-container"
        v-if="(checkInDiscount || activationDiscount || milageDiscounts || demoPolicyDiscounts)">
        <v-fade-transition hide-on-leave :group="(checkInDiscount != null || activationDiscount  != null)">

            <v-card v-if="activationDiscount || checkInDiscount" key="discounts-checkin">
                <v-card-title>Check-in Rewards</v-card-title>
                <v-card-text>
                    <table>
                        <tr v-if="activationDiscount">
                            <td>1st check-in </td>
                            <th>{{ activationDiscount.amountPerVehicle | currency(currency,0)}}</th>
                        </tr>
                        <tr v-if="checkInDiscount">
                            <td>2nd check-in onward </td>
                            <th>{{ checkInDiscount.amountPerVehicle | currency(currency,0)}}</th>
                        </tr>
                    </table>
                </v-card-text>
            </v-card>

            <v-card v-else>
                You’re at the top of the list when more space becomes available.
                <br />
                <br />
                In the meantime, feel free to take a look around.
                <br />
                You can even try a mileage check-in if you want,
                or wait till you get paid for it!
            </v-card>

            <v-card class="mt-5" v-if="!loading" key="discounts-low-mileage">
                <v-card-title class="" v-if="milageDiscounts && milageDiscounts.length > 0">Additional low mileage
                    Bonus*</v-card-title>
                <v-card-text v-if="milageDiscounts && milageDiscounts.length > 0">
                    <table>
                        <tr>
                            <th>Min</th>
                            <th>Max</th>
                            <th></th>
                        </tr>

                        <tr v-for="discount in milageDiscounts" :key="discount.id" class="discount">
                            <td>
                                {{discount.minMilage | number("0,0")}} {{ policy.discountsProviderReadingUnit }}
                            </td>
                            <td>
                                {{discount.maxMilage | number("0,0")}} {{ policy.discountsProviderReadingUnit }}
                            </td>
                            <td class="text-center">
                                {{ discount.amountPerVehicle | currency(currency,0)}}
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" class="pt-5 opacity">* Starting from 2nd check-in onwards</td>
                        </tr>
                    </table>
                </v-card-text>

            </v-card>

        </v-fade-transition>
    </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import api from "@/services/api-service";

    export default {
        name: "Discounts",
        props: {
            customer: {
                type: Object,
                required: true
            },
        },
        computed: {
            ...mapGetters(["discounts"]),

            policy() {
                return this.customer.policy;
            },
            currency() {
                return this.policy.discountsProviderCurrency;
            },

            demoPolicyDiscounts() {
                const demo = [{
                        max: 250,
                        amountForOneVehicle: 25
                    },
                    {
                        max: 500,
                        amountForOneVehicle: 15
                    },
                    {
                        max: 750,
                        amountForOneVehicle: 10
                    },
                    {
                        max: 1000,
                        amountForOneVehicle: 5
                    },
                ];

                let min_milage = 0;

                for (let index = 0; index < demo.length; index++) {
                    const discount = demo[index];
                    discount.maxMilage = discount.max;
                    discount.minMilage = min_milage;
                    min_milage = discount.maxMilage > 0 ? discount.maxMilage + 1 : 0;
                }

                return demo;
            },

            milageDiscounts() {
                let min_milage = 0;
                let milageDiscounts = this.discounts && this.discounts.length > 0 && this.discounts.filter(d => d
                    .type == "Mileage");

                for (let index = 0; index < milageDiscounts.length; index++) {
                    const discount = milageDiscounts[index];
                    discount.maxMilage = discount.max;
                    discount.minMilage = min_milage;
                    min_milage = discount.maxMilage > 0 ? discount.maxMilage + 1 : 0;
                }

                return milageDiscounts;
            },
            activationDiscount() {
                return this.discounts.find(d => d.type == "Enrollment");
            },
            checkInDiscount() {
                return this.discounts.find(d => d.type == "CheckIn");
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        methods: {
            async load() {
                try {
                    this.loading = true;
                    // Fetch customer by login e-mail:
                    const uri = `/policies/${this.policy.id}/discounts`;
                    const response = await api.get(uri, {
                        requestId: uri
                    });

                    if (response.status == 200 && response.data) {
                        this.$store.commit("UPDATE_DISCOUNTS", response.data);
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "load"
                    }, error);
                } finally {
                    this.loading = false;
                }
            },
        },

        mounted() {
            this.load();
        }
    }
</script>

<style lang="css" scoped>
    .v-card__title {
        font-size: 1rem;
    }

    table {
        width: 100%;
        font-size: 1rem;
        text-align: left;
    }
</style>