import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"390","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"card-center"},[_c(VCardTitle,[_c('h2',[_vm._v("Parked "),_c('span',{staticClass:"opacity-low"},[_vm._v("is now")]),_vm._v(" "),_c('span',{staticClass:"primary--text"},[_vm._v("Ownli!")])])]),_c(VCardText,{staticClass:"my-5"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VAvatar,{attrs:{"size":92}},[_c(VImg,{attrs:{"src":"https://parked.blob.core.windows.net/web/www/logos/parked_dark.svg"}})],1)],1),_c(VCol,[_c(VAvatar,{attrs:{"size":92}},[_c(VImg,{attrs:{"src":"https://parked.blob.core.windows.net/ownli/www/logos/logo.png"}})],1)],1)],1)],1),_c(VCardText,[_c('h2',{staticClass:"mb-4"},[_vm._v("Own your data "),_c('br'),_vm._v(" and earn from it with Ownli.")]),_vm._v(" Don’t worry, nothing changes with your mileage check-ins, mileage rewards, and bonuses. Same app, new look. ")]),_c(VCardActions,{staticClass:"py-5"},[_c(VSpacer),_c(VBtn,{staticClass:"text-initial px-8",attrs:{"x-large":"","text":"","color":"accent","href":"https://www.ownli.app/"}},[_vm._v(" Take me to Ownli app ")]),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }