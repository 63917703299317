<template>

    <svg viewBox="0 0 1450 487" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_294_7090)">
            <rect width="1450" height="487" :fill="color" />
            <path d="M484.076 191.741H420.463V481.542H484.076V191.741Z" :fill="darkenColor" />
            <path d="M472.851 170.116H431.69V293.389H472.851V170.116Z" :fill="darkenColor" />
            <path d="M465.365 157.14H441.043V226.346H465.365V157.14Z" :fill="darkenColor" />
            <path d="M459.754 191.742H444.786V146.326H450.183H455.536H459.754V191.742Z" :fill="darkenColor" />
            <path d="M452.271 172.276H450.4V124.696H451.085H451.736H452.271V172.276Z" :fill="darkenColor" />
            <path d="M485.948 191.741H418.594V193.903H485.948V191.741Z" :fill="darkenColor" />
            <path d="M474.723 167.951H429.82V172.277H474.723V167.951Z" :fill="darkenColor" />
            <path opacity="0.5" d="M439.175 174.44H435.434V187.417H439.175V174.44Z" :fill="color" />
            <path opacity="0.5" d="M446.659 174.44H442.917V187.417H446.659V174.44Z" :fill="color" />
            <path opacity="0.5" d="M454.142 174.44H450.4V187.417H454.142V174.44Z" :fill="color" />
            <path opacity="0.5" d="M461.625 174.44H457.883V187.417H461.625V174.44Z" :fill="color" />
            <path opacity="0.5" d="M469.108 174.44H465.366V187.417H469.108V174.44Z" :fill="color" />
            <path opacity="0.5" d="M431.689 202.556H426.077V217.695H431.689V202.556Z" :fill="color" />
            <path opacity="0.5" d="M441.046 202.556H435.434V217.695H441.046V202.556Z" :fill="color" />
            <path opacity="0.5" d="M450.399 202.556H444.786V217.695H450.399V202.556Z" :fill="color" />
            <path opacity="0.5" d="M459.752 202.556H454.139V217.695H459.752V202.556Z" :fill="color" />
            <path opacity="0.5" d="M469.109 202.556H463.496V217.695H469.109V202.556Z" :fill="color" />
            <path opacity="0.5" d="M478.462 202.556H472.849V217.695H478.462V202.556Z" :fill="color" />
            <path opacity="0.5" d="M431.689 228.506H426.077V241.483H431.689V228.506Z" :fill="color" />
            <path opacity="0.5" d="M441.046 228.506H435.434V241.483H441.046V228.506Z" :fill="color" />
            <path opacity="0.5" d="M450.399 228.506H444.786V241.483H450.399V228.506Z" :fill="color" />
            <path opacity="0.5" d="M459.752 228.506H454.139V241.483H459.752V228.506Z" :fill="color" />
            <path opacity="0.5" d="M469.109 228.506H463.496V241.483H469.109V228.506Z" :fill="color" />
            <path opacity="0.5" d="M478.462 228.506H472.849V241.483H478.462V228.506Z" :fill="color" />
            <path opacity="0.5" d="M431.689 250.136H426.077V265.275H431.689V250.136Z" :fill="color" />
            <path opacity="0.5" d="M441.046 250.136H435.434V265.275H441.046V250.136Z" :fill="color" />
            <path opacity="0.5" d="M450.399 250.136H444.786V265.275H450.399V250.136Z" :fill="color" />
            <path opacity="0.5" d="M459.752 250.136H454.139V265.275H459.752V250.136Z" :fill="color" />
            <path opacity="0.5" d="M469.109 250.136H463.496V265.275H469.109V250.136Z" :fill="color" />
            <path opacity="0.5" d="M478.462 250.136H472.849V265.275H478.462V250.136Z" :fill="color" />
            <path opacity="0.5" d="M431.689 273.925H426.077V289.064H431.689V273.925Z" :fill="color" />
            <path opacity="0.5" d="M441.046 273.925H435.434V289.064H441.046V273.925Z" :fill="color" />
            <path opacity="0.5" d="M450.399 273.925H444.786V289.064H450.399V273.925Z" :fill="color" />
            <path opacity="0.5" d="M459.752 273.925H454.139V289.064H459.752V273.925Z" :fill="color" />
            <path opacity="0.5" d="M469.109 273.925H463.496V289.064H469.109V273.925Z" :fill="color" />
            <path opacity="0.5" d="M478.462 273.925H472.849V289.064H478.462V273.925Z" :fill="color" />
            <path opacity="0.5" d="M431.689 297.715H426.077V312.854H431.689V297.715Z" :fill="color" />
            <path opacity="0.5" d="M441.046 297.715H435.434V312.854H441.046V297.715Z" :fill="color" />
            <path opacity="0.5" d="M450.399 297.715H444.786V312.854H450.399V297.715Z" :fill="color" />
            <path opacity="0.5" d="M459.752 297.715H454.139V312.854H459.752V297.715Z" :fill="color" />
            <path opacity="0.5" d="M469.109 297.715H463.496V312.854H469.109V297.715Z" :fill="color" />
            <path opacity="0.5" d="M478.462 297.715H472.849V312.854H478.462V297.715Z" :fill="color" />
            <path d="M431.689 321.501H426.077V336.64H431.689V321.501Z" :fill="darkenColorExtra" />
            <path d="M441.046 321.501H435.434V336.64H441.046V321.501Z" :fill="darkenColorExtra" />
            <path d="M450.399 321.501H444.786V336.64H450.399V321.501Z" :fill="darkenColorExtra" />
            <path opacity="0.5" d="M459.752 321.501H454.139V336.64H459.752V321.501Z" :fill="color" />
            <path opacity="0.5" d="M469.109 321.501H463.496V336.64H469.109V321.501Z" :fill="color" />
            <path opacity="0.5" d="M478.462 321.501H472.849V336.64H478.462V321.501Z" :fill="color" />
            <path d="M431.689 345.292H426.077V360.43H431.689V345.292Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M441.046 345.292H435.434V360.43H441.046V345.292Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M450.399 345.292H444.786V360.43H450.399V345.292Z" :fill="darkenColorExtra" />
            <path opacity="0.5" d="M459.752 345.292H454.139V360.43H459.752V345.292Z" :fill="color" />
            <path opacity="0.5" d="M469.109 345.292H463.496V360.43H469.109V345.292Z" :fill="color" />
            <path opacity="0.5" d="M478.462 345.292H472.849V360.43H478.462V345.292Z" :fill="color" />
            <path d="M431.689 369.082H426.077V384.22H431.689V369.082Z" :fill="darkenColorExtra" />
            <path d="M441.046 369.082H435.434V384.22H441.046V369.082Z" :fill="darkenColorExtra" />
            <path d="M450.399 369.082H444.786V384.22H450.399V369.082Z" :fill="darkenColorExtra" />
            <path d="M459.752 369.082H454.139V384.22H459.752V369.082Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M469.108 369.082H465.366V384.22H469.108V369.082Z" :fill="darkenColorExtra" />
            <path d="M478.462 369.082H472.849V384.22H478.462V369.082Z" :fill="darkenColorExtra" />
            <path d="M431.689 392.871H426.077V408.01H431.689V392.871Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M441.046 392.871H435.434V408.01H441.046V392.871Z" :fill="darkenColorExtra" />
            <path d="M450.399 392.871H444.786V408.01H450.399V392.871Z" :fill="darkenColorExtra" />
            <path d="M459.752 392.871H454.139V408.01H459.752V392.871Z" :fill="darkenColorExtra" />
            <path d="M469.108 392.871H465.366V408.01H469.108V392.871Z" :fill="darkenColorExtra" />
            <path d="M478.462 392.871H472.849V408.01H478.462V392.871Z" :fill="darkenColorExtra" />
            <path d="M431.689 416.661H426.077V431.8H431.689V416.661Z" :fill="darkenColorExtra" />
            <path d="M441.046 416.661H435.434V431.8H441.046V416.661Z" :fill="darkenColorExtra" />
            <path d="M450.399 416.661H444.786V431.8H450.399V416.661Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M459.752 416.661H454.139V431.8H459.752V416.661Z" :fill="darkenColorExtra" />
            <path d="M469.108 416.661H465.366V431.8H469.108V416.661Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M478.462 416.661H472.849V431.8H478.462V416.661Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M431.689 440.451H426.077V455.59H431.689V440.451Z" :fill="darkenColorExtra" />
            <path d="M441.046 440.451H435.434V455.59H441.046V440.451Z" :fill="darkenColorExtra" />
            <path opacity="0.29" d="M450.399 440.451H444.786V455.59H450.399V440.451Z" :fill="darkenColorExtra" />
            <path d="M459.752 440.451H454.139V455.59H459.752V440.451Z" :fill="darkenColorExtra" />
            <path d="M469.108 440.451H465.366V455.59H469.108V440.451Z" :fill="darkenColorExtra" />
            <path d="M478.462 440.451H472.849V455.59H478.462V440.451Z" :fill="darkenColorExtra" />
            <path d="M338.144 202.556H237.113V446.94H338.144V202.556Z" :fill="darkenColor" />
            <path d="M340.015 198.23H237.113V204.719H340.015V198.23Z" :fill="darkenColor" />
            <path d="M336.272 189.581H238.983V222.021H336.272V189.581Z" :fill="darkenColor" />
            <path d="M283.885 176.601H246.466V211.204H283.885V176.601Z" :fill="darkenColor" />
            <path d="M268.917 185.255H246.466V165.791H262.561L268.917 185.255Z" :fill="darkenColor" />
            <path d="M250.207 150.651H248.336V193.905H250.207V150.651Z" :fill="darkenColor" />
            <path d="M1183.81 466.403H1056.59V271.761L1183.81 271.761V466.403Z" :fill="darkenColor" />
            <path
                d="M726.282 259.018V138.054H709.165V105.641H703.055V58.1133H669.358H666.365H659.763V42.5156H656.77V58.1133H635.477V138.054H624.47V259.018H615.044V479.38H732.914V259.018H726.282Z"
                :fill="darkenColor" />
            <path d="M878.848 490.194H818.978L842.299 351.781H878.848V490.194Z" :fill="darkenColor" />
            <path
                d="M830.179 238.222V177.826H811.224V154.822H801.087V135.511H797.525L796.615 135.511L793.053 135.511V154.822H782.917V177.826H764.103V238.222H760.196V347.883H731.042V472.891H760.196H789.35H833.944V238.222H830.179Z"
                :fill="darkenColor" />
            <path d="M938.717 306.366H847.04V308.293H851.181V488.032H934.576V308.293H938.717V306.366Z"
                :fill="darkenColor" />
            <path d="M521.494 351.781H441.043V501.007H521.494V351.781Z" :fill="darkenColorExtra" />
            <path d="M426.075 429.637H287.625V501.006H426.075V429.637Z" :fill="darkenColorExtra" />
            <path d="M394.27 265.271H362.464V444.775H394.27V265.271Z" :fill="darkenColorExtra" />
            <path d="M383.046 254.46H366.208V278.25H383.046V254.46Z" :fill="darkenColorExtra" />
            <path d="M633.752 247.971H534.592V501.007H633.752V247.971Z" :fill="darkenColorExtra" />
            <path d="M624.399 239.321H543.949V280.412H624.399V239.321Z" :fill="darkenColorExtra" />
            <path d="M641.236 254.46H534.592V258.786H641.236V254.46Z" :fill="darkenColorExtra" />
            <path d="M562.656 373.406H515.882V501.005H562.656V373.406Z" :fill="darkenColorExtra" />
            <path d="M231.498 369.082H177.24V501.006H231.498V369.082Z" :fill="darkenColorExtra" />
            <path d="M302.594 332.316H195.95V501.007H302.594V332.316Z" :fill="darkenColorExtra" />
            <path d="M306.336 325.831H195.95V332.319H306.336V325.831Z" :fill="darkenColorExtra" />
            <path d="M246.467 308.526H199.694V343.13H246.467V308.526Z" :fill="darkenColorExtra" />
            <path d="M270.79 308.526H263.306V330.153H270.79V308.526Z" :fill="darkenColorExtra" />
            <path d="M287.627 308.526H278.272V330.153H287.627V308.526Z" :fill="darkenColorExtra" />
            <path d="M152.919 429.637H124.855V501.006H152.919V429.637Z" :fill="darkenColorExtra" />
            <path d="M139.821 475.056H94.9182V501.008H139.821V475.056Z" :fill="darkenColorExtra" />
            <path d="M1187.55 444.776H630.01V501.006H1187.55V444.776Z" :fill="darkenColor" />
            <path d="M1247.42 351.781H1159.49V501.007H1247.42V351.781Z" :fill="darkenColor" />
            <path d="M1322.26 412.336H1234.33V498.844H1322.26V412.336Z" :fill="darkenColor" />
            <path d="M944.33 395.036H897.557V472.893H944.33V395.036Z" fill="#95A9A7" />
            <path d="M1266.13 213.37H1202.52V501.009H1266.13V213.37Z" :fill="darkenColor" />
            <path d="M1367.16 501.007H1284.84V332.316H1347.25L1367.16 501.007Z" :fill="darkenColor" />
            <path d="M1318.52 319.341H1288.58V345.293H1318.52V319.341Z" :fill="darkenColor" />
            <path d="M1314.78 308.526H1292.33V323.665H1314.78V308.526Z" :fill="darkenColor" />
            <path d="M970.524 278.25H897.557V552.913H970.524V278.25Z" :fill="darkenColorExtra" />
            <path d="M978.006 271.761H886.33V280.412H978.006V271.761Z" :fill="darkenColorExtra" />
            <path d="M927.49 260.946H899.426V276.085H927.49V260.946Z" :fill="darkenColorExtra" />
            <path d="M927.49 260.946H899.426V263.109H927.49V260.946Z" :fill="darkenColorExtra" />
            <path d="M790.913 552.912H691.753V290.973L790.913 263.111V552.912Z" :fill="darkenColorExtra" />
            <path d="M697.364 286.901H688.009V552.912H697.364V286.901Z" :fill="darkenColorExtra" />
            <path d="M721.687 280.411H712.332V552.911H721.687V280.411Z" :fill="darkenColorExtra" />
            <path d="M746.01 271.761H736.656V552.911H746.01V271.761Z" :fill="darkenColorExtra" />
            <path d="M770.329 265.271H760.975V552.91H770.329V265.271Z" :fill="darkenColorExtra" />
            <path d="M794.652 258.786H785.298V552.912H794.652V258.786Z" :fill="darkenColorExtra" />
            <path
                d="M410.968 302.041V304.779H408.58V312.576H450.4V552.913H381.788V325.119V312.576V304.779H379.304V302.041H410.968Z"
                :fill="darkenColorExtra" />
            <path d="M583.238 412.336H525.239V552.911H583.238V412.336Z" :fill="darkenColorExtra" />
            <path d="M321.304 379.896H272.659V552.912H321.304V379.896Z" :fill="darkenColorExtra" />
            <path d="M362.464 416.661H311.948V552.911H362.464V416.661Z" :fill="darkenColorExtra" />
            <path d="M311.949 373.406H291.369V395.033H311.949V373.406Z" :fill="darkenColorExtra" />
            <path d="M369.951 412.336H313.822V418.824H369.951V412.336Z" :fill="darkenColorExtra" />
            <path d="M414.85 475.056H356.851V552.913H414.85V475.056Z" :fill="darkenColorExtra" />
            <path d="M218.401 412.336H171.627V552.911H218.401V412.336Z" :fill="darkenColorExtra" />
            <path d="M192.209 481.542H130.468V552.91H192.209V481.542Z" :fill="darkenColorExtra" />
            <path d="M100.531 470.731H63.112V552.913H100.531V470.731Z" :fill="darkenColorExtra" />
            <path d="M540.204 464.241H496.812L478.462 542.098H540.204V464.241Z" :fill="darkenColorExtra" />
            <path d="M577.625 390.711H557.045V414.501H577.625V390.711Z" :fill="darkenColorExtra" />
            <path d="M583.237 386.386H553.301V390.711H583.237V386.386Z" :fill="darkenColorExtra" />
            <path d="M643.106 479.381H573.881V552.912H643.106V479.381Z" :fill="darkenColorExtra" />
            <path d="M674.913 399.361H630.01V542.099H674.913V399.361Z" :fill="darkenColorExtra" />
            <path d="M878.844 427.476H805.877V552.912H878.844V427.476Z" :fill="darkenColorExtra" />
            <path d="M991.101 552.911H1099.62V384.221H991.101V552.911Z" :fill="darkenColorExtra" />
            <path d="M987.36 384.221H1105.23V379.896H987.36V384.221Z" :fill="darkenColorExtra" />
            <path d="M1045.36 395.036H1094.01V362.595H1045.36V395.036Z" :fill="darkenColorExtra" />
            <path d="M1022.91 379.896H1032.26V362.594H1022.91V379.896Z" :fill="darkenColorExtra" />
            <path d="M1172.58 552.911H1258.65V308.527H1172.58V552.911Z" :fill="darkenColorExtra" />
            <path d="M1170.72 310.691H1260.52V302.04H1170.72V310.691Z" :fill="darkenColorExtra" />
            <path d="M1176.33 327.991H1256.78V293.388H1176.33V327.991Z" :fill="darkenColorExtra" />
            <path d="M1217.49 315.016H1249.29V280.412H1217.49V315.016Z" :fill="darkenColorExtra" />
            <path d="M1230.58 291.225H1249.29V271.761H1235.88L1230.58 291.225Z" :fill="darkenColorExtra" />
            <path
                d="M1239.94 253.605C1240.02 253.803 1240.19 253.986 1240.44 254.131C1240.68 254.277 1241 254.38 1241.34 254.428C1241.69 254.476 1242.05 254.468 1242.39 254.404C1242.72 254.34 1243.02 254.223 1243.25 254.066C1243.47 253.91 1243.62 253.72 1243.66 253.518C1243.71 253.317 1243.66 253.112 1243.51 252.927C1243.36 252.743 1243.13 252.585 1242.83 252.474C1242.53 252.362 1242.19 252.3 1241.83 252.296C1241.28 252.331 1240.78 252.488 1240.43 252.732C1240.07 252.976 1239.9 253.289 1239.94 253.605Z"
                fill="#658280" />
            <path d="M1284.84 451.266H1247.42V552.913H1284.84V451.266Z" :fill="darkenColorExtra" />
            <path d="M1183.81 401.521H1138.91V552.91H1183.81V401.521Z" :fill="darkenColorExtra" />
            <path d="M1159.49 552.911H1099.62V472.891L1150.14 499.253L1159.49 552.911Z" :fill="darkenColorExtra" />
            <path d="M1385.87 379.896H1331.62V552.912H1385.87V379.896Z" :fill="darkenColorExtra" />
            <path d="M1407.63 444.776H1384V524.796H1417.68L1407.63 444.776Z" :fill="darkenColorExtra" />
            <path opacity="0.52" d="M282.014 388.546H278.272V392.872H282.014V388.546Z" :fill="color" />
            <path opacity="0.52" d="M289.497 388.546H285.755V392.872H289.497V388.546Z" :fill="color" />
            <path opacity="0.52" d="M282.014 397.196H278.272V401.522H282.014V397.196Z" :fill="color" />
            <path opacity="0.52" d="M289.497 397.196H285.755V401.522H289.497V397.196Z" :fill="color" />
            <path opacity="0.52" d="M282.014 408.011H278.272V412.337H282.014V408.011Z" :fill="color" />
            <path opacity="0.52" d="M296.98 388.546H293.239V392.872H296.98V388.546Z" :fill="color" />
            <path opacity="0.5" d="M543.947 265.271H540.205V280.41H543.947V265.271Z" :fill="color" />
            <path opacity="0.5" d="M551.43 265.271H547.688V280.41H551.43V265.271Z" :fill="color" />
            <path opacity="0.5" d="M558.913 265.271H555.171V280.41H558.913V265.271Z" :fill="color" />
            <path opacity="0.5" d="M568.27 265.271H564.528V280.41H568.27V265.271Z" :fill="color" />
            <path opacity="0.5" d="M575.753 265.271H572.011V280.41H575.753V265.271Z" :fill="color" />
            <path opacity="0.5" d="M583.236 265.271H579.494V280.41H583.236V265.271Z" :fill="color" />
            <path opacity="0.5" d="M594.463 265.271H590.721V280.41H594.463V265.271Z" :fill="color" />
            <path opacity="0.5" d="M601.946 265.271H598.204V280.41H601.946V265.271Z" :fill="color" />
            <path opacity="0.5" d="M609.429 265.271H605.687V280.41H609.429V265.271Z" :fill="color" />
            <path opacity="0.5" d="M616.912 265.271H613.17V280.41H616.912V265.271Z" :fill="color" />
            <path opacity="0.5" d="M626.269 265.271H622.527V280.41H626.269V265.271Z" :fill="color" />
            <path opacity="0.5" d="M551.43 286.901H547.688V302.04H551.43V286.901Z" :fill="color" />
            <path opacity="0.5" d="M558.913 286.901H555.171V302.04H558.913V286.901Z" :fill="color" />
            <path opacity="0.5" d="M568.27 286.901H564.528V302.04H568.27V286.901Z" :fill="color" />
            <path opacity="0.5" d="M575.753 286.901H572.011V302.04H575.753V286.901Z" :fill="color" />
            <path opacity="0.5" d="M583.236 286.901H579.494V302.04H583.236V286.901Z" :fill="color" />
            <path opacity="0.5" d="M594.463 286.901H590.721V302.04H594.463V286.901Z" :fill="color" />
            <path opacity="0.5" d="M601.946 286.901H598.204V302.04H601.946V286.901Z" :fill="color" />
            <path opacity="0.5" d="M609.429 286.901H605.687V302.04H609.429V286.901Z" :fill="color" />
            <path opacity="0.5" d="M616.912 286.901H613.17V302.04H616.912V286.901Z" :fill="color" />
            <path opacity="0.5" d="M626.269 286.901H622.527V302.04H626.269V286.901Z" :fill="color" />
            <path opacity="0.5" d="M568.27 308.526H564.528V323.665H568.27V308.526Z" :fill="color" />
            <path opacity="0.5" d="M575.753 308.526H572.011V323.665H575.753V308.526Z" :fill="color" />
            <path opacity="0.5" d="M583.236 308.526H579.494V323.665H583.236V308.526Z" :fill="color" />
            <path opacity="0.5" d="M594.463 308.526H590.721V323.665H594.463V308.526Z" :fill="color" />
            <path opacity="0.5" d="M601.946 308.526H598.204V323.665H601.946V308.526Z" :fill="color" />
            <path opacity="0.5" d="M609.429 308.526H605.687V323.665H609.429V308.526Z" :fill="color" />
            <path opacity="0.5" d="M616.912 308.526H613.17V323.665H616.912V308.526Z" :fill="color" />
            <path opacity="0.5" d="M626.269 308.526H622.527V323.665H626.269V308.526Z" :fill="color" />
            <path opacity="0.5" d="M594.463 330.156H590.721V345.295H594.463V330.156Z" :fill="color" />
            <path opacity="0.5" d="M601.946 330.156H598.204V345.295H601.946V330.156Z" :fill="color" />
            <path opacity="0.5" d="M609.429 330.156H605.687V345.295H609.429V330.156Z" :fill="color" />
            <path opacity="0.5" d="M616.912 330.156H613.17V345.295H616.912V330.156Z" :fill="color" />
            <path opacity="0.5" d="M626.269 330.156H622.527V345.295H626.269V330.156Z" :fill="color" />
            <path opacity="0.5" d="M609.429 351.781H605.687V369.083H609.429V351.781Z" :fill="color" />
            <path opacity="0.5" d="M616.912 351.781H613.17V369.083H616.912V351.781Z" :fill="color" />
            <path opacity="0.5" d="M626.269 351.781H622.527V369.083H626.269V351.781Z" :fill="color" />
            <path opacity="0.33" d="M704.849 302.041H699.236V319.342H704.849V302.041Z" :fill="color" />
            <path opacity="0.33" d="M704.849 334.48H699.236V351.782H704.849V334.48Z" :fill="color" />
            <path opacity="0.33" d="M704.849 366.921H699.236V384.222H704.849V366.921Z" :fill="color" />
            <path opacity="0.33" d="M704.849 399.361H699.236V416.662H704.849V399.361Z" :fill="color" />
            <path opacity="0.33" d="M704.849 429.637H699.236V446.938H704.849V429.637Z" :fill="color" />
            <path opacity="0.33" d="M704.849 462.077H699.236V479.378H704.849V462.077Z" :fill="color" />
            <path opacity="0.33" d="M725.428 302.041H719.816V319.342H725.428V302.041Z" :fill="color" />
            <path opacity="0.33" d="M725.428 334.48H719.816V351.782H725.428V334.48Z" :fill="color" />
            <path opacity="0.33" d="M725.428 366.921H719.816V384.222H725.428V366.921Z" :fill="color" />
            <path opacity="0.33" d="M725.428 399.361H719.816V416.662H725.428V399.361Z" :fill="color" />
            <path opacity="0.33" d="M725.428 429.637H719.816V446.938H725.428V429.637Z" :fill="color" />
            <path opacity="0.33" d="M725.428 462.077H719.816V479.378H725.428V462.077Z" :fill="color" />
            <path opacity="0.33" d="M755.363 302.041H740.395V319.342H755.363V302.041Z" :fill="color" />
            <path opacity="0.33" d="M755.363 334.48H740.395V351.782H755.363V334.48Z" :fill="color" />
            <path opacity="0.33" d="M755.363 366.921H740.395V384.222H755.363V366.921Z" :fill="color" />
            <path opacity="0.33" d="M755.363 399.361H740.395V416.662H755.363V399.361Z" :fill="color" />
            <path opacity="0.33" d="M755.363 429.637H740.395V446.938H755.363V429.637Z" :fill="color" />
            <path opacity="0.33" d="M755.363 462.077H740.395V479.378H755.363V462.077Z" :fill="color" />
            <path opacity="0.33" d="M781.556 302.041H766.588V319.342H781.556V302.041Z" :fill="color" />
            <path opacity="0.33" d="M781.556 334.48H766.588V351.782H781.556V334.48Z" :fill="color" />
            <path opacity="0.33" d="M781.556 366.921H766.588V384.222H781.556V366.921Z" :fill="color" />
            <path opacity="0.33" d="M781.556 399.361H766.588V416.662H781.556V399.361Z" :fill="color" />
            <path opacity="0.33" d="M781.556 429.637H766.588V446.938H781.556V429.637Z" :fill="color" />
            <path opacity="0.33" d="M781.556 462.077H766.588V479.378H781.556V462.077Z" :fill="color" />
            <path opacity="0.5" d="M646.85 118.21H641.237V131.187H646.85V118.21Z" :fill="color" />
            <path opacity="0.5" d="M658.072 118.21H652.46V131.187H658.072V118.21Z" :fill="color" />
            <path opacity="0.5" d="M646.85 87.9312H641.237V100.907H646.85V87.9312Z" :fill="color" />
            <path opacity="0.5" d="M658.072 87.9312H652.46V100.907H658.072V87.9312Z" :fill="color" />
            <path opacity="0.4" d="M296.98 217.695H293.239V230.671H296.98V217.695Z" :fill="color" />
            <path opacity="0.4" d="M308.207 217.695H304.465V230.671H308.207V217.695Z" :fill="color" />
            <path opacity="0.4" d="M319.434 217.695H315.692V230.671H319.434V217.695Z" :fill="color" />
            <path opacity="0.4" d="M330.66 217.695H326.918V230.671H330.66V217.695Z" :fill="color" />
            <path opacity="0.4" d="M274.531 217.695H270.789V230.671H274.531V217.695Z" :fill="color" />
            <path opacity="0.4" d="M285.758 217.695H282.016V230.671H285.758V217.695Z" :fill="color" />
            <path opacity="0.4" d="M296.98 241.485H293.239V254.462H296.98V241.485Z" :fill="color" />
            <path opacity="0.4" d="M308.207 241.485H304.465V254.462H308.207V241.485Z" :fill="color" />
            <path opacity="0.4" d="M319.434 241.485H315.692V254.462H319.434V241.485Z" :fill="color" />
            <path opacity="0.4" d="M330.66 241.485H326.918V254.462H330.66V241.485Z" :fill="color" />
            <path opacity="0.4" d="M274.531 241.485H270.789V254.462H274.531V241.485Z" :fill="color" />
            <path opacity="0.4" d="M285.758 241.485H282.016V254.462H285.758V241.485Z" :fill="color" />
            <path opacity="0.4" d="M296.98 265.271H293.239V278.248H296.98V265.271Z" :fill="color" />
            <path opacity="0.4" d="M308.207 265.271H304.465V278.248H308.207V265.271Z" :fill="color" />
            <path opacity="0.4" d="M319.434 265.271H315.692V278.248H319.434V265.271Z" :fill="color" />
            <path opacity="0.4" d="M330.66 265.271H326.918V278.248H330.66V265.271Z" :fill="color" />
            <path opacity="0.4" d="M274.531 265.271H270.789V278.248H274.531V265.271Z" :fill="color" />
            <path opacity="0.4" d="M285.758 265.271H282.016V278.248H285.758V265.271Z" :fill="color" />
            <path opacity="0.5" d="M862.006 321.501H856.393V343.128H862.006V321.501Z" :fill="color" />
            <path opacity="0.5" d="M890.073 321.501H884.46V343.128H890.073V321.501Z" :fill="color" />
            <path opacity="0.33" d="M1185.68 325.831H1183.81V336.644H1185.68V325.831Z" fill="white" />
            <path opacity="0.33" d="M1196.91 325.831H1193.17V336.644H1196.91V325.831Z" fill="white" />
            <path opacity="0.33" d="M1185.68 325.831H1183.81V336.644H1185.68V325.831Z" :fill="color" />
            <path opacity="0.33" d="M1196.91 325.831H1193.17V336.644H1196.91V325.831Z" :fill="color" />
            <path opacity="0.33" d="M1208.13 325.831H1202.52V336.644H1208.13V325.831Z" :fill="color" />
            <path opacity="0.33" d="M1217.49 325.831H1213.74V336.644H1217.49V325.831Z" :fill="color" />
            <path opacity="0.33" d="M1185.68 345.292H1183.81V358.268H1185.68V345.292Z" fill="white" />
            <path opacity="0.33" d="M1196.91 345.292H1193.17V358.268H1196.91V345.292Z" fill="white" />
            <path opacity="0.33" d="M1185.68 345.292H1183.81V358.268H1185.68V345.292Z" :fill="color" />
            <path opacity="0.33" d="M1196.91 345.292H1193.17V358.268H1196.91V345.292Z" :fill="color" />
            <path opacity="0.33" d="M1208.13 345.292H1202.52V358.268H1208.13V345.292Z" :fill="color" />
            <path opacity="0.33" d="M1217.49 345.292H1213.74V358.268H1217.49V345.292Z" :fill="color" />
            <path opacity="0.33" d="M1185.68 369.082H1183.81V379.895H1185.68V369.082Z" fill="white" />
            <path opacity="0.33" d="M1196.91 369.082H1193.17V379.895H1196.91V369.082Z" fill="white" />
            <path opacity="0.33" d="M1185.68 369.082H1183.81V379.895H1185.68V369.082Z" :fill="color" />
            <path opacity="0.33" d="M1196.91 369.082H1193.17V379.895H1196.91V369.082Z" :fill="color" />
            <path opacity="0.33" d="M1208.13 369.082H1202.52V379.895H1208.13V369.082Z" :fill="color" />
            <path opacity="0.33" d="M1217.49 369.082H1213.74V379.895H1217.49V369.082Z" :fill="color" />
            <path opacity="0.33" d="M1230.59 325.831H1228.71V336.644H1230.59V325.831Z" :fill="color" />
            <path opacity="0.33" d="M1230.59 345.292H1228.71V358.268H1230.59V345.292Z" :fill="color" />
            <path opacity="0.33" d="M1241.81 325.831H1239.94V336.644H1241.81V325.831Z" :fill="color" />
            <path opacity="0.33" d="M906.912 291.226H903.17V308.527H906.912V291.226Z" :fill="color" />
            <path opacity="0.33" d="M925.621 291.226H921.88V308.527H925.621V291.226Z" :fill="color" />
            <path opacity="0.33" d="M944.331 291.226H940.589V308.527H944.331V291.226Z" :fill="color" />
            <path opacity="0.33" d="M964.908 291.226H959.295V308.527H964.908V291.226Z" :fill="color" />
            <path opacity="0.33" d="M906.912 330.156H903.17V345.295H906.912V330.156Z" :fill="color" />
            <path opacity="0.33" d="M925.621 330.156H921.88V345.295H925.621V330.156Z" :fill="color" />
            <path opacity="0.33" d="M944.331 330.156H940.589V345.295H944.331V330.156Z" :fill="color" />
            <path opacity="0.33" d="M964.908 330.156H959.295V345.295H964.908V330.156Z" :fill="color" />
            <path opacity="0.33" d="M906.912 366.921H903.17V386.385H906.912V366.921Z" :fill="color" />
            <path opacity="0.33" d="M925.621 366.921H921.88V386.385H925.621V366.921Z" :fill="color" />
            <path opacity="0.33" d="M944.331 366.921H940.589V386.385H944.331V366.921Z" :fill="color" />
            <path opacity="0.33" d="M964.908 366.921H959.295V386.385H964.908V366.921Z" :fill="color" />
            <path opacity="0.33" d="M906.912 405.847H903.17V425.311H906.912V405.847Z" :fill="color" />
            <path opacity="0.33" d="M925.621 405.847H921.88V425.311H925.621V405.847Z" :fill="color" />
            <path opacity="0.33" d="M944.331 405.847H940.589V425.311H944.331V405.847Z" :fill="color" />
            <path opacity="0.33" d="M964.908 405.847H959.295V425.311H964.908V405.847Z" :fill="color" />
            <path opacity="0.33" d="M906.912 442.616H903.17V459.917H906.912V442.616Z" :fill="color" />
            <path opacity="0.33" d="M925.621 442.616H921.88V459.917H925.621V442.616Z" :fill="color" />
            <path opacity="0.33" d="M944.331 442.616H940.589V459.917H944.331V442.616Z" :fill="color" />
            <path opacity="0.33" d="M906.912 479.381H903.17V498.845H906.912V479.381Z" :fill="color" />
            <path opacity="0.33" d="M925.621 479.381H921.88V498.845H925.621V479.381Z" :fill="color" />
            <path opacity="0.33" d="M944.331 479.381H940.589V498.845H944.331V479.381Z" :fill="color" />
            <path opacity="0.33" d="M964.908 442.616H959.295V459.917H964.908V442.616Z" :fill="color" />
            <path opacity="0.33" d="M1342.84 388.546H1337.23V397.197H1342.84V388.546Z" :fill="color" />
            <path opacity="0.33" d="M1342.84 405.847H1337.23V414.498H1342.84V405.847Z" :fill="color" />
            <path opacity="0.33" d="M1352.2 388.546H1346.58V397.197H1352.2V388.546Z" :fill="color" />
        </g>
        <defs>
            <clipPath id="clip0_294_7090">
                <rect width="1450" height="487" fill="white" />
            </clipPath>
        </defs>
    </svg>


</template>

<script>
    function shadeColor(color, percent) {

        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    export default {
        name: "LoginPageSVG",

        props: {
            color: {
                type: String,
                default: "#FFD51C"
            },
        },

        computed: {
            darkenColor() {
                return shadeColor(this.color, -15);
            },
            darkenColorExtra() {
                return shadeColor(this.color, -30);
            },
        },
    }
</script>