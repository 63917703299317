<template>
    <v-btn v-bind="$attrs" @click="share">
        <slot name="icon"></slot>

        <navigator-share :url="shareUrl" title="Know Your Worth!"
            text="Hey, I just earned money with Ownli. You can too...it’s easy." id="btn_share">

            <template v-slot:clickable>
                <slot name="label">share with friends</slot>
            </template>

        </navigator-share>
    </v-btn>
</template>

<script>
    import NavigatorShare from 'vue-navigator-share';

    export default {
        inheritAttrs: false,
        name: "BtnAffiliateShare",

        components: {
            NavigatorShare,
        },

        props: {
            shareUrl: {
                type: String,
                default: "https://www.ownli.co/"
            },
        },

        methods: {
            share() {
                this.$Logger.track(`share_with_friends`, {
                    component: this.$options.name,
                    url: this.shareUrl
                });
            }
        },
    }
</script>

<style lang="css" scoped>

</style>