<template>
    <v-card v-bind="$attrs" elevation="1">
        <v-list-item :three-line="lines == 3" :two-line="lines == 2">
            <v-list-item-avatar tile :size="30">
                <slot name="avatar"></slot>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>
                    <div>
                        <slot name="title"></slot>
                    </div>
                    <v-spacer />
                    <div class="date">
                        <slot name="date"></slot>
                    </div>
                </v-list-item-title>

                <v-list-item-subtitle>
                    <slot name="subtitle"></slot>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-card-actions v-if="hasActions">
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "TimelineCard",
        inheritAttrs: false,

        props: {
            hasActions: {
                type: Boolean,
                default: false
            },
            lines: {
                type: Number,
                default: 2
            }
        },
    }
</script>

<style scoped>
    .v-list-item__title {
        display: flex;
        align-items: center;
    }

    .v-list-item__title>.date {
        font-size: 0.7rem;
    }
</style>