<template>
    <div id="rewards-summary-container">
        <div class="confetti-bck-wrapper" v-if="summary.total && (policy.status == 'Pending' || policy.status == 'Activated') ">
            <RewardsConfettiSVG />
        </div>

        <v-card id="summary" class="card-center" :loading="loading">
            <template v-if="policy.status == 'Pending' || policy.status == 'Activated'">
                <v-card-text class="pt-6 pb-0">
                    <h2>Total earned: {{ summary.total | currency(currency,2)  }}</h2>
                    <span class="mt-2">Member since {{ customer.dateRegisteredAt | moment("MMMM YYYY") }}</span>
                </v-card-text>

                <v-card-text v-if="summary.confirmed">
                    <h2 class="opacity">Approved rewards:{{summary.confirmed | currency(currency,2)  }}</h2>
                    <span class="opacity mt-2">Pending approval:{{summary.pending | currency(currency,2)  }}</span>
                </v-card-text>
                <v-card-text v-else>
                    <h2 class="opacity">Pending approval:{{summary.pending | currency(currency,2)  }}</h2>
                    <span class="opacity mt-2">Takes up to 1 business day</span>
                </v-card-text>
            </template>

            <v-card-text class="pt-15 mt-5" v-else-if="policy.status != 'Declined'">
                <h1 class="opacity-low">Validating policy</h1>
                <h3 class="opacity mt-4 font-weight-regular">Takes up to 1 business day</h3>
            </v-card-text>

            <v-card-text class="pt-15 mt-5" v-else>
                <h1 class="opacity-low">Policy Declined</h1>
                <h4 class="opacity mt-4 font-weight-regular">Please contact us if you have any questions.</h4>
                <div class="mt-2"><a target="_blank" href="https://joinparked.zendesk.com/hc/en-us/requests/new">Contact us</a></div>
            </v-card-text>
        </v-card>


        <template v-if="summary && policy.status != 'Declined'">
            <v-card flat color="transparent" class="card-center" v-if="policy.numberOfVehiclesActivated == 0">
                <v-card-text class="pa-8">
                    <RewardsCarSVG class="floating-svg" :class="{'show':!loading}" :color="$vuetify.theme.currentTheme.accent" />
                </v-card-text>
                <v-card-text class="py-0">
                    <v-btn large :loading="loading" color="primary" elevation="8" class="px-10" to="/vehicle/activate">
                        check-in now!
                    </v-btn>
                    <span class="mt-5">Not by your car now?</span>
                    <ReminderButton text :title="title" :description="description" :start="start" id="btn-reminder"
                        class="text-initial">
                        Set a calendar reminder
                    </ReminderButton>
                </v-card-text>
            </v-card>

            <v-card flat color="transparent" class="card-center" v-else>
                <v-card-text class="pa-8">
                    <RewardsGiftSVG class="floating-svg" :class="{'show':!loading}"
                        :color="$vuetify.theme.currentTheme.accent" />
                </v-card-text>
                <v-card-text class="py-0">
                    <v-btn large :loading="loading" color="primary" elevation="8" class="px-10"
                        :disabled="disableClaim" to="/rewards-claim">
                        Claim rewards
                    </v-btn>
                </v-card-text>
            </v-card>
        </template>
    </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import api from "@/services/api-service";
    import RewardsConfettiSVG from "@/components/customer/RewardsConfettiSVG"
    import RewardsCarSVG from "@/components/customer/RewardsCarSVG"
    import RewardsGiftSVG from "@/components/customer/RewardsGiftSVG"
    import ReminderButton from "@/components/global/ReminderButton";

    export default {
        name: "RewardsSummary",

        props: {
            customer: {
                type: Object,
                required: true
            },
        },

        components: {
            RewardsConfettiSVG,
            RewardsCarSVG,
            RewardsGiftSVG,
            ReminderButton
        },

        computed: {
             ...mapGetters(["connection", "offline"]),
            isWeakSignal() {
                return this.connection != "3g" && this.connection != "4g";
            },

            disableClaim(){
                return this.offline || !this.summary.confirmed || this.policy.status != 'Activated'
            },

            policy() {
                return this.customer.policy;
            },
            currency() {
                return this.policy.discountsProviderCurrency;
            },
            title() {
                return `Ownli - Start your vehicle Check-in`;
            },
            description() {
                return "Keep engaged with your policy check-ins reward on Ownli, <a href='https://www.ownli.co/'>Click to Check-in</a>";
            },
            start() {
                let date = this.$options.filters.moment(new Date(), "add", "2 hours", "YYYY-MM-DD HH:MM");
                return this.$options.filters.moment(date, "YYYY-MM-DD HH:MM");
            },
        },

        data() {
            return {
                loading: false,
                dialog: false,
                summary: {
                    total: 0,
                    confirmed: 0,
                    pending: 0
                },
            }
        },

        methods: {
            async load() {
                try {
                    this.loading = true;
                    // Fetch customer by login e-mail:
                    const uri = `/policies/${this.policy.id}/rewards-summary`;
                    const response = await api.get(uri, {
                        requestId: uri
                    });

                    if (response.status == 200 && response.data) {
                        this.summary = response.data;
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "load"
                    }, error);
                } finally {
                    this.loading = false;
                }
            },
        },

        mounted() {
            this.load();
        }
    }
</script>

<style scoped>
    .confetti-bck-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        width: 100%;
        align-items: center;
        pointer-events: none;
    }

    .confetti-bck-wrapper>svg {
        width: 100%;
    }

    #summary {
        padding-bottom: 4rem;
    }

    .floating-svg {
        height: 100px;
        max-width: 100%;
        position: absolute;
        top: 0;
        z-index: 5;
        opacity: 0;
        transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .floating-svg.show {
        opacity: 1;
        top: -52px;
        transition: 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    #btn-reminder {
        text-transform: initial !important;
        font-weight: normal;
        height: auto;
    }
</style>