const host = location.host;
const environment = process.env.NODE_ENV;
const isProductionEnvironment = environment === "production" && host !== "localhost:8080";

import Vue from "vue"
import router from "@/router"
import VueSegmentAnalytics from "vue-segment-analytics"; //https://github.com/Kapiche/vue-segment-analytics
import LogRocket from "logrocket";


let sessionURL;

if (isProductionEnvironment) {
  Vue.use(VueSegmentAnalytics, {
    id: "4KDGPGO4UxJMyuUWyJQ152qrEkunh9eZ",
    router // Optional
  });

  LogRocket.init("ayeypq/parked", {
    rootHostname: "ownli.app",
    console: {
      isEnabled: isProductionEnvironment,
      shouldAggregateConsoleErrors: isProductionEnvironment
    },
    network: {
      requestSanitizer: request => {
        // if the url contains 'ignore'
        if (request.url.toLowerCase().indexOf("api.segment.io") !== -1) {
          // ignore the request response pair for Segment
          return null;
        }
        // otherwise log the request normally
        return request;
      }
    }
  });

  LogRocket.track("Logger_Init");

  LogRocket.getSessionURL((url) => {
    sessionURL = url;
    window.analytics.track("LogRocket", {
      sessionURL: url
    });
  });
}

class LogWrapper {

  identify(uid, data) {
    if (isProductionEnvironment) {
      // LogRocket:
      LogRocket.identify(uid, data);
      window.analytics.identify(uid, data);
    }

    console && console.log("identify:", uid, data);
  }

  getSessionURL() {
    if (!sessionURL) {
      LogRocket.getSessionURL((url) => {
        sessionURL = url;
        return sessionURL;
      });
    }

    return sessionURL;
  }

  info(text) {
    const model = {
      text: text,
      app_type:"pwa",
      pageUrl: document.location.href,
      // pagePath: document.location.pathname,
      // pageHostname: document.location.hostname,
      // referralUrl: document.referrer,
      // siteSection: document.location.pathname.replace("/", ""),
      timestamp: new Date().toISOString(),
    };

    if (isProductionEnvironment) {
      LogRocket.info(text, model);
    } 
    
    console && console.info("info:", model);
  }

  track(event, data) {
    const model = {
      ...data,
      app_type:"pwa",
      pageUrl: document.location.href,
      timestamp: new Date().toISOString()
    };

    if (isProductionEnvironment) {
      LogRocket.track(event, model);
      window.analytics.track(event, model);
    } 

    console && console.log(`track.${event}:`, model);
  }

  error(data, err) {
    const connection = navigator && navigator.connection;

    const model = {
      ...data,
      app_type:"pwa",
      pageUrl: document.location.href,
      connection: connection && connection.effectiveType,
      timestamp: new Date().toISOString(),
    };

    if (isProductionEnvironment) {
      // LogRocket:
      LogRocket.captureException(err, model);
    } 

    console && console.error("error:", model, err);
  }
}

const Logger = {
  install(Vue, options) {
    Vue.prototype.$Logger = new LogWrapper();
  }
};

export default Logger;