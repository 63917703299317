<template>
    <v-banner tow-line color="grey lighten-2" id="connection-banner" v-show="show">
        <v-icon slot="icon" size="40">
            {{ icon }}
        </v-icon>

        <p class="ma-0">Hmm, there seems to be a problem. Some functionality may be unavailable.</p>

        <template v-slot:actions>
            <v-btn color="accent" text @click="show = false">
                got it
            </v-btn>
        </template>
    </v-banner>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        name: "ConnectionBanner",
        data() {
            return {
                show: false
            }
        },

        computed: {
            ...mapGetters(["connection", "offline"]),
            isWeakSignal() {
                return this.connection != "3g" && this.connection != "4g";
            },
            icon() {
                return this.offline ? "$vuetify.icons.global_offline" : "$vuetify.icons.global_weak_signal";
            },
            text() {
                return this.offline ? "You’re offline. Some of your info may not be up to date.  If you want to check-in, go ahead!" : "Hmm… seems to be a weak network. Some of your info may not be up to date.";
            },
        },

        watch: {
            connection(o, ov) {
                this.toggleShow();
            },
            offline(o, ov) {
                this.toggleShow();
            },
        },

        methods: {
            toggleShow() {
                if (this.offline) {
                    this.show = true;
                    return;
                }
                if (this.isWeakSignal) {
                    this.show = true;
                    return;
                }

                this.show = false;
            }
        },
    }
</script>

<style scoped>
    #connection-banner {
        margin-top: 48px;
        margin-bottom: -48px;
    }

    #connection-banner>>>.v-banner__actions {
        padding-top: 5px;
    }
</style>