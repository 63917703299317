<template>
    <v-progress-linear :value="progress" v-bind="$attrs">
        {{ current / 1000 | number('0.0')}}
    </v-progress-linear>
</template>

<script>
    export default {
        name: "CountDownTimer",
        inheritAttrs: false,

        props: {
            time: {
                type: Number,
                default: 5000
            },
            interval: {
                type: Number,
                default: 1000
            },
            autoStart: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                current: this.time,
                timeout: null
            }
        },

        computed: {
            progress() {
                return this.current / this.time * 100;
            }
        },

        methods: {
            start() {
                if (this.current <= 0) {
                    this.clear();
                    this.$emit("ended");
                    return;
                }
                this.timeout = setTimeout(() => {
                    let cur = this.current;
                    this.current =  cur - this.interval;
                    this.start();
                }, this.interval);
            },
            clear() {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        },

        mounted() {
            this.current = this.time;
            if (this.autoStart) {
                this.start();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>