<template>
    <v-btn v-bind="$attrs" @click="addToCalendar">
        <slot>Remind Me</slot>

        <AddToCalendar :title="title" :description="description" :start="start" v-model="sheet" />
    </v-btn>
</template>

<script>
    import AddToCalendar from "@/components/global/AddToCalendar"
    export default {
        inheritAttrs: false,
        name: "ReminderButton",

        components: {
            AddToCalendar,
        },

        props: {
            title: {
                type: String,
                default: ""
            },
            description: {
                type: String,
                default: ""
            },
            start: {
                type: String,
                default: new Date().toString()
            },
        },

        data() {
            return {
                sheet: false
            }
        },

        methods: {
            addToCalendar() {
                this.sheet = true;

                this.$Logger.track(`reminder_clicked`, {
                    component: this.$options.name,
                    date: this.start,
                });
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>