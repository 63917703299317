<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 918.88 1044.64">
        <path
            d="M1321.29,1052.13H598.56c-20.49,0-37.13-16.86-37.13-37.65V535.05c0-20.78,16.64-37.65,37.13-37.65h722.62c20.5,0,37.13,16.87,37.13,37.65v479.43A37.24,37.24,0,0,1,1321.29,1052.13Z"
            transform="translate(-500.56 -17.82)" style="fill:#fff" />
        <path
            d="M1321.29,1052.13H598.56c-20.49,0-37.13-16.86-37.13-37.65V535.05c0-20.78,16.64-37.65,37.13-37.65h722.62c20.5,0,37.13,16.87,37.13,37.65v479.43A37.24,37.24,0,0,1,1321.29,1052.13Z"
            transform="translate(-500.56 -17.82)" style="fill:none;stroke:#212121;stroke-width:20.6391232844813px" />
        <path d="M1007.2,588.68H914.87v463.46h92.33Z" transform="translate(-500.56 -17.82)"
            :style="`fill:${color};stroke:#212121;stroke-width:20.6391232844813px`" />
        <path
            d="M1387.64,588.69H532.25A21.54,21.54,0,0,1,510.88,567V482.55a21.54,21.54,0,0,1,21.37-21.67h855.5a21.55,21.55,0,0,1,21.37,21.67V567A21.57,21.57,0,0,1,1387.64,588.69Z"
            transform="translate(-500.56 -17.82)" style="fill:#fff" />
        <path
            d="M1387.64,588.69H532.25A21.54,21.54,0,0,1,510.88,567V482.55a21.54,21.54,0,0,1,21.37-21.67h855.5a21.55,21.55,0,0,1,21.37,21.67V567A21.57,21.57,0,0,1,1387.64,588.69Z"
            transform="translate(-500.56 -17.82)" style="fill:none;stroke:#212121;stroke-width:20.6391232844813px" />
        <path
            d="M917.27,330.7c-45.83-37.77-97.28-50.61-114.91-28.6s5.18,70.5,50.9,108.26S950.54,461,968.17,439,963.1,368.58,917.27,330.7Zm-52,64.8c-28.76-23.68-43-54.07-31.95-67.93s43.3-5.81,72,18,43,54.08,32,67.93S893.91,419.3,865.27,395.5Z"
            transform="translate(-500.56 -17.82)" :style="`fill:${color}`" />
        <path
            d="M917.27,338c-45.83-37.76-97.28-50.61-114.91-28.6s5.18,70.5,50.9,108.27,97.28,50.61,114.91,28.6S963.1,375.88,917.27,338Zm-52,64.81c-28.76-23.69-43-54.08-31.95-67.94s43.3-5.81,72,18,43,54.08,32,67.94S893.91,426.61,865.27,402.81Z"
            transform="translate(-500.56 -17.82)" style="fill:none;stroke:#212121;stroke-width:15.4793424633609px" />
        <path
            d="M1013,327.23c-37.24,46.48-49.91,98.66-28.21,116.53s69.52-5.25,106.76-51.61,49.91-98.66,28.2-116.54S1050.37,280.75,1013,327.23Zm63.9,52.74c-23.36,29.16-53.33,43.57-67,32.4s-5.73-43.91,17.74-73.07,53.32-43.58,67-32.4S1100.28,350.81,1076.93,380Z"
            transform="translate(-500.56 -17.82)" :style="`fill:${color}`" />
        <path
            d="M1013,334.54c-37.24,46.47-49.91,98.65-28.21,116.53s69.52-5.25,106.76-51.62,49.91-98.66,28.2-116.53S1050.37,288.06,1013,334.54Zm63.9,52.73c-23.36,29.16-53.33,43.58-67,32.4s-5.73-43.91,17.74-73.07,53.32-43.57,67-32.4S1100.28,358.11,1076.93,387.27Z"
            transform="translate(-500.56 -17.82)" style="fill:none;stroke:#212121;stroke-width:15.4793424633609px" />
        <path
            d="M966.55,152.72a20.85,20.85,0,0,1-20.72-21V49.14a20.72,20.72,0,1,1,41.43,0v82.57A20.84,20.84,0,0,1,966.55,152.72Z"
            transform="translate(-500.56 -17.82)"
            :style="`fill:${color};stroke:#212121;stroke-width:20.6391232844813px`" />
        <path
            d="M787,225.68a20.62,20.62,0,0,1-29.31,0l-57.51-58.33a21.32,21.32,0,0,1,0-29.72,20.62,20.62,0,0,1,29.31,0L787,196A21.32,21.32,0,0,1,787,225.68Z"
            transform="translate(-500.56 -17.82)"
            :style="`fill:${color};stroke:#212121;stroke-width:20.6391232844813px`" />
        <path
            d="M1147.45,225.68a21.32,21.32,0,0,1,0-29.72L1205,137.63a20.62,20.62,0,0,1,29.31,0,21.32,21.32,0,0,1,0,29.72l-57.51,58.33A20.62,20.62,0,0,1,1147.45,225.68Z"
            transform="translate(-500.56 -17.82)"
            :style="`fill:${color};stroke:#212121;stroke-width:20.6391232844813px`" />
        <path
            d="M1195.48,388.24a20.84,20.84,0,0,1,20.71-21h81.42a21,21,0,0,1,0,42h-81.42A20.83,20.83,0,0,1,1195.48,388.24Z"
            transform="translate(-500.56 -17.82)"
            :style="`fill:${color};stroke:#212121;stroke-width:20.6391232844813px`" />
        <path
            d="M601.66,388.24a20.84,20.84,0,0,1,20.71-21h81.42a21,21,0,0,1,0,42H622.37A20.83,20.83,0,0,1,601.66,388.24Z"
            transform="translate(-500.56 -17.82)"
            :style="`fill:${color};stroke:#212121;stroke-width:20.6391232844813px`" />
        <path d="M1006.11,460.87H913.79V588.68h92.32Z" transform="translate(-500.56 -17.82)"
            :style="`fill:${color};stroke:#212121;stroke-width:20.6391232844813px`" /></svg>
</template>

<script>
    export default {
        name: "RewardsGiftSVG",
        props: {
            color: {
                type: String,
                default: "#FFD51C"
            },
        },
    }
</script>
