<template>
    <v-bottom-navigation v-model="nav" app grow>
        <v-btn small value="Rewards" to="/rewards" exact>
            <span>Rewards</span>
            <v-icon>$vuetify.icons.pages_rewards</v-icon>
        </v-btn>

        <v-btn small value="Vehicles" to="/vehicles" exact>
            <span>Vehicles</span>
            <v-icon>$vuetify.icons.pages_vehicles</v-icon>
        </v-btn>

        <v-btn small value="Timeline" to="/timeline" exact>
            <span>Timeline</span>
            <v-icon>$vuetify.icons.pages_timeline</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
    export default {
        name: "NavbarBottom",

        data() {
            return {
                nav: this.currentRoute
            }
        },

        computed: {
             currentRoute() {
                return this.$route && this.$route.name;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>