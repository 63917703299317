<template>
    <v-card v-bind="$attrs" class="card-center">
        <v-card-title class="grow">
            <v-icon color="primary">mdi-progress-download</v-icon>

            <h1 class="mt-5">Great news!!</h1>
        </v-card-title>
        <v-card-text>
            <h3>We have a brand new version for you.</h3>
            <p>
                click on the <strong>upgrade now</strong> button<br/> to enjoy a better experience in our app.
            </p>
        </v-card-text>
        <v-card-actions>
            <v-btn block x-large elevation="10" color="primary" @click="upgrade">
                upgrade now
                <v-icon right>mdi-progress-download</v-icon>    
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "UpgradeVersion",
        inheritAttrs: false,

        methods: {
            upgrade() {
                this.$store.commit("UPGRADED_PWA");
                window.location.reload();
            }
        },
    }
</script>

<style scoped>
.v-card__title > .v-icon{
    font-size: 8rem;
}
</style>