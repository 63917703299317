<template>
    <v-stepper v-model="step" fill-height flat tile class="pb-15">
        <v-form v-model="valid" ref="form">

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card fill-height flat tile color="transparent" class="card-flex">
                        <v-card-title class="pa-0">
                            <StepOneSVG :color="$vuetify.theme.currentTheme.primary"
                                :accent="$vuetify.theme.currentTheme.accent" />
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <h2>First things first</h2>
                            <p>To get started, tell us a little bit about yourself.</p>
                        </v-card-text>
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="6">
                                    <v-text-field filled v-model="firstname" :rules="nameRules" required
                                        label="First name" append-icon="$vuetify.icons.global_required" />
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field filled v-model="lastname" :rules="nameRules" required
                                        label="Last name" append-icon="$vuetify.icons.global_required" />
                                </v-col>

                                <v-col cols="12">
                                    <MobilePhoneInput filled clearable v-model="phone" return-international
                                        @country="countryChanged" :country-code="countryCode"
                                        :allow-country-change="!partner"
                                        :hint="`Number will be saved as ${phone||' - '}`" label="Mobile Number"
                                        v-if="!isPhoneProvider" />

                                    <v-text-field filled label="Mobile Number" v-model="phone" readonly v-else>
                                        <template v-slot:prepend-inner>
                                            <v-avatar :size="24" tile class="mr-5">
                                                <v-img :src="countryFlag" />
                                            </v-avatar>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-text-field filled v-model="email" :rules="emailRules" required
                                        label="Primary e-mail used for notifications" type="email"
                                        append-icon="$vuetify.icons.global_required" />
                                </v-col>
                            </v-row>

                            <div class="mt-10 text-center">Need any help?<br />
                                <a target="_blank" href="https://joinparked.zendesk.com/hc/en-us/requests/new">Contact
                                    us</a></div>
                        </v-card-text>
                        <v-spacer />
                        <v-card-actions>
                            <v-btn text @click="signOut" :disabled="loading">
                                <v-icon>mdi-chevron-left</v-icon>
                                prev
                            </v-btn>
                            <v-spacer />
                            <v-radio-group v-model="step" row dense>
                                <v-radio :value="1"></v-radio>
                                <v-radio :value="2"></v-radio>
                            </v-radio-group>
                            <v-spacer />
                            <v-btn text @click="nextStep" :disabled="!valid || loading" :loading="loading">
                                next
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card fill-height flat tile color="transparent" class="card-flex">
                        <v-card-title class="pa-0">
                            <StepTowSVG :color="$vuetify.theme.currentTheme.primary"
                                :accent="$vuetify.theme.currentTheme.accent" />
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <h2>Almost done...</h2>
                            <p>Help us help you - Your policy details help us maximize your rewards.</p>
                        </v-card-text>
                        <v-card-text v-if="step > 1">
                            <v-row dense>
                                <v-col cols="12" xl="4" v-if="!partner">
                                    <InsuranceProviderPicker filled v-model="insurance" :country="countryCode"
                                        :rules="requiredRule" required clearable
                                        append-icon="$vuetify.icons.global_required" label="Insurance Provider"
                                        placeholder="Start typing to search"
                                        hint="Search for your Insurance Company Provider" />
                                </v-col>

                                <v-col cols="12" xl="4" v-if="countryCode == 'US'">
                                    <UsStatesPicker filled v-model="state" :rules="requiredRule" required clearable
                                        append-icon="$vuetify.icons.global_required" label="State"
                                        placeholder="Start typing to search" />
                                </v-col>

                                <v-col cols="12" xl="4" v-if="insurance">
                                    <v-select filled :disabled="maxNumberOfVehiclesOnPolicy.length == 1"
                                        v-model="numberOfVehiclesOnPolicy" item-text="text" item-value="value"
                                        :items="maxNumberOfVehiclesOnPolicy" :rules="requiredRule" required
                                        label="No. Of Vehicles On Policy">
                                    </v-select>
                                </v-col>
                            </v-row>

                            <div class="mt-10 text-center">
                                This information will NOT be shared<br /> without your explicit consent
                            </div>
                        </v-card-text>
                        <v-spacer />
                        <v-card-actions>
                            <v-btn text @click="step = 1" :disabled="loading">
                                <v-icon>mdi-chevron-left</v-icon>
                                prev
                            </v-btn>
                            <v-spacer />
                            <v-radio-group v-model="step" row dense>
                                <v-radio :value="1"></v-radio>
                                <v-radio :value="2"></v-radio>
                            </v-radio-group>
                            <v-spacer />
                            <v-btn text @click="register" :disabled="!valid || loading" :loading="loading">
                                next
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card fill-height flat tile color="transparent" class="card-flex">
                        <v-card-title class="pa-0">
                            <StepThreeSVG :color="$vuetify.theme.currentTheme.primary"
                                :accent="$vuetify.theme.currentTheme.accent" />
                        </v-card-title>
                        <v-spacer />

                        <v-card-text class="text-center">
                            <h1 class="display-1 mb-5">You’re Registered!</h1>
                            <p v-if="hasDiscounts">You're all set to start earning rewards<br /> based on your mileage.</p>
                            <p v-else>
                                Ownli is a growing community of people owning their data and
                                earning from it.
                                <br/>
                                <br/>
                                <br/>
                                We’re currently full and will make sure to reach out when more space
                                becomes available.
                                <br/>
                                <br/>
                                <br/>
                                In the meantime, feel free to take a look around.
                            </p>
                        </v-card-text>

                        <v-spacer />
                        <v-card-actions class="pa-5">
                            <v-btn large block color="primary" to="/vehicles" :loading="loading">go to dashboard</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>

        </v-form>
    </v-stepper>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import {
        getAuth,
        signOut
    } from "firebase/auth";
    import api from "@/services/api-service";
    import InsuranceProviderPicker from "@/components/customer/InsuranceProviderPicker"
    import UsStatesPicker from "@/components/customer/UsStatesPicker"
    import MobilePhoneInput from "@/components/global/MobilePhoneInput"
    import StepOneSVG from '@/components/register/StepOneSVG'
    import StepTowSVG from '@/components/register/StepTowSVG'
    import StepThreeSVG from '@/components/register/StepThreeSVG'

    export default {
        name: 'Register',

        components: {
            MobilePhoneInput,
            StepOneSVG,
            StepTowSVG,
            StepThreeSVG,

            InsuranceProviderPicker,
            UsStatesPicker,
        },

        computed: {
            ...mapGetters(["ipinfo", "user", "partner", "device", "customer"]),

            nameFirst() {
                if (!this.user || !this.user.name) {
                    return null;
                }
                let split = this.user.name.split(' ');
                return split[0];
            },
            nameLast() {
                if (!this.user || !this.user.name) {
                    return null;
                }
                let split = this.user.name.split(' ');
                return split[1];
            },

            authProvider() {
                return this.user && this.user.provider;
            },
            isPhoneProvider() {
                return this.authProvider == "phone";
            },
            isAppleAuthProvider() {
                return this.authProvider == "apple.com";
            },

            countryFlag() {
                const code = this.countryCode && this.countryCode.toLowerCase();
                return `https://flagcdn.com/${code}.svg`;
            },

            maxNumberOfVehiclesOnPolicy() {
                if (!this.insurance) {
                    return [{
                        text: "1",
                        value: 1
                    }];
                }

                let items = [];
                for (let index = 1; index <= this.insurance.maxVehiclesPerPolicy; index++) {
                    items.push({
                        text: `${index}`,
                        value: index
                    });
                }

                return items;
            },

            hasDiscounts() {
                return this.customer && this.customer.policy.hasDiscounts;
            }
        },

        watch: {
            insurance(v, ov) {
                if (v && v.maxVehiclesPerPolicy == 1) {
                    this.numberOfVehiclesOnPolicy = 1;
                }
            }
        },

        data() {
            return {
                step: 1,
                loading: false,
                valid: false,
                exists: false,
                insurance: this.partner,
                state: null,
                numberOfVehiclesOnPolicy: null,

                requiredRule: [v => !!v || "Field is required"],

                policyNumber: null,

                firstname: null,
                lastname: null,
                nameRules: [
                    v => !!v || "Name is required",
                    v => v && v.length <= 30 || "Name must be less than 30 characters"
                ],

                email: null,
                emailRules: [
                    v => !!v || "E-mail is required",
                    v => v && /.+@.+/.test(v) || "Please enter a valid E-mail"
                ],


                country: "United States",
                countryCode: "US",
                dialCode: "1",
                phone: null,
            }
        },

        methods: {
            countryChanged(obj) {
                if (!obj || this.partner) {
                    return;
                }

                this.country = obj.name;
                this.countryCode = obj.code;
                this.dialCode = obj.dial;
                this.insurance = null;
            },

            fillUserDetails() {
                this.firstname = this.nameFirst;
                this.lastname = this.nameLast;
                this.email = this.user.email;
                this.phone = this.user.phone;

                if (this.user.phone) {
                    if (this.user.phone.startsWith("+1")) {
                        this.countryCode = "US";
                        this.dialCode = "1";
                        // this.phone = this.user.phone.substring(2);
                    }
                    if (this.user.phone.startsWith("+44")) {
                        this.countryCode = "GB";
                        this.dialCode = "44";
                        // this.phone = this.user.phone.substring(3);
                    }
                    if (this.user.phone.startsWith("+81")) {
                        this.countryCode = "JP";
                        this.dialCode = "81";
                        // this.phone = this.user.phone.substring(3);
                    }
                    if (this.user.phone.startsWith("+972")) {
                        this.countryCode = "IL";
                        this.dialCode = "972";
                        // this.phone = this.user.phone.substring(4);
                    }
                }
            },
            fillPartnerDetails() {
                if (!this.partner) {
                    return;
                }
                this.insurance = this.partner;
                this.country = this.partner.country;
                this.countryCode = this.partner.countryCode;
                this.dialCode = this.partner.countryDialCode;
                // this.onlyCountries = [this.partner.countryCode];

                if (this.partner.countryCode != "US") {
                    this.state = this.partner.country;
                }
            },

            async register() {
                if (!this.$refs.form.validate()) {
                    return null;
                }

                try {
                    const sessionUrl = this.$Logger.getSessionURL();

                    this.loading = true;
                    const request = {
                        nameFirst: this.firstname,
                        nameLast: this.lastname,
                        loginEmail: this.user.email || this.email,
                        contact: {
                            email: this.email,
                            phone: this.phone
                        },
                        trafficReferral: {
                            source: localStorage && localStorage.getItem("utm_source"),
                            medium: localStorage && localStorage.getItem("utm_medium"),
                            campaign: localStorage && localStorage.getItem("utm_campaign"),
                            registeredWithIP: this.ipinfo && this.ipinfo.ip,
                            registeredWithDevice: this.device && this.device.product
                        },
                        lastLoginData: {
                            deviceOS: this.device && this.device.os,
                            deviceProduct: this.device && this.device.product,
                            deviceManufacturer: this.device && this.device.manufacturer,
                            logRocketSessionUrl: sessionUrl
                        },
                        affiliateId: localStorage && localStorage.getItem("affiliate"),
                        insuranceCompanyId: this.insurance.id,
                        insurancePolicyState: this.countryCode == "US" ? this.state : this.insurance.country,
                        numberOfVehiclesOnPolicy: this.numberOfVehiclesOnPolicy
                    };

                    const response = await api.post("customers", request);
                    this.$store.commit("UPDATE_CUSTOMER", response.data);
                    this.nextStep();
                } catch (error) {
                    if (error.response && error.response.status == 409) {
                        this.$store.commit("ADD_NOTIFICATION", {
                            text: "This email or phone number is already associated with an existing account.",
                            timeout: 8000,
                            multiLine: true
                        })
                        this.exists = true;
                    }
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "register"
                    }, error);

                } finally {
                    this.loading = false;
                }
            },
            async signOut() {
                try {
                    const auth = getAuth();
                    let result = await signOut(auth);
                    // console.log("signOut:", result);
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "signOut"
                    }, error);
                    // console.log("signOut.error:", error);
                }
            },
            async lead() {
                try {
                    const sessionUrl = this.$Logger.getSessionURL();

                    this.loading = true;
                    const request = {
                        AuthProvider: this.user.provider,
                        Name: `${this.firstname || ''} ${this.lastname || ''}`,
                        Email: this.email,
                        Phone: this.phone,

                        ClientIP: this.ipinfo && this.ipinfo.ip,
                        ClientCountry: this.ipinfo && this.ipinfo.country_name,
                        ClientCity: this.ipinfo && this.ipinfo.city,

                        DeviceOS: this.device && this.device.os,
                        DeviceModel: this.device && this.device.product,
                        DeviceMake: this.device && this.device.manufacturer,

                        LogRocketSessionUrl: sessionUrl,
                        ReferrerUrl: localStorage && localStorage.getItem("referrer"),
                        LandingUrl: localStorage && localStorage.getItem("landed"),

                        AffiliateId: localStorage && localStorage.getItem("affiliate"),
                    };

                    const response = await api.post("/leads", request);
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "lead"
                    }, error);

                } finally {
                    this.loading = false;
                }
            },

            nextStep() {
                if (!this.$refs.form.validate()) {
                    return null;
                }
                this.step = this.step + 1;
                this.$Logger.track(`registration_flow`, {
                    component: this.$options.name,
                    step: this.step
                });
            }
        },

        mounted() {

            //Set Globalization by IP:
            if (this.ipinfo) {
                this.country = this.ipinfo.country_name;
                this.countryCode = this.ipinfo.country_code;
                this.dialCode = this.ipinfo.country_calling_code.replace("+", "");
            }

            //Fill User & Partner details:
            this.fillUserDetails();
            this.fillPartnerDetails();

            //Create a Lead entity prior registration:
            this.lead();

            this.$Logger.track(`registration_flow`, {
                component: this.$options.name,
                step: this.step
            });
        },
    }
</script>

<style scoped>
    form {
        background: none !important;
        height: 100% !important;
    }

    .v-card__title>svg {
        width: 100%;
        height: auto;
    }

    .v-stepper,
    .v-stepper__items,
    .v-stepper__content {
        background: none !important;
        height: 100% !important;
        padding: 0;
    }
</style>