import store from "@/store"
import axios from "axios";
import axiosCancel from "axios-cancel";

let api_base_uri = () => {
  switch (document.location.host) {
    case "localhost:8080":
      return "https://ownli-customer-prod-api.azurewebsites.net/";
    
    case "staging.joinparked.com":
      return "https://parked-customers-stg-webapi.azurewebsites.net/";
    
    case "dev-customers.ownli.app":
      return "https://ownli-customer-dev-api.azurewebsites.net/";
    default:
      return "https://ownli-customer-prod-api.azurewebsites.net/";
  }
};

let config = {
  baseURL: api_base_uri(),
  timeout: process.env.NODE_ENV === "production" ? 60 * 1000 : 300 * 1000
};

const _axios = axios.create(config);

axiosCancel(_axios, {
  debug: false
});

_axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const token = store.getters.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } 
    // else {
    //   window.location.href = "/";
    // }

    store.commit("ADD_HTTP_REQUEST");
    return config;
  },

  function (error) {
    // Do something with request error
    store.commit("REMOVE_HTTP_REQUEST");
    return window.Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    store.commit("REMOVE_HTTP_REQUEST");

    return response;
  },
  function (error) {
    // Do something with response error
    // console.log("api-service.response.error:", error.response.status);

    // store.commit("ADD_NOTIFICATION", {
    //   text: error.message,
    //   color:"error",
    //   timeout: 3000
    // })

    store.commit("REMOVE_HTTP_REQUEST");

    if (error["__CANCEL__"] !== true) {
      return window.Promise.reject(error);
    }
  }
);

export default _axios;
