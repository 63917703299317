<template>
        <v-timeline dense reverse>

            <v-timeline-item fill-dot small color="primary" key="registered">
                <TimelineCard>
                    <template v-slot:avatar>
                        <!-- <v-img :src="getEventAvatar('Register')" /> -->
                        <component v-bind:is="getSvgComponent('Register')" :color="$vuetify.theme.currentTheme.primary">
                        </component>
                    </template>
                    <template v-slot:date>{{ customer.dateRegisteredAt | moment("MMM DD, YYYY") }}</template>
                    <template v-slot:title>Registered</template>
                    <template v-slot:subtitle>Welcome!</template>
                </TimelineCard>
            </v-timeline-item>

            <v-timeline-item fill-dot small color="primary" key="signed-up">
                <TimelineCard>
                    <template v-slot:avatar>
                        <!-- <v-img :src="getEventAvatar('SignUp')" /> -->
                        <component v-bind:is="getSvgComponent('SignUp')" :color="$vuetify.theme.currentTheme.primary">
                        </component>
                    </template>
                    <template v-slot:date>{{ user.created | moment("MMM DD, YYYY") }}</template>
                    <template v-slot:title>Signed-up</template>
                    <template v-slot:subtitle>That was easy</template>
                </TimelineCard>
            </v-timeline-item>

        </v-timeline>

</template>

<script>
    import TimelineCard from "@/components/timeline/TimelineCard"
    import SignUpSVG from "@/components/timeline/SignUpSVG"
    import RegisterSVG from "@/components/timeline/RegisterSVG"

    export default {
        name: "CustomerTimeline",

        components: {
            TimelineCard,
            SignUpSVG,
            RegisterSVG
        },

        props: {
            user: {
                type: Object,
                required: true
            },
            customer: {
                type: Object,
                required: true
            },
        },

        methods: {
            getEventAvatar(evnt) {
                switch (evnt) {
                    case "Register":
                        return "https://parked.blob.core.windows.net/web/resources/images/V3/Timeline_Register.svg";
                    case "SignUp":
                        return "https://parked.blob.core.windows.net/web/resources/images/V3/Timeline_SignUp.svg";
                }
            },
            getSvgComponent(evnt) {
                switch (evnt) {
                    case "Register":
                        return "RegisterSVG";
                    case "SignUp":
                        return "SignUpSVG";
                }
            }
        },
    }
</script>