<template>

    <svg viewBox="0 0 1394 476" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_272_6180)">
            <path d="M1394 0H0V476H1394V0Z" :fill="color" />
            <rect x="209.171" y="120" width="622.512" height="366.156" rx="7" fill="#F9F9F9" stroke="#212121"
                stroke-width="2" />
            <path
                d="M421.845 270.046C421.845 291.518 404.495 308.919 383.1 308.919C361.704 308.919 344.354 291.518 344.354 270.046C344.354 248.574 361.704 231.172 383.1 231.172C404.495 231.172 421.845 248.574 421.845 270.046Z"
                :fill="color" stroke="#212121" stroke-width="2" />
            <path
                d="M461.962 373.715H301.752C301.024 373.715 300.54 372.962 300.842 372.299L322.602 324.672C325.042 319.331 330.374 315.905 336.245 315.905H358.241L371.139 323.173C377.058 326.509 384.225 326.775 390.375 323.886L407.371 315.905L427.468 315.905C433.34 315.905 438.672 319.331 441.112 324.672L462.871 372.299C463.174 372.962 462.69 373.715 461.962 373.715Z"
                :fill="color" stroke="#212121" stroke-width="2" />
            <path d="M509.275 267.75L756.477 267.75M509.275 349.562L669.12 349.562M509.275 308.656L695.141 308.656"
                stroke="#212121" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M887.276 269.808C908.249 275.449 929.394 282.225 941.806 286.215C946.001 287.66 949.817 290.239 952.74 293.679C955.662 297.118 957.485 301.349 958.035 305.717C969.14 388.714 943.457 450.179 912.272 490.662C899.138 507.998 883.287 523.304 865.58 535.893C859.563 540.261 853.168 543.907 846.429 547.209C841.134 549.582 836.39 551.233 832.951 551.233C829.479 551.233 824.734 549.582 819.473 547.209C812.734 543.941 806.339 540.089 800.322 535.893C782.65 523.132 766.765 507.998 753.631 490.662C722.824 450.041 696.934 388.748 708.04 305.752C708.59 301.384 710.412 297.187 713.335 293.713C716.257 290.239 720.074 287.694 724.268 286.249C742.319 280.402 760.542 274.933 778.799 269.843C799.566 264.167 820.917 259.421 833.123 259.421C845.329 259.421 866.68 264.167 887.276 269.808Z"
                :fill="color" />
            <path
                d="M887.276 269.808C908.249 275.449 929.394 282.225 941.806 286.215C946.001 287.66 949.817 290.239 952.74 293.679C955.662 297.118 957.485 301.349 958.035 305.717C969.14 388.714 943.457 450.179 912.272 490.662C899.138 507.998 883.287 523.304 865.58 535.893C859.563 540.261 853.168 543.907 846.429 547.209C841.134 549.582 836.39 551.233 832.951 551.233C829.479 551.233 824.734 549.582 819.473 547.209C812.734 543.941 806.339 540.089 800.322 535.893C782.65 523.132 766.765 507.998 753.631 490.662C722.824 450.041 696.934 388.748 708.04 305.752C708.59 301.384 710.412 297.187 713.335 293.713C716.257 290.239 720.074 287.694 724.268 286.249C742.319 280.402 760.542 274.933 778.799 269.843C799.566 264.167 820.917 259.421 833.123 259.421C845.329 259.421 866.68 264.167 887.276 269.808Z"
                stroke="#212121" stroke-width="2" />
            <path
                d="M882.568 279.817C901.702 285.119 921.008 291.143 932.481 294.965C936.294 296.238 939.764 298.614 942.512 301.919C945.054 305.189 946.874 309.045 947.424 313.038C957.627 389.877 934.13 446.679 905.721 484.272C893.698 500.349 879.305 514.395 863.091 526.272C857.629 530.299 851.789 533.776 845.606 536.875C840.865 539.078 836.503 540.524 833.411 540.524C830.32 540.524 825.957 539.078 821.216 536.875C815.205 533.948 809.193 530.299 803.731 526.478C787.517 514.808 773.124 500.555 761.101 484.479C732.658 447.058 709.161 390.29 719.192 313.245C719.742 309.045 721.391 305.224 724.104 302.125C726.646 298.855 730.116 296.479 734.135 295.171C750.727 289.698 767.284 284.568 784.048 280.024C802.976 274.894 822.281 270.35 833.583 270.35C844.335 270.144 863.641 274.55 882.568 279.817Z"
                fill="#F9F9F9" stroke="#212121" stroke-width="2" />
            <path
                d="M747.152 322.424L747.152 365.052C747.152 365.909 745.652 366.592 743.713 366.592L740.56 366.592C738.647 366.592 737.122 365.92 737.122 365.052L737.122 322.424C737.122 321.566 738.621 320.883 740.56 320.883L743.713 320.883C745.497 320.883 747.152 321.566 747.152 322.424Z"
                fill="white" fill-opacity="0.25" />
            <path
                d="M763.496 345.197L763.496 380.548C763.496 381.258 761.996 381.825 760.058 381.825L756.904 381.825C754.991 381.825 753.466 381.268 753.466 380.548L753.466 345.197C753.466 344.487 754.965 343.92 756.904 343.92L760.058 343.92C761.841 343.92 763.496 344.487 763.496 345.197Z"
                fill="white" fill-opacity="0.25" />
            <path
                d="M281.071 262.958C287.393 268.915 289.475 278.241 287.621 288.6C285.768 298.955 279.987 310.277 270.687 320.105C261.352 329.91 248.164 338.605 235.707 343.324C229.479 345.683 223.459 347.039 218.211 347.056C212.963 347.073 208.534 345.754 205.415 342.815C202.297 339.877 200.726 335.529 200.447 330.284C200.168 325.041 201.185 318.949 203.195 312.601C207.215 299.906 215.171 286.291 224.445 276.577C233.716 266.866 244.724 260.457 254.998 257.988C265.276 255.518 274.749 257.002 281.071 262.958Z"
                :fill="accent" stroke="#212121" />
            <path opacity="0.26"
                d="M281.295 262.595C277.12 258.831 271.757 256.639 266.134 256.396C266.752 256.856 267.345 257.348 267.91 257.872C280.881 270.093 276.261 295.951 257.605 315.669C243.569 330.487 220.649 342.826 204.658 342.826L204.954 343.18C217.924 355.401 252.334 340.169 270.931 320.451C289.527 300.733 294.265 274.815 281.295 262.595Z"
                fill="#0F2A3F" />
            <path
                d="M150.656 193.96C158.653 194.734 165.491 200.679 170.067 209.825C174.639 218.961 176.909 231.218 175.791 244.423C174.406 257.668 169.718 272.431 163.306 283.681C160.1 289.306 156.476 294.029 152.64 297.245C148.803 300.461 144.796 302.136 140.8 301.769C136.803 301.402 133.15 299.014 129.94 295.138C126.731 291.264 124.002 285.943 121.859 279.815C117.575 267.559 115.673 252.179 116.91 238.967C118.147 225.761 122.577 214.09 128.716 205.932C134.862 197.766 142.662 193.186 150.656 193.96Z"
                :fill="accent" stroke="#212121" />
            <path
                d="M50.3091 196.623C56.9355 193.22 65.3299 194.514 73.5555 199.513C81.7713 204.505 89.7367 213.153 95.4162 224.27C101.099 235.483 104.767 249.582 105.29 261.737C105.551 267.816 105.025 273.381 103.589 277.845C102.153 282.311 99.8272 285.623 96.524 287.298C93.2209 288.972 89.1617 288.891 84.6902 287.41C80.2208 285.93 75.3939 283.068 70.6172 279.272C61.0638 271.678 51.7963 260.414 46.0839 249.26C40.3754 238.114 38.0916 226.636 38.9189 217.098C39.7471 207.55 43.6781 200.028 50.3091 196.623Z"
                :fill="accent" stroke="#212121" />
            <path
                d="M24.0627 323.077C26.8305 316.962 33.6941 313.264 42.7082 312.306C51.7051 311.35 62.7358 313.144 73.6256 317.896C84.5519 322.917 95.867 331.113 103.734 339.623C107.669 343.879 110.722 348.192 112.439 352.206C114.159 356.224 114.515 359.875 113.173 362.871C111.831 365.866 108.874 368.034 104.739 369.426C100.608 370.818 95.3676 371.412 89.5856 371.305C78.0231 371.092 64.4079 368.082 53.4224 363.208C42.4427 358.338 33.6791 351.363 28.3797 344.059C23.0713 336.743 21.2957 329.191 24.0627 323.077Z"
                :fill="accent" stroke="#212121" />
            <path opacity="0.25"
                d="M73.8296 317.44C51.9164 307.876 29.3516 310.178 23.6068 322.871C22.3093 325.927 21.9787 329.305 22.6592 332.553C28.5817 320.273 50.8503 318.148 72.5267 327.83C90.2942 335.741 108.891 352.035 112.504 364.728C112.913 364.214 113.27 363.66 113.57 363.075C119.255 350.323 95.8021 327.535 73.8296 317.44Z"
                fill="#1C3E59" />
            <path
                d="M307.001 355.152C309.445 362.146 307.395 370.06 301.951 377.237C296.51 384.41 287.712 390.792 276.805 394.661C265.891 398.532 252.434 400.029 241.076 398.79C235.398 398.17 230.271 396.87 226.258 394.859C222.244 392.849 219.387 390.152 218.158 386.756C216.928 383.362 217.413 379.48 219.238 375.397C221.062 371.314 224.207 367.075 228.215 363.013C236.233 354.889 247.629 347.551 258.543 343.651C269.451 339.753 280.368 339.116 289.159 341.223C297.956 343.332 304.558 348.164 307.001 355.152Z"
                :fill="accent" stroke="#212121" />
            <path opacity="0.26"
                d="M307.473 354.987C306.183 351.848 304.206 349.036 301.685 346.758C299.164 344.48 296.163 342.794 292.903 341.822C295.541 343.967 297.58 346.752 298.826 349.91C303.978 364.315 290.297 382.262 268.325 390.055C253.208 395.45 236.793 396.089 221.3 391.885C231.368 401.508 258.138 401.921 277.209 395.132C298.944 387.339 312.507 369.392 307.473 354.987Z"
                fill="#0F2A3F" />
            <path
                d="M162.667 474.063C167.525 412.259 195.388 354.498 240.785 312.126C242.562 310.473 239.838 307.757 238.061 309.469C191.98 352.557 163.734 411.269 158.877 474.063C158.877 476.543 162.49 476.484 162.667 474.063Z"
                fill="#212121" />
            <path
                d="M148.809 465.031C150.48 388.684 125.903 314.064 79.16 253.562C77.7386 251.673 74.422 253.562 75.9026 255.51C122.274 315.43 146.666 389.371 145.018 465.031C145.018 465.532 145.218 466.013 145.573 466.367C145.929 466.721 146.411 466.92 146.913 466.92C147.416 466.92 147.898 466.721 148.254 466.367C148.609 466.013 148.809 465.532 148.809 465.031V465.031Z"
                fill="#212121" />
            <path
                d="M132.7 353.57C143.123 320.569 145.729 285.501 147.032 251.082C147.032 248.662 143.301 248.662 143.182 251.082C141.939 285.206 139.333 319.86 128.909 352.862C128.139 355.223 131.871 356.227 132.581 353.865L132.7 353.57Z"
                fill="#212121" />
            <path
                d="M73.4746 347.253C92.4993 358.457 112.572 367.79 133.41 375.119C135.72 376.004 136.727 372.344 134.476 371.458C114.001 364.222 94.2839 355.008 75.6067 343.947C73.5339 342.648 71.6387 345.954 73.7115 347.253H73.4746Z"
                fill="#212121" />
            <path
                d="M235.87 376.83C217.537 384.443 198.673 390.718 179.428 395.604C178.941 395.737 178.527 396.057 178.277 396.495C178.027 396.932 177.962 397.45 178.095 397.936C178.229 398.421 178.55 398.834 178.989 399.083C179.428 399.332 179.948 399.397 180.435 399.264C199.68 394.378 218.544 388.104 236.876 380.49C239.127 379.546 238.12 375.886 235.87 376.83Z"
                fill="#212121" />
            <path
                d="M214.398 536.18L214.398 536.18C213.729 542.212 210.872 547.786 206.372 551.834C201.873 555.882 196.046 558.122 190.007 558.126H125.472C119.437 558.11 113.617 555.866 109.119 551.82C104.622 547.773 101.761 542.207 101.081 536.179L89.769 434.283C89.769 434.283 89.7689 434.283 89.7689 434.283C89.6351 433.06 89.7554 431.823 90.1223 430.65C90.4891 429.477 91.0945 428.393 91.8999 427.468C92.7052 426.542 93.693 425.795 94.8006 425.274C95.9063 424.753 97.1074 424.468 98.3279 424.438H217.096C218.321 424.461 219.529 424.74 220.641 425.258C221.755 425.776 222.75 426.522 223.561 427.448C224.372 428.375 224.982 429.461 225.352 430.638C225.722 431.815 225.844 433.056 225.71 434.282C225.71 434.283 225.71 434.283 225.71 434.283L214.398 536.18Z"
                fill="#FFF497" stroke="#212121" />
        </g>
        <defs>
            <clipPath id="clip0_272_6180">
                <rect width="1394" height="476" fill="white" />
            </clipPath>
        </defs>
    </svg>


</template>

<script>
    export default {
        name: "StepOneSVG",

        props: {
            color: {
                type: String,
                default: "#FFD51C"
            },
            accent: {
                type: String,
                default: "#6200EE"
            },
        },
    }
</script>