<template>
    <v-row dense>
        <v-col style="max-width:80px" class="pr-0">
            <v-select v-model="country" label="" hint="" item-text="name" return-object :clearable="false" :disabled="!allowCountryChange"
                :items="countries" v-bind="$attrs">
                <template v-slot:selection="{ item }">
                    <v-avatar :size="24">
                        <v-img :src="item.flag" />
                    </v-avatar>
                </template>
                <template v-slot:item="{ item }">
                    <v-avatar :size="24" class="mr-2">
                        <v-img :src="item.flag" />
                    </v-avatar>
                    +{{ item.dial }}
                </template>
            </v-select>
        </v-col>
        <v-col>
            <v-text-field type="tel" v-model="input" :rules="phoneRules" v-bind="$attrs" @keyup.enter="enter" />
        </v-col>
    </v-row>
</template>

<script>
    import PhoneNumber from 'awesome-phonenumber';
    import api from "@/services/api-service";

    export default {
        name: "MobilePhoneInput",
        inheritAttrs: false,

        props: {
            value: {
                type: String,
                default: ""
            },

            countryCode: {
                type: String,
                default: "US"
            },

            blackListedCountryCodes: {
                type: Array,
                default: () => []
            },

            enter: {
                type: Function
            },

            returnInternational: {
                type: Boolean,
                default: false
            },

            allowCountryChange:{
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                countries: [],
                country: null,
                input: null,

                valid: false,
                mobile: false,
                possible: false,
                national: null,
                international: null
            }
        },

        computed: {
            phoneRules() {
                return [
                    v => !!v || "Mobile number is required",
                    v => v && this.valid && this.mobile || `Please provide a valid Mobile number.`
                ];
            },

            dto() {
                return {
                    country: this.country,
                    national: this.national,
                    international: this.international,
                    valid: this.valid && this.mobile
                };
            }
        },

        watch: {
            input(v, ov) {
                if (!v) return;

                const pn = new PhoneNumber(v, this.country.code);

                this.valid = pn.isValid();
                this.mobile = pn.isMobile();
                this.possible = pn.isPossible();
                this.international = pn.getNumber();
                this.national = pn.getNumber('national');

                if (this.valid) {
                    this.input = this.national;
                    this.$emit("input", this.returnInternational ? this.international : this.national);
                    this.$emit("updated", this.dto);
                }
            },

            country(v, ov) {
                if (!v) return;
                this.$emit("country", this.country);
            }
        },

        methods: {
            async load() {
                try {
                    const uri = "global/countries";
                    const response = await api.get(uri);
                    if (response.status == 200 && response.data) {
                        const list = response.data.filter(i => !this.blackListedCountryCodes.includes(i.code));
                        this.countries = list;
                        this.country = list.find(c => c.code == this.countryCode);
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "load"
                    }, error);
                }
            }
        },

        mounted() {
            this.load();
        },
    }
</script>

<style scoped>

</style>