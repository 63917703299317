import Vue from "vue";

class MetaWrapper {
    setTitle(title) {
        document.title = title;
    }
}

const Meta = {
    install(Vue, options) {
        Vue.prototype.$Meta = new MetaWrapper();
    }
};

export default Meta;