<template>
    <v-img :src="partner.logo" v-bind="$attrs" v-if="partner" />
    <v-img src="https://parked.blob.core.windows.net/ownli/www/logos/logo.png" v-bind="$attrs" v-else/>
    <!-- <svg width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
        <path d="M512 1024C794.77 1024 1024 794.77 1024 512C1024 229.23 794.77 0 512 0C229.23 0 0 229.23 0 512C0 794.77 229.23 1024 512 1024Z" fill="black"/>
        <path d="M191.907 493.159C191.907 523.879 168.97 542.721 132.31 542.721H104.765V580.507H73.0208V443.495H132.31C168.97 443.495 191.907 462.439 191.907 493.159ZM159.754 493.159C159.754 478.107 149.514 469.3 130.57 469.3H104.765V516.916H130.57C149.923 516.916 159.754 508.11 159.754 493.159V493.159Z" fill="#FFD51C"/>
        <path d="M304.839 551.22H241.249L229.063 580.507H196.602L258.042 443.495H289.377L350.817 580.507H317.434L304.839 551.22ZM294.599 527.054L272.788 474.625L251.079 527.054H294.599Z" fill="#FFD51C"/>
        <path d="M459.05 580.507L432.528 542.721H403.344V580.814H371.6V443.495H430.992C467.651 443.495 490.589 462.439 490.589 493.159C490.956 502.42 488.433 511.565 483.368 519.326C478.304 527.088 470.95 533.081 462.326 536.475L493.046 580.507H459.05ZM429.251 469.3H403.344V517.121H429.251C448.605 517.121 458.435 508.11 458.435 493.159C458.435 478.209 448.605 469.3 429.251 469.3Z" fill="#FFD51C"/>
        <path d="M572.62 526.644L554.188 545.895V580.507H522.239V443.495H553.778V507.495L614.501 443.495H649.727L592.997 504.935L653.106 580.916H616.447L572.62 526.644Z" fill="#FFD51C"/>
        <path d="M813.158 443.495H875.418C920.269 443.495 950.989 470.427 950.989 512.001C950.989 553.575 920.269 580.507 875.418 580.507H813.158V443.495ZM873.882 554.497C901.12 554.497 918.938 538.215 918.938 512.001C918.938 485.787 901.12 469.505 873.882 469.505H844.902V554.497H873.882Z" fill="#FFD51C"/>
        <path d="M682.301 468.887H778.25V443.39H674.621V468.887H674.723H682.301Z" fill="#FFD51C"/>
        <path d="M682.506 580.608H780.81V555.11H674.621V580.608H674.723H682.506Z" fill="#FFD51C"/>
        <path d="M680.662 523.368H756.541V498.69H674.621V523.368H674.723H680.662Z" fill="#FFD51C"/>
    </svg> -->
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        name: "BrandLogo",
        inheritAttrs: false,

        computed: {
            ...mapGetters(["partner"]),
        }
    }
</script>

<style lang="scss" scoped>

</style>