<template>
    <v-autocomplete v-model="select" :loading="loading" :items="items" item-text="name" return-object
        :search-input.sync="search" no-filter hide-no-data v-bind="$attrs">
    </v-autocomplete>
</template>

<script>
    import api from "@/services/api-service";

    export default {
        name: "InsuranceProviderPicker",
        inheritAttrs: false,

        props: {
            country: {
                type: String,
                default: "US"
            },
        },

        data() {
            return {
                loading: false,
                items: [],
                search: null,
                select: null,
            }
        },
        watch: {
            search(val) {
                if (val && val !== (this.select && this.select.name)) {
                    this.querySelections(val);
                }
            },
            select(val) {
                // this.items = [];
                this.$emit("input", val);
            },
        },
        methods: {
            async querySelections(v) {
                this.loading = true;
                this.items = [];
                try {
                    const baseuri = "/insurance-providers/search";
                    const response = await api.get(`${baseuri}?country=${this.country}&order=name asc&limit=15&query=${v||''}`, {
                        requestId: baseuri
                    });
                    if (response && response.status == 200) {
                        this.items = response.data.items || [];
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "querySelections"
                    }, error);

                } finally {
                    this.loading = false;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>