import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,_vm._b({attrs:{"elevation":"1"}},'v-card',_vm.$attrs,false),[_c(VListItem,{attrs:{"three-line":_vm.lines == 3,"two-line":_vm.lines == 2}},[_c(VListItemAvatar,{attrs:{"tile":"","size":30}},[_vm._t("avatar")],2),_c(VListItemContent,[_c(VListItemTitle,[_c('div',[_vm._t("title")],2),_c(VSpacer),_c('div',{staticClass:"date"},[_vm._t("date")],2)],1),_c(VListItemSubtitle,[_vm._t("subtitle")],2)],1)],1),(_vm.hasActions)?_c(VCardActions,[_vm._t("actions")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }