<template>
  <v-container>
    <v-row fill-height v-if="requireActivation && !activeHttpRequests">
      <v-col cols="12">
        <ActivationCard :first="customer.policy.numberOfVehiclesActivated == 0" />
      </v-col>
    </v-row>
    
    <v-row align="center" justify="center">
        <template v-if="vehicles && vehicles.length > 0">
          <v-col cols="12" md="6" xl="4" v-for="vehicle in vehicles" :key="vehicle.id">
            <DisplayCard :vehicle="vehicle" :customer="customer" />
          </v-col>
        </template>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  import api from "@/services/api-service";

  export default {
    name: 'Vehicles',

    components: {
      ActivationCard: () => import("@/components/vehicle/ActivationCard"),
      DisplayCard: () => import("@/components/vehicle/DisplayCard"),
    },

    computed: {
      ...mapGetters(["customer", "vehicles","activeHttpRequests"]),

      activeVehiclesCount(){
        let active = this.vehicles.filter(v => v.isActiveOnPolicy);
        return active.length;
      },
      requireActivation() {
        return this.activeVehiclesCount < this.customer.policy.numberOfVehiclesOnPolicy;
      }
    },

    methods: {
      async loadVehicles() {
        try {
          // Fetch customer by login e-mail:
          const uri = `policies/${this.customer.policy.id}/vehicles`;
          const response = await api.get(uri, {
            requestId: uri
          });

          this.$store.commit("UPDATE_VEHICLES", response.data);
        } catch (error) {
          this.$Logger.error({
            component: this.$options.name,
            method: "loadVehicles"
          }, error);
        }
      }
    },

    mounted() {
      this.loadVehicles();
    }
  }
</script>