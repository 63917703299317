<template>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 896"><path d="M461,765.21q4.66-7.15,14.9-.89Q708.6,894.23,982.28,894.24q182.46,0,360.26-65.41,4.67-1.79,13.5-5.38t12.57-5.37q14-5.39,21.88,5.37t-5.12,19.71q-16.77,11.65-42.83,26.88-80.05,45.71-178.73,70.79t-192.7,25.09q-145.22,0-274.62-48.84T464.7,779.55q-5.59-4.49-5.59-9A9.41,9.41,0,0,1,461,765.21ZM741.18,509.85q0-61.82,31.65-105.73t86.57-66.3q50.26-20.61,123.81-29.57,25.14-2.69,81.92-7.17V285.85q0-57.35-13-77.05-19.55-26.88-61.44-26.88h-7.45q-30.72,2.69-53.06,18.81t-28.86,44.8q-3.72,17.93-18.62,20.61l-107-12.54Q759.8,250,759.79,237.47a25,25,0,0,1,.94-6.27q15.82-79.75,77.73-118.28t144.75-43h23.28q106.11,0,165.7,52.86A173.44,173.44,0,0,1,1189.41,142,175.5,175.5,0,0,1,1202,160.41q4.65,8.07,8.37,23.3t5.59,21q1.86,5.84,2.79,25.09t.93,22.85V469.53q0,23.3,7,42.56t13.49,27.78q6.52,8.5,21.41,27.33,5.6,8.06,5.59,14.33,0,7.17-7.45,12.55-77.26,64.5-83.78,69.88-11.17,8.07-27,1.8-13-10.75-22.8-20.61t-14-14.34q-4.19-4.47-13.49-17.47t-13-17.47q-52.12,54.66-102.4,68.09-31.65,9-78.19,9-71.68,0-117.76-42.56T741.18,509.85Zm160.11-17.92q0,34.95,18.16,56T968.32,569a49.12,49.12,0,0,0,7.91-.9,48.62,48.62,0,0,1,7-.89q39.1-9.86,60.51-48.39a137,137,0,0,0,15.36-37.18q5.12-20.16,5.59-32.71t.46-41.21v-22.4q-54,0-81.92,7.17Q901.3,414.88,901.29,491.93Zm391,288.51a28.44,28.44,0,0,1,5.59-7.16q23.27-15.24,44.68-20.61a314.37,314.37,0,0,1,68.89-9.86,57.8,57.8,0,0,1,17.69.9q41.89,3.59,50.27,13.44,3.72,5.37,3.72,16.13v6.27q0,31.35-17.69,73.92T1416.09,922q-4.66,3.59-8.38,3.59a8.52,8.52,0,0,1-3.73-.9q-5.58-2.69-2.79-9.86,34.44-77.94,34.44-108.41,0-9.85-3.72-14.34-9.32-10.76-52.13-10.75-15.83,0-37.24,1.79-23.26,2.68-42.82,5.38-5.58,0-7.45-1.79c-1.24-1.2-1.55-2.39-.93-3.59A6.72,6.72,0,0,1,1292.27,780.44Z" transform="translate(-459.11 -69.92)" style="fill:#212121"/></svg>
</template>

<script>
    export default {
        name: "PayoutSVG",
        props: {
            color: {
                type: String,
                default: "#FFD51C"
            },
        },
    }
</script>