<template>
  <v-card flat color="transparent" id="timeline">
    <v-card-title class="pb-0">
      <v-select hide-details outlined dense label="Vehicle" return-object item-text="caption" item-value="id"
        v-model="vehicle" :items="vehicles" :disabled="vehicles.length < 2" v-if="vehicles && vehicles.length > 0" />
    </v-card-title>

    <v-card-text class="timeline-container" id="future-rewards">
      <FutureRewards :vehicle="vehicle" :policy="policy" />
    </v-card-text>

    <v-card-text class="timeline-container" id="vehicle-timeline">
      <VehicleTimeline :vehicle="vehicle" :policy="policy" />
    </v-card-text>

    <v-card-text class="timeline-container" id="customer-timeline">
      <CustomerTimeline :user="user" :customer="customer" />
    </v-card-text>
  </v-card>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  import TimelineCard from "@/components/timeline/TimelineCard";
  import FutureRewards from "@/components/timeline/FutureRewards";
  import VehicleTimeline from "@/components/timeline/VehicleTimeline";
  import CustomerTimeline from "@/components/timeline/CustomerTimeline";

  export default {
    name: 'Timeline',

    components: {
      VehicleTimeline,
      CustomerTimeline,
      FutureRewards,
      TimelineCard
    },

    computed: {
      ...mapGetters(["user", "customer", "vehicles"]),

      policy() {
        return this.customer.policy;
      },
    },

    data() {
      return {
        vehicle: this.vehicles && this.vehicles[0],
      }
    },

    mounted() {
      this.vehicle = this.vehicles && this.vehicles[0];
    },
  }
</script>

<style scoped>
  #timeline {
    max-width: 724px;
    margin: auto;
  }

  .timeline-container {
    padding-bottom: 0;
    padding-top: 0;
  }

  .timeline-container>>>.v-timeline-item {
    padding-bottom: 15px;
  }

  .timeline-container>>>.v-timeline {
    padding-top: 15px;
  }

  .timeline-container>>>.v-timeline-item__dot--small {
    height: 8px;
    left: calc(50% - 4px);
    width: 8px;
  }

  .timeline-container>>>.v-timeline--reverse.v-timeline--dense:before {
    right: 4px;
    left: initial;
  }

  .timeline-container>>>.v-timeline-item__divider {
    min-width: 32px;
    justify-content: flex-end;
  }

  .timeline-container>>>.v-timeline-item__body {
    max-width: calc(100% - 32px);
    border: solid 2px var(--v-secondary-base);
    border-radius: 8px;
  }

  .theme--light.v-timeline::before {
    background: var(--v-secondary-base);
  }
</style>