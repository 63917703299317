<template>
    <v-timeline dense reverse>
        <transition name="fade" appear>
            <v-timeline-item fill-dot small color="primary" key="next-check-in"
                v-if="!vehicle || vehicle.isActiveOnPolicy">
                <TimelineCard color="primary" dark has-actions id="next-check-in-card" :lines="3" :loading="loading">
                    <template v-slot:avatar>
                        <component v-bind:is="getSvgComponent('CheckIn')" :color="$vuetify.theme.currentTheme.secondary">
                        </component>
                    </template>
                    <template v-slot:title>{{ !activated && !vehicle ? "1st " :"" }}Check-in</template>
                    <template v-slot:subtitle v-if="!activated && !vehicle">
                        Not by your car now?<br />Set a calendar reminder
                    </template>
                    <template v-slot:subtitle v-else-if="vehicle">
                        Next Check-in {{ vehicle.nextCheckInDate | moment("MMM DD, YYYY") }}<br /> Create a reminder for
                        yourself
                    </template>

                    <template v-slot:actions v-if="vehicle">
                        <v-btn small color="accent" :disabled="disableCheckIn"
                            :to="`/vehicles/${vehicle.id}/check-in`">
                            check-in now
                        </v-btn>
                        <ReminderButton small color="accent" outlined :disabled="offline || loading" :title="title" :description="description" :start="start" />
                    </template>

                    <template v-slot:actions v-else>
                        <v-btn small color="accent" elevation="8" to="/vehicle/activate">
                            check-in now
                        </v-btn>
                        <ReminderButton small color="accent" outlined :disabled="offline || loading" :title="title" :description="description" :start="start" />
                        <!-- <ReminderButton small :color="reminderColor" :outlined="timeToCheckIn" :disabled="offline || loading" :title="title" :description="description" :start="start" /> -->
                    </template>
                </TimelineCard>
            </v-timeline-item>
        </transition>

        <v-timeline-item fill-dot small color="primary" v-for="event in timeline" :key="event.id">
            <transition name="fade" mode="out-in" appear>
                <TimelineCard :loading="loading" :lines="event.description ? 3 : 2">
                    <template v-slot:avatar>
                        <v-avatar :size="30" color="primary" dark v-if="event.description">
                            <v-icon color="white">$vuetify.icons.global_info</v-icon>
                        </v-avatar>

                        <component v-bind:is="getSvgComponent(event.type)" :color="$vuetify.theme.currentTheme.primary" v-else></component>
                    </template>
                    <template v-slot:date>{{ event.time | moment("MMM DD, YYYY") }}</template>
                    <template v-slot:title>{{ event.title }}</template>
                    <template v-slot:subtitle v-if="event.description">{{ event.description }}</template>
                    <template v-slot:subtitle v-else>{{ event.subTitle }}</template>
                </TimelineCard>
            </transition>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
    import {
        mapGetters
    } from 'vuex';

    import api from "@/services/api-service";
    import TimelineCard from "@/components/timeline/TimelineCard"
    import TimerSVG from "@/components/timeline/TimerSVG"
    import RewardSVG from "@/components/timeline/RewardSVG"
    import ReminderButton from "@/components/global/ReminderButton";

    export default {
        name: "VehicleTimeline",

        components: {
            TimelineCard,
            TimerSVG,
            RewardSVG,
            ReminderButton
        },

        props: {
            vehicle: {
                type: Object,
                required: false
            },
            policy: {
                type: Object,
                required: true
            },
        },

        computed: {
            ...mapGetters(["offline","timelines_dict","isPrimaryColorDark"]),

            reminderColor(){
                return this.isPrimaryColorDark ? "accent" : "primary";
            },

disableCheckIn(){
                return this.policy.status == "Declined" || !this.timeToCheckIn || this.loading;
            },

            activated() {
                return this.policy.numberOfVehiclesActivated == this.policy.numberOfVehiclesOnPolicy;
            },
            timeToCheckIn() {
                if (!this.vehicle) {
                    return true;
                }
                return this.vehicle.nextCheckInDaysCount <= 0;
            },
            title() {
                return this.vehicle ?
                    `Ownli - Check-in your Vehicle ${this.vehicle.make || ''} ${this.vehicle.model || ''}` :
                    `Ownli - Start your vehicle Check-in`;
            },
            description() {
                return "Keep engaged with your policy check-ins reward on Ownli, <a href='https://www.ownli.co/'>Click to Check-in</a>";
            },
            start() {
                let date = this.timeToCheckIn ? this.$options.filters.moment(new Date(), "add", "2 hours",
                    "YYYY-MM-DD HH:MM") : this.vehicle.nextCheckInDate;
                return this.$options.filters.moment(date, "YYYY-MM-DD HH:MM");
            },
        },

        data() {
            return {
                loading: false,
                timeline: this._parseTimeline()
            }
        },

        watch: {
            vehicle(v, ov) {
                if (v) {
                    this.loadVehicleTimeline();
                }
            }
        },


        methods: {
            getEventAvatar(evnt) {
                switch (evnt) {
                    case "CheckIn":
                        return "https://parked.blob.core.windows.net/web/resources/images/V3/Timeline_Timer.svg";
                    case "Reward":
                        // return "https://parked.blob.core.windows.net/web/resources/images/V3/Gift_icon_outlined_24px.svg";
                        return "https://parked.blob.core.windows.net/web/resources/images/V3/Timeline_Amazon.svg";
                }
            },

            getSvgComponent(evnt) {
                switch (evnt) {
                    case "CheckIn":
                        return "TimerSVG";
                    case "Reward":
                        return "RewardSVG";
                }
            },

            _parseTimeline() {
                // console.log("_parseTimeline",this.timelines_dict)
                if (!this.timelines_dict || !this.vehicle) {
                    return [];
                }

                let list = this.timelines_dict[this.vehicle.id];
                return list;
            },

            async loadVehicleTimeline() {
                if (!this.vehicle) {
                    return;
                }
                try {
                    this.loading = true;
                    // Fetch customer by login e-mail:
                    const uri = `/vehicles/${this.vehicle.id}/timeline`;
                    const response = await api.get(uri, {
                        requestId: uri
                    });

                    if (response.status == 200 && response.data) {
                        this.$store.commit("UPDATE_TIMELINES", {
                            events: response.data,
                            vehicleId: this.vehicle.id
                        });
                        // this.timeline = response.data;
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "loadVehicleTimeline"
                    }, error);
                } finally {
                    this.timeline = this._parseTimeline();
                    this.loading = false;
                }
            },
        },

        mounted() {
            this.timeline = this._parseTimeline();
            this.loadVehicleTimeline();
        },
    }
</script>

<style scoped>
    #next-check-in-card>>>.v-card__actions>.v-btn {
        width: 49%;
    }
</style>