const partners = [
{
  "id": "ICOM_mujxw0MZC3wm",
  "host": "polaris.joinparked.com",
  "name": "Polaris",
  "logo": "https://parked.blob.core.windows.net/web/polaris/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "accent": "#03A9F4",
  "secondary": "#212121",
  "primary": "#004E97",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 
{
  "id": "ICOM_8BzKGGxno5JL",
  "host": "esure.joinparked.com",
  "name": "esure",
  "logo": "https://parked.blob.core.windows.net/web/esure/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "accent": "#F08906",
  "secondary": "#212121",
  "primary": "#207EB4",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 
{
  "id": "ICOM_MDUNAiCefiZM",
  "host": "buckle.joinparked.com",
  "name": "Buckle",
  "logo": "https://parked.blob.core.windows.net/web/buckle/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "accent": "#00EF73",
  "secondary": "#212121",
  "primary": "#1D252C",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 
{
  "id": "ICOM_p6XtS1EclSRY",
  "host": "novo.joinparked.com",
  "name": "Novo",
  "logo": "https://parked.blob.core.windows.net/web/novo/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "accent": "#292C3D",
  "secondary": "#212121",
  "primary": "#6CF96A",
  "darkPrimary": false,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 
{
  "id": "INS_VB3BSNCL9KS5",
  "host": "branch.joinparked.com",
  "name": "Branch",
  "logo": "https://parked.blob.core.windows.net/web/branch/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "accent": "#C93800",
  "secondary": "#212121",
  "primary": "#527059",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 
{
  "id": "ICOM_9MAaqbKDWdzf",
  "host": "kentucky-national.joinparked.com",
  "name": "kentucky-national",
  "logo": "https://parked.blob.core.windows.net/web/kentucky-national/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "accent": "#BB8D09",
  "secondary": "#212121",
  "primary": "#083A81",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 
{
  "id": "ICOM_XwLypzroKxGO",
  "host": "sensa.joinparked.com",
  "name": "Sensa",
  "logo": "https://parked.blob.core.windows.net/web/sensa/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "accent": "#9BDD0B",
  "secondary": "#212121",
  "primary": "#00A0D2",
  "darkPrimary": false,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 
{
  "id": "INS_A2QFWOSJHGHQ",
  "host": "erie.joinparked.com",
  "name": "Erie",
  "logo": "https://parked.blob.core.windows.net/web/erie/logos/logo.png",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "primary": "#0079C1",
  "secondary": "#212121",
  "accent": "#03A9F4",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 

{
  "id": "INS_K8DSEKUPUF92",
  "host": "safeauto.joinparked.com",
  "name": "SafeAuto",
  "logo": "https://parked.blob.core.windows.net/web/safeauto/logos/safeauto.jpg",
  "country": "United States",
  "countryCode": "US",
  "countryDialCode": "1",
  "currency": "$",
  "currencyCode": "USD",
  "maxVehiclesPerPolicy": 2,
  "primary": "#B71C1C",
  "secondary": "#212121",
  "accent": "#6F1919",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, 

{
  "id": "INS_08WUA8FDBX8A",
  "host": "diamond.joinparked.com",
  "name": "Diamond",
  "logo": "https://parked.blob.core.windows.net/web/diamond/logos/logo.svg",
  "country": "United Kingdom",
  "countryCode": "GB",
  "countryDialCode": "44",
  "currency": "£",
  "currencyCode": "GBP",
  "maxVehiclesPerPolicy": 2,
  "primary": "#82045A",
  "secondary": "#333333",
  "accent": "#FFD51C",
  "darkPrimary": true,
  "nativeAppLink_iOS": "https://apps.apple.com/us/app/diamond-mileage-reporting/id1614068252",
  "nativeAppLink_Android": "https://play.google.com/store/apps/details?id=com.joinparked.diamond"
}, {
  "id": "ICOM_uINS7SgcOl7q",
  "host": "zurich-jp.joinparked.com",
  "name": "Zurich",
  "logo": "https://parked.blob.core.windows.net/web/zurich-jp/logos/zurich.png",
  "country": "Japan",
  "countryCode": "JP",
  "countryDialCode": "81",
  "currency": "¥",
  "currencyCode": "JPY",
  "maxVehiclesPerPolicy": 1,
  "primary": "#23366f",
  "secondary": "#333333",
  "accent": "#1fb1e6",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, {
  "id": "ICOM_NZJ0qsfK2eq5",
  "host": "aig-isr.joinparked.com",
  "name": "AIG",
  "logo": "https://parked.blob.core.windows.net/web/aig-isr/logos/aig_logo.png",
  "country": "Israel",
  "countryCode": "IL",
  "countryDialCode": "972",
  "currency": "₪",
  "currencyCode": "ILS",
  "maxVehiclesPerPolicy": 2,
  "primary": "#001871",
  "secondary": "#313131",
  "accent": "#00A4E4",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}, {
  "id": "ICOM_WE2Eoa08Sf1r",
  "host": "migdal.joinparked.com",
  "name": "Migdal",
  "logo": "https://parked.blob.core.windows.net/web/migdal/logos/migdal_logo.png",
  "country": "Israel",
  "countryCode": "IL",
  "countryDialCode": "972",
  "currency": "₪",
  "currencyCode": "ILS",
  "maxVehiclesPerPolicy": 2,
  "primary": "#df4e16",
  "secondary": "#37474F",
  "accent": "#143175",
  "darkPrimary": true,
  "nativeAppLink_iOS": null,
  "nativeAppLink_Android": null
}];
export default partners;