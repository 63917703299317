<template>
<svg viewBox="0 0 1025 372" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M200 57.0516L150.13 30.1303L199.29 4.77844L200 57.0516L150.13 30.1303L199.29 4.77844L200 57.0516Z" fill="#5600E8"/>
<path d="M859.24 252.179L809.35 225.257L858.51 199.936L859.24 252.209L809.35 225.287L858.51 199.936L859.24 252.179Z" fill="#5600E8"/>
<path d="M970 310.47L937.63 312.639L951.72 286.018L970 310.47L937.63 312.639L951.72 286.018L970 310.47Z" fill="#5600E8"/>
<path d="M1011.55 289.597C1011.57 288.822 1011.44 288.052 1011.15 287.331C1010.87 286.61 1010.45 285.953 1009.9 285.4C1009.36 284.847 1008.71 284.408 1008 284.111C1007.28 283.813 1006.51 283.663 1005.74 283.669C1004.19 283.7 1002.71 284.338 1001.62 285.446C1000.53 286.554 999.922 288.044 999.92 289.597C999.9 290.371 1000.04 291.141 1000.32 291.862C1000.6 292.582 1001.03 293.238 1001.57 293.791C1002.12 294.343 1002.77 294.78 1003.48 295.076C1004.2 295.373 1004.97 295.522 1005.74 295.515C1007.28 295.44 1008.73 294.789 1009.8 293.692C1010.88 292.595 1011.5 291.133 1011.55 289.597V289.597Z" fill="#FF6B73"/>
<path d="M56.1402 358.674C56.0929 356.097 55.2852 353.591 53.8184 351.471C52.3516 349.351 50.2911 347.711 47.8955 346.757C45.4998 345.803 42.8757 345.578 40.3525 346.11C37.8293 346.641 35.5194 347.906 33.7127 349.746C31.9059 351.585 30.6829 353.917 30.1969 356.448C29.711 358.98 29.9839 361.599 30.9813 363.976C31.9787 366.353 33.6562 368.383 35.8034 369.81C37.9505 371.238 40.4716 371.999 43.0502 372C44.7877 371.993 46.5066 371.643 48.1075 370.968C49.7085 370.293 51.1596 369.307 52.377 368.068C53.5944 366.829 54.5538 365.36 55.1997 363.748C55.8457 362.135 56.1653 360.411 56.1402 358.674V358.674Z" fill="#FFD51C"/>
<path d="M94.6698 304.362C94.6323 302.358 94.0036 300.409 92.8625 298.761C91.7214 297.112 90.1188 295.837 88.2556 295.096C86.3925 294.355 84.3519 294.18 82.3898 294.594C80.4278 295.008 78.6317 295.992 77.2269 297.423C75.8222 298.853 74.8713 300.666 74.4937 302.635C74.116 304.604 74.3284 306.64 75.1042 308.489C75.88 310.337 77.1846 311.916 78.8543 313.026C80.524 314.136 82.4845 314.728 84.4898 314.728C87.2136 314.699 89.8149 313.592 91.7232 311.649C93.6314 309.705 94.6911 307.085 94.6698 304.362V304.362Z" fill="#22BCC5"/>
<path d="M136.75 258.696C136.703 256.119 135.895 253.613 134.428 251.493C132.962 249.373 130.901 247.733 128.505 246.779C126.11 245.825 123.486 245.6 120.962 246.132C118.439 246.664 116.129 247.928 114.323 249.768C112.516 251.607 111.293 253.939 110.807 256.47C110.321 259.002 110.594 261.621 111.591 263.998C112.589 266.375 114.266 268.405 116.413 269.832C118.56 271.26 121.081 272.022 123.66 272.022C125.398 272.016 127.116 271.665 128.717 270.99C130.318 270.315 131.769 269.329 132.987 268.09C134.204 266.851 135.164 265.382 135.81 263.77C136.456 262.158 136.775 260.433 136.75 258.696Z" fill="#5600E8"/>
<path d="M88.64 186.34C88.6601 185.566 88.5244 184.797 88.241 184.077C87.9575 183.357 87.5321 182.702 86.99 182.15C86.448 181.597 85.8003 181.16 85.0856 180.863C84.371 180.566 83.6039 180.416 82.83 180.422C81.2764 180.448 79.7951 181.083 78.7049 182.189C77.6146 183.296 77.0024 184.787 77 186.34C76.9799 187.115 77.1158 187.885 77.3998 188.607C77.6837 189.328 78.1099 189.984 78.6529 190.537C79.1959 191.09 79.8446 191.529 80.5605 191.826C81.2764 192.123 82.0448 192.274 82.82 192.268C84.3583 192.193 85.8118 191.541 86.8905 190.443C87.9693 189.344 88.594 187.879 88.64 186.34Z" fill="#5600E8"/>
<path d="M142 140.955L113.57 95.8091H170.8L142 140.955L113.57 95.8091H170.8L142 140.955Z" fill="#22BCC5"/>
<path d="M277.17 45.1054L248.71 0H305.94L277.17 45.1054L248.71 0H305.94L277.17 45.1054Z" fill="#22BCC5"/>
<path d="M100.38 60.8604L98 31.5199L127.05 44.2858L100.38 60.8604L98 31.5199L127.05 44.2858L100.38 60.8604Z" fill="#5600E8"/>
<path d="M1024 157.609C1023.95 155.032 1023.14 152.527 1021.67 150.408C1020.21 148.29 1018.14 146.651 1015.75 145.699C1013.35 144.747 1010.73 144.523 1008.21 145.056C1005.68 145.589 1003.37 146.854 1001.57 148.694C999.763 150.533 998.541 152.865 998.056 155.397C997.571 157.928 997.844 160.546 998.842 162.923C999.84 165.299 1001.52 167.328 1003.66 168.756C1005.81 170.183 1008.33 170.944 1010.91 170.945C1012.65 170.938 1014.37 170.587 1015.97 169.912C1017.57 169.236 1019.02 168.25 1020.24 167.009C1021.46 165.769 1022.42 164.299 1023.06 162.686C1023.71 161.072 1024.03 159.347 1024 157.609Z" fill="#FF6B73"/>
<path d="M49.6898 85.8024C49.7086 85.0285 49.5717 84.2588 49.2871 83.5388C49.0025 82.8189 48.5761 82.1635 48.0333 81.6115C47.4904 81.0595 46.8421 80.6221 46.1269 80.3254C45.4117 80.0287 44.6442 79.8787 43.8698 79.8843C42.3205 79.9182 40.8458 80.556 39.7604 81.6617C38.6749 82.7673 38.0647 84.2532 38.0598 85.8024C38.0383 86.5766 38.173 87.3472 38.4559 88.0683C38.7387 88.7893 39.1639 89.4461 39.7061 89.9993C40.2483 90.5525 40.8964 90.9909 41.6118 91.2883C42.3272 91.5858 43.0951 91.7362 43.8698 91.7305C45.4081 91.6557 46.8616 91.0039 47.9404 89.9051C49.0191 88.8063 49.6438 87.3413 49.6898 85.8024V85.8024Z" fill="#FF6B73"/>
<path d="M1005.56 69.5075C1005.58 68.7337 1005.44 67.9637 1005.16 67.2432C1004.88 66.5228 1004.45 65.8666 1003.91 65.3137C1003.37 64.7608 1002.72 64.3223 1002.01 64.0244C1001.29 63.7265 1000.52 63.5752 999.75 63.5794C998.197 63.6108 996.719 64.2489 995.631 65.3569C994.543 66.465 993.932 67.9549 993.93 69.5075C993.91 70.2818 994.046 71.0521 994.33 71.7727C994.614 72.4932 995.04 73.1492 995.584 73.7015C996.127 74.2538 996.776 74.6911 997.491 74.9873C998.207 75.2835 998.975 75.4326 999.75 75.4256C1001.29 75.3508 1002.74 74.7001 1003.81 73.6032C1004.89 72.5062 1005.51 71.0438 1005.56 69.5075V69.5075Z" fill="#FF6B73"/>
<path d="M706.52 63.2097C706.54 62.4355 706.404 61.6651 706.12 60.9446C705.836 60.224 705.41 59.568 704.866 59.0157C704.323 58.4634 703.674 58.0261 702.959 57.7299C702.243 57.4337 701.475 57.2846 700.7 57.2916C699.148 57.3204 697.669 57.9563 696.581 59.0627C695.493 60.1692 694.882 61.6581 694.88 63.2097C694.86 63.9844 694.996 64.7552 695.28 65.4763C695.564 66.1974 695.99 66.8541 696.533 67.4071C697.076 67.9602 697.725 68.3985 698.44 68.6958C699.156 68.9931 699.925 69.1434 700.7 69.1378C702.238 69.063 703.692 68.4112 704.77 67.3124C705.849 66.2136 706.474 64.7486 706.52 63.2097V63.2097Z" fill="#FF6B73"/>
<path d="M520.13 64.0692C520.15 63.2959 520.014 62.5263 519.731 61.8064C519.448 61.0866 519.022 60.431 518.48 59.8789C517.938 59.3267 517.29 58.8892 516.576 58.5924C515.861 58.2956 515.094 58.1455 514.32 58.1512C512.768 58.1799 511.289 58.8158 510.201 59.9223C509.113 61.0288 508.502 62.5176 508.5 64.0692C508.483 64.8423 508.621 65.611 508.906 66.3297C509.191 67.0485 509.617 67.7027 510.16 68.2536C510.703 68.8046 511.351 69.241 512.065 69.5371C512.78 69.8332 513.547 69.9829 514.32 69.9773C515.853 69.9004 517.301 69.2504 518.378 68.156C519.454 67.0617 520.079 65.603 520.13 64.0692Z" fill="#FF6B73"/>
<path d="M430.24 39.6172C430.26 38.8425 430.124 38.0717 429.84 37.3506C429.556 36.6295 429.13 35.9729 428.587 35.4198C428.044 34.8667 427.395 34.4284 426.679 34.1311C425.963 33.8338 425.195 33.6835 424.42 33.6891C422.869 33.7231 421.393 34.3623 420.307 35.4701C419.221 36.5779 418.612 38.0663 418.61 39.6172C418.59 40.3906 418.725 41.1601 419.009 41.88C419.292 42.5999 419.718 43.2554 420.26 43.8076C420.802 44.3597 421.45 44.7972 422.164 45.094C422.879 45.3909 423.646 45.5409 424.42 45.5353C425.957 45.463 427.411 44.8133 428.49 43.7161C429.569 42.6189 430.194 41.1551 430.24 39.6172V39.6172Z" fill="#FF6B73"/>
<path d="M954.78 79.5044C954.733 76.9266 953.925 74.4202 952.457 72.2999C950.99 70.1795 948.929 68.5397 946.533 67.5862C944.137 66.6328 941.512 66.4082 938.988 66.9407C936.465 67.4732 934.155 68.739 932.349 70.5793C930.543 72.4196 929.32 74.7523 928.835 77.2845C928.351 79.8168 928.625 82.4358 929.624 84.8128C930.623 87.1898 932.302 89.2188 934.45 90.6452C936.599 92.0715 939.121 92.8317 941.7 92.8301C943.437 92.8237 945.155 92.4728 946.756 91.7978C948.356 91.1227 949.807 90.1369 951.023 88.8975C952.24 87.6581 953.198 86.1897 953.843 84.5773C954.488 82.9649 954.806 81.2407 954.78 79.5044V79.5044Z" fill="#FFD51C"/>
<path d="M961 226.167C960.97 224.448 960.433 222.775 959.456 221.36C958.479 219.945 957.106 218.85 955.508 218.212C953.91 217.574 952.16 217.423 950.477 217.776C948.793 218.13 947.252 218.972 946.046 220.199C944.84 221.425 944.023 222.98 943.698 224.669C943.373 226.358 943.555 228.105 944.22 229.69C944.885 231.276 946.003 232.631 947.436 233.583C948.868 234.536 950.55 235.044 952.27 235.044C953.428 235.042 954.575 234.809 955.642 234.36C956.71 233.912 957.678 233.255 958.49 232.429C959.302 231.604 959.942 230.625 960.373 229.55C960.804 228.475 961.017 227.325 961 226.167V226.167Z" fill="#FFD51C"/>
<path d="M31.76 294.535C31.76 286.658 24.57 280.14 15.88 280.14C7.19 280.14 0 286.658 0 294.535C0 302.412 7.19 308.9 15.88 308.9C24.57 308.9 31.76 302.692 31.76 294.535Z" fill="#22BCC5"/>
<path d="M598 42.3364C598.02 41.5625 597.885 40.7925 597.601 40.0721C597.318 39.3517 596.893 38.6955 596.351 38.1426C595.809 37.5896 595.161 37.1512 594.447 36.8532C593.732 36.5553 592.964 36.404 592.19 36.4083C590.637 36.4397 589.159 37.0777 588.071 38.1858C586.983 39.2939 586.372 40.7838 586.37 42.3364C586.35 43.1106 586.486 43.881 586.77 44.6015C587.054 45.3221 587.48 45.9781 588.024 46.5304C588.567 47.0827 589.216 47.52 589.931 47.8162C590.647 48.1124 591.415 48.2614 592.19 48.2545C593.726 48.1797 595.177 47.5289 596.254 46.432C597.33 45.3351 597.954 43.8726 598 42.3364Z" fill="#FF6B73"/>
<path d="M343.94 53.8527C343.92 52.7064 343.562 51.5915 342.911 50.648C342.259 49.7044 341.344 48.9743 340.279 48.5492C339.214 48.1242 338.047 48.0231 336.924 48.2588C335.802 48.4944 334.774 49.0563 333.971 49.8738C333.167 50.6913 332.622 51.7281 332.406 52.8539C332.189 53.9796 332.31 55.1443 332.753 56.2016C333.196 57.2589 333.942 58.1618 334.897 58.7968C335.852 59.4317 336.973 59.7706 338.12 59.7708C338.892 59.7682 339.656 59.6126 340.367 59.3131C341.079 59.0135 341.724 58.5759 342.265 58.0255C342.806 57.4752 343.233 56.823 343.52 56.1068C343.808 55.3905 343.951 54.6244 343.94 53.8527V53.8527Z" fill="#22BCC5"/>
<path d="M823.13 52.713C823.13 46.715 817.74 42.1165 811.44 42.1165C804.85 42.1165 799.76 47.0149 799.76 52.713C799.76 58.7111 805.15 63.3096 811.44 63.3096C817.73 63.3096 823.13 58.6911 823.13 52.713Z" fill="#22BCC5"/>
</svg>
</template>

<script>
    export default {
        name: "RewardsConfettiSVG",
    }
</script>

<style lang="scss" scoped>

</style>