import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "vuetify/es5/util/colors";
import { Ripple } from "vuetify/lib/directives"

Vue.use(Vuetify);

const opts =
{
  //   components: {
  //     VApp,
  //     VNavigationDrawer,
  //     VFadeTransition,
  //     VLayout,
  //     VCard,
  //     VCardTitle,
  //     VCardText,
  //     VCardActions,
  //     VSheet
  //   },

  directives: {
    Ripple
  },

  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#6200EE",
        secondary: "#15B9A6",
        accent: "#F34747"
      },
      dark: {
        primary: "#6200EE",
        secondary: "#15B9A6",
        accent: "#F34747"
      }
    }
  },

  icons: {
    iconfont: "mdi", // default
    values: {
      pages_vehicles: "mdi-car",
      pages_timeline: "mdi-chart-timeline-variant",
      pages_rewards: "mdi-gift-outline",
      pages_profile: "mdi-face-man-profile",
      pages_policy: "mdi-file-document-outline",
      pages_faq: "mdi-chat-question-outline",
      pages_contact: "mdi-message-text-outline",
      pages_about: "mdi-information-outline",

      actions_download: "mdi-download",
      actions_add: "mdi-plus",
      actions_more: "mdi-chevron-right",
      actions_share: "mdi-share-variant-outline",
      actions_logout: "mdi-logout-variant",
      actions_take_photo: "mdi-camera-plus-outline",
      actions_retake_photo: "mdi-camera-retake-outline",
      actions_drag: "mdi-drag-variant",

      camera_take_photo: "mdi-camera-outline",
      camera_retake_photo: "mdi-camera-retake-outline",
      camera_switch: "mdi-camera-flip-outline",

      global_alert: "mdi-alert",
      global_info: "mdi-exclamation-thick",
      global_check: "mdi-check",
      global_clock: "mdi-timeline-clock-outline",
      global_timer: "mdi-timer-sand",
      global_required: "mdi-asterisk",
      global_back: "mdi-chevron-left",
      global_close: "mdi-close",
      global_camera_location: "mdi-camera-marker-outline",
      global_location: "mdi-map-marker-radius-outline",
      global_offline: "mdi-signal-off",
      global_weak_signal: "mdi-signal-cellular-1",
      global_cellphone: "mdi-cellphone",
      global_email: "mdi-email",
      global_checkin: "mdi-clock-check-outline",

      calendar_google: "mdi-google",
      calendar_microsoft_office: "mdi-microsoft-office",
      calendar_microsoft_outlook: "mdi-microsoft-outlook",
      calendar_ics: "mdi-calendar-clock",

      auth_google: "mdi-google",
      auth_apple: "mdi-apple",
      auth_facebook: "mdi-facebook",
      auth_email: "mdi-email-outline",
      auth_sms: "mdi-cellphone",

      appstore:"mdi-apple",
      playstore:"mdi-google-play",
      web:"mdi-web",
    }
  }
};

export default new Vuetify(opts);
