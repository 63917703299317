import {
  initializeApp
} from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";

import Vue from 'vue'
import store from '@/store';

//Initialize FireBase App:
const firebaseConfig = {
  apiKey: "AIzaSyCeBTkI_XP09eHlTAvo4H2jL7FdhSKKYgc",
  authDomain: "parked-flutter.firebaseapp.com",
  databaseURL: "https://parked-flutter-default-rtdb.firebaseio.com",
  projectId: "parked-flutter",
  storageBucket: "parked-flutter.appspot.com",
  messagingSenderId: "378485831069",
  appId: "1:378485831069:web:ffe9fd0c8246915d262b74",
  measurementId: "G-SS4NZZ2X12"
};
initializeApp(firebaseConfig);
//Bind FireBase Auth State to Vuex:
const auth = getAuth();
Vue.prototype.$auth = auth;
onAuthStateChanged(auth, u => {
  if (!u) {
    store.commit('UPDATE_USER', null);
    return;
  }

  // console.log("firebase.onAuthStateChanged,", u);

  const email = u.email || u.providerData[0].email;
  const name = u.displayName || u.providerData[0].displayName;
  const phone = u.phoneNumber || u.providerData[0].phoneNumber;

  const user = {
    uid: u.uid,
    name: name,
    email: email,
    phone: phone,
    photo: u.photoURL,
    token: u.accessToken,
    provider: u.providerData[0] && u.providerData[0].providerId,
    created: u.metadata && u.metadata.creationTime
  };
  store.commit('UPDATE_USER', user);
});

export default auth;