<template>
    <div class="pa-4">
        <v-card flat full-width fill-height class="card-center" color="transparent">
            <v-card-title class="pa-0">
                <v-avatar size="6rem">
                    <v-img :src="user.photo" v-if="user && user.photo" />
                    <BrandLogo v-else />
                </v-avatar>
                <h3>My Profile</h3>
            </v-card-title>

            <v-card-text>
                <p class="opacity mt-2">This information will NOT be shared <br /> without your explicit consent</p>
            </v-card-text>

            <v-card-text class="pt-0 added-bottom-pad">
                <v-form v-model="valid" ref="form" lazy-validation>
                    <v-row dense>
                        <v-col cols="6">
                            <v-text-field filled v-model="customer.nameFirst" :rules="nameRules" disabled label="First name"
                                          append-icon="$vuetify.icons.global_required" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field filled v-model="customer.nameLast" :rules="nameRules" disabled label="Last name"
                                          append-icon="$vuetify.icons.global_required" />
                        </v-col>

                        <v-col cols="4">
                            <v-select filled v-model="countryCode" item-text="label" item-value="value" :items="countries"
                                      :rules="requiredRule" disabled label="Country">
                            </v-select>
                        </v-col>

                        <v-col cols="8">
                            <v-text-field filled v-model="customer.contactPhone"
                                          disabled label="Mobile number" type="tel" append-icon="$vuetify.icons.global_required">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field filled :value="user.email" :rules="emailRules" required disabled :label="loginEmailLabel"
                                          type="email" :append-icon="loginEmailIcon" />
                        </v-col>

                    </v-row>

                    <div class="mb-5">
                        <h3>Check-in Notifications</h3>
                    </div>

                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-text-field filled v-model="customer.contactEmail" :rules="emailRules" required
                                          label="Primary e-mail used for notifications" type="email"
                                          append-icon="$vuetify.icons.global_required" />
                        </v-col>

                        <v-col cols="12" class="mb-10">
                            <v-switch inset hide-details v-model="customer.notificationsCheckins" @change="_handleNotificationsToggle"
                                      label="Email Notifications" color="secondary" class="reverted mt-0" />

                            <v-switch inset hide-details v-model="customer.notificationsSMS" @change="_handleNotificationsToggle"
                                      label="SMS Notifications" color="secondary"  class="reverted"/>

                            <!-- <v-switch inset hide-details v-model="customer.notificationsPush" @change="_handleNotificationsToggle"
                                      label="Push Notifications" color="accent"  class="reverted"/> -->
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="mb-2">
                                Need any help? <a target="_blank"
                                                  href="https://joinparked.zendesk.com/hc/en-us/requests/new">Contact us</a>
                            </div>
                            <v-btn x-large block color="primary" elevation="8" @click="submit" :loading="loading">
                                submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-dialog v-model="confirmationDialog" persistent max-width="350px">
                <v-card class="card-center">
                    <v-card-title class="text-h5">
                        Are you sure?
                    </v-card-title>

                    <v-card-text>
                        This means you’ll miss out on the reminders to check-in and earn the Amazon rewards.
                    </v-card-text>

                    <v-card-text>
                        <v-btn block large color="primary" class="mb-2" @click="_switchNotificationsOn">
                            NO
                        </v-btn>
                        <v-btn block large color="primary" outlined @click="_switchNotificationsOff">
                            YES
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import api from "@/services/api-service";

    export default {
        name: 'Profile',

        computed: {
            ...mapGetters(["user", "customer", "partner"]),

            policy() {
                return this.customer && this.customer.policy;
            },

            countryCode() {
                return this.policy.discountsProviderCountryCode;
            },

            authProvider() {
                return this.user && this.user.provider;
            },

            loginEmailLabel() {
                switch (this.authProvider) {
                    default:
                        return "Login E-mail";

                    case "google.com":
                        return "Google account Login";

                    case "facebook.com":
                        return "Facebook account Login";

                    case "apple.com":
                        return "Apple account Login";
                }
            },
            loginEmailIcon() {
                switch (this.authProvider) {
                    default:
                        return "$vuetify.icons.auth_email";

                    case "google.com":
                        return "$vuetify.icons.auth_google";

                    case "facebook.com":
                        return "$vuetify.icons.auth_facebook";

                    case "apple.com":
                        return "$vuetify.icons.auth_apple";
                }
            },

            maxNumberOfVehiclesOnPolicy() {
                let items = [];
                for (let index = 1; index <= this.policy.discountsProviderMaxVehiclesPerPolicy; index++) {
                    items.push({
                        text: `${index}`,
                        value: index
                    });
                }

                return items;
            }
        },

        data() {
            return {
                loading: false,
                loaded: false,
                valid: false,
                confirmationDialog: false,

                countries: [{
                    label: "+1",
                    value: "US",
                }, {
                    label: "+44",
                    value: "GB",
                }, {
                    label: "+972",
                    value: "IL",
                }],

                requiredRule: [v => !!v || "Field is required"],
                nameRules: [
                    v => !!v || "Name is required",
                    v => v && v.length <= 20 || "Name must be less than 10 characters"
                ],
                emailRules: [
                    v => !!v || "E-mail is required",
                    v => v && /.+@.+/.test(v) || "E-mail must be valid"
                ],
                phoneRules: [
                    v => !!v || "Phone is required"
                ]
            }
        },

        methods: {
            _handleNotificationsToggle() {
                if (!this.customer.notificationsCheckins) {
                    this.confirmationDialog = true;
                }
            },
            _switchNotificationsOff() {
                this.customer.notificationsCheckins = false;
                this.confirmationDialog = false;
            },
            _switchNotificationsOn() {
                this.customer.notificationsCheckins = true;
                this.confirmationDialog = false;
            },
            async submit() {
                if (!this.$refs.form.validate()) {
                    return null;
                }

                try {
                    this.loading = true;
                    const request = {
                        NameFirst: this.customer.nameFirst,
                        NameLast: this.customer.nameLast,
                        ContactEmail: this.customer.contactEmail,
                        ContactPhone: this.customer.contactPhone,
                        NotificationsNewsletter: this.customer.notificationsNewsletter,
                        NotificationsCheckins: this.customer.notificationsCheckins,
                    };
                    // Put customer's profile data:
                    const response = await api.put(`customers/${this.customer.id}`, request);
                    if (response.status == 202 && response.data) {
                        this.$store.commit("UPDATE_CUSTOMER", response.data);
                        this.$router.replace("/vehicles");
                    }
                } catch (error) {

                    this.$Logger.error({
                        component: this.$options.name,
                        method: "submit"
                    }, error);

                } finally {
                    this.loading = false;
                }
            }
        },
    }
</script>

<style scoped>
    .v-input--switch >>> .v-input__slot {
        flex-flow: row-reverse;
    }

        .v-input--switch >>> .v-input__slot .v-input--selection-controls__input {
            margin-right: 0;
            margin-left: 1rem;
        }
</style>