import Vue from 'vue'
import Vuex from 'vuex'

import logRocketPlugin from 'logrocket-vuex';
import persistedStatePlugin from "vuex-persistedstate"; //https://www.npmjs.com/package/vuex-persistedstate
import platform from "platform";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    logRocketPlugin,
    persistedStatePlugin({
      paths: ['user', 'partner', 'customer', 'vehicles', 'pending_activation', 'pending_checkin', 'checkins_dict', 'timelines_dict', 'discounts', 'payouts']
    })
  ],

  state: {
    connection: navigator.connection && navigator.connection.effectiveType,
    updateInProgress: false,
    offline: false,
    httpRequests: 0,
    notifications: [],

    ipinfo: null,
    user: null,
    partner: null,
    customer: null,
    vehicles: [],
    pending_activation: null,
    pending_checkin: null,
    checkins_dict: {},
    timelines_dict: {},
    discounts: [],
    payouts: [],
  },

  getters: {
    activeHttpRequests(state) {
      return state.httpRequests > 0;
    },

    device(state) {
      return {
        manufacturer: platform.manufacturer,
        product: platform.product,
        os: platform.os.toString()
      };
    },

    isMobileDevice(state) {
      const os = platform.os;
      // return state.size.width < 600 || state.size.height < 600;
      return os.family == "iOS" || os.family == "Android";
    },

    connection(state) {
      return state.connection;
    },

    offline(state) {
      return state.offline;
    },

    updateInProgress(state) {
      return state.updateInProgress;
    },

    notifications(state) {
      return state.notifications;
    },

    ipinfo(state) {
      return state.ipinfo;
    },

    user(state) {
      return state.user;
    },

    token(state) {
      return state.user && state.user.token;
    },

    partner(state) {
      return state.partner;
    },

    customer(state) {
      return state.customer;
    },
    vehicles(state) {
      return state.vehicles;
    },
    pending_activation(state) {
      return state.pending_activation;
    },
    pending_checkin(state) {
      return state.pending_checkin;
    },
    checkins_dict(state) {
      return state.checkins_dict;
    },
    checkins_last_dict(state) {
      const dict = state.checkins_dict;
      let last_checkins = {};
      Object.keys(dict).forEach(key => {
        last_checkins[key] = dict[key][0];
      });

      return last_checkins;
    },
    timelines_dict(state) {
      return state.timelines_dict;
    },
    discounts(state) {
      return state.discounts;
    },
    payouts(state) {
      return state.payouts;
    },
    isPrimaryColorDark(state) {
      return state.partner && state.partner.darkPrimary || true;
    }
  },

  mutations: {
    UPDATE_FOUND(state) {
      state.updateInProgress = true;
    },
    UPDATED_PWA(state) {
      state.updateInProgress = false;
    },

    SET_CONNECTION(state, connection) {
      state.connection = connection;
    },
    SET_OFFLINE(state, offline) {
      state.offline = offline;
    },


    ADD_HTTP_REQUEST(state) {
      // console.log("ADD_HTTP_REQUEST",state.httpRequests)
      const requests = state.httpRequests + 1;
      state.httpRequests = requests;
    },
    REMOVE_HTTP_REQUEST(state) {
      // console.log("REMOVE_HTTP_REQUEST",state.httpRequests)
      const requests = state.httpRequests > 1 ? state.httpRequests - 1 : 0;
      state.httpRequests = requests;
    },


    ADD_NOTIFICATION(state, payload) {
      state.notifications.push({
        id: new Date().toISOString(),
        show: true,
        auto_dismiss: true,
        ...payload
      });
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notifications = state.notifications.filter(i => i.id !== id);
    },
    CLEAR_NOTIFICATIONS(state, payload) {
      state.notifications = [];
    },


    UPDATE_IPINFO(state, payload) {
      state.ipinfo = payload;
    },

    UPDATE_USER(state, payload) {
      state.user = payload;
    },

    UPDATE_PARTNER(state, payload) {
      state.partner = payload;
    },


    UPDATE_CUSTOMER(state, payload) {
      state.customer = payload;
    },

    UPDATE_DISCOUNTS(state, payload) {
      state.discounts = payload;
    },

    UPDATE_PAYOUTS(state, payload) {
      state.payouts = payload;
    },


    UPDATE_VEHICLES(state, payload) {
      state.vehicles = payload;
    },
    UPDATE_PENDING_ACTIVATION(state, payload) {
      state.pending_activation = payload;
    },
    CLEAR_PENDING_ACTIVATION(state) {
      state.pending_activation = null;
    },
    UPDATE_PENDING_CHECKIN(state, payload) {
      state.pending_checkin = payload;
    },
    CLEAR_PENDING_CHECKIN(state) {
      state.pending_checkin = null;
    },

    UPDATE_CHECKINS(state, payload) {
      state.checkins_dict[payload.vehicleId] = payload.checkins;
    },

    UPDATE_TIMELINES(state, payload) {
      state.timelines_dict[payload.vehicleId] = payload.events;
    }
  },

  actions: {},
})