<template>
    <v-dialog v-model="dialog" max-width="390" persistent>
        <v-card class="card-center">
            <v-card-title>
                <h2>Parked <span class="opacity-low">is now</span> <span class="primary--text">Ownli!</span></h2>
            </v-card-title>

            <v-card-text class="my-5">
                <v-row dense>
                    <v-col>
                        <v-avatar :size="92">
                            <v-img :src="`https://parked.blob.core.windows.net/web/www/logos/parked_dark.svg`" />
                        </v-avatar>
                    </v-col>
                    <v-col>
                        <v-avatar :size="92">
                            <v-img src="https://parked.blob.core.windows.net/ownli/www/logos/logo.png" />
                        </v-avatar>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <h2 class="mb-4">Own your data <br /> and earn from it with Ownli.</h2>
                Don’t worry, nothing changes with your mileage check-ins, mileage rewards, and bonuses. Same app, new
                look.
            </v-card-text>

            <v-card-actions class="py-5">
                <v-spacer/>

                <v-btn x-large text color="accent" class="text-initial px-8" href="https://www.ownli.app/">
                    Take me to Ownli app
                </v-btn>

                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "RebrandPopUp",

        data() {
            return {
                dialog: false
            }
        },

        methods: {
            displayPopup() {
                const host = document.location.host;
                const ignore = host == "ownli.app" || host == "www.ownli.app" || host == "diamond.joinparked.com";// || host == "localhost:8080";
                this.dialog = !ignore;
            }
        },

        mounted () {
            setTimeout(()=>{
                this.displayPopup();
            },3000);
        },
    }
</script>

<style lang="scss" scoped>

</style>