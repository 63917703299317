import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';

import NavbarTop from "@/components/appshell/NavbarTop.vue";
import NavbarBottom from "@/components/appshell/NavbarBottom.vue";
import NavDrawer from "@/components/appshell/NavDrawer.vue";
import ConnectionBanner from "@/components/appshell/ConnectionBanner.vue";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import OnBoarding from "@/views/OnBoarding.vue";
import Register from "@/views/Register.vue";
import Vehicles from "@/views/Vehicles.vue";
import Timeline from "@/views/Timeline.vue";
import Rewards from "@/views/Rewards.vue";
import Profile from "@/views/Profile.vue";
import Policy from "@/views/Policy.vue";
import VersionUpdate from "@/views/VersionUpdate.vue";
import DeviceInstalledStart from "@/views/DeviceInstalledStart.vue";

Vue.use(VueRouter);

const routes = [{
    path: "*",
    name: "404",
    component: () => import("@/views/404.vue"),
    meta: {
      secured: false,
      title: "Page Not Found"
    }
  },

  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/login',
    name: 'Login',
    components: {
      default: Login,
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/on-boarding',
    name: 'OnBoarding',
    components: {
      default: OnBoarding,
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/pwa-update',
    name: 'VersionUpdate',
    components: {
      default: VersionUpdate,
    },
    meta: {
      secured: false,
      title: 'Version Update.',
    },
  },

  {
    path: '/pwa-start',
    name: 'DeviceInstalledStart',
    components: {
      default: DeviceInstalledStart,
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/mobile-only',
    name: 'MobileOnly',
    components: {
      default: () => import("@/views/MobileOnly.vue"),
    },
    meta: {
      secured: false,
      title: 'Mobile Only.',
    },
  },

  {
    path: '/weak-signal',
    name: 'WeakSignal',
    components: {
      default: () => import("@/views/WeakSignal.vue"),
    },
    meta: {
      secured: false,
      title: 'Weak Signal.',
    },
  },

  {
    path: '/passwordless',
    name: 'PasswordlessLogin',
    components: {
      default: () => import("@/views/PasswordlessLogin.vue"),
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/passwordless-link',
    name: 'PasswordlessLoginByEmail',
    components: {
      default: () => import("@/views/PasswordlessLogin_email.vue"),
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/passwordless-cb',
    name: 'PasswordlessCallback',
    components: {
      default: () => import("@/views/PasswordlessCallback.vue"),
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/reset',
    name: 'Reset',
    components: {
      default: () => import("@/views/Reset.vue"),
    },
    meta: {
      secured: false,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/register',
    name: 'Register',
    components: {
      default: Register,
    },
    meta: {
      secured: true,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/vehicles',
    name: 'Vehicles',
    components: {
      default: Vehicles,
      connection_banner: ConnectionBanner,
      top_nav: NavbarTop,
      bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      saveToLast: true,
      title: 'My Vehicles',
    },
  },

  {
    path: '/vehicles/:id',
    name: 'VehicleInfo',
    components: {
      default: () => import("@/views/VehicleInfo.vue"),
      bottom_nav: NavbarBottom,
    },
    meta: {
      secured: true,
      title: 'Vehicle Details',
    },
  },

  {
    path: '/vehicles/:id/check-in',
    name: 'VehicleCheckin',
    components: {
      default: () => import("@/views/VehicleCheckin.vue"),
      bottom_nav: NavbarBottom,
    },
    meta: {
      secured: true,
      title: 'Vehicle Check-In',
    },
  },

  {
    path: '/vehicle/activate',
    name: 'VehicleActivation',
    components: {
      default: () => import("@/views/VehicleActivation.vue"),
    },
    meta: {
      secured: true,
      title: 'Add a new Vehicle',
    },
  },

  {
    path: '/vehicle/celebrate',
    name: 'Celebrate',
    components: {
      default: () => import("@/views/CelebrateCheckin.vue"),
    },
    meta: {
      secured: true,
      title: 'Know Your Worth!',
    },
  },

  {
    path: '/timeline',
    name: 'Timeline',
    components: {
      default: Timeline,
      connection_banner: ConnectionBanner,
      top_nav: NavbarTop,
      bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      saveToLast: true,
      title: 'My Timeline',
    },
  },

  {
    path: '/rewards',
    name: 'Rewards',
    components: {
      default: Rewards,
      connection_banner: ConnectionBanner,
      top_nav: NavbarTop,
      bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      saveToLast: true,
      title: 'My Rewards',
    },
  },

  {
    path: '/rewards-claim',
    name: 'RewardsClaim',
    components: {
      default: () => import("@/views/RewardsClaim.vue"),
    },
    meta: {
      secured: true,
      title: 'My Rewards',
    },
  },

  {
    path: '/profile',
    name: 'Profile',
    components: {
      default: Profile,
      connection_banner: ConnectionBanner,
      top_nav: NavbarTop,
      bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      title: 'My Profile',
    },
  },

  {
    path: '/policy',
    name: 'Policy',
    components: {
      default: Policy,
      connection_banner: ConnectionBanner,
      top_nav: NavbarTop,
      bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      title: 'My Policy',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeResolve((to, from, next) => {
  let title = to.meta && to.meta.title ? to.meta.title : to.name;
  document.title = title;

  if (to.meta && to.meta.secured) {
    // save requested route
    if (to.meta.saveToLast) {
      localStorage.setItem("route", to.fullPath);
    }
    // get user from vuex
    const user = store.getters["user"];
    if (user && user.token) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router