<template>

    <svg viewBox="0 0 500 415" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.8738 390.722C7.96966 383.161 107.085 367.663 116.072 366.834L246.754 354.793H461.692C468.808 354.793 475.327 358.766 478.573 365.084C484.805 377.204 476.559 391.732 462.938 392.626L286.995 404.177C285.39 404.283 283.775 404.273 282.17 404.141L116.072 392.626C110.378 394.531 12.8738 396.723 12.8738 390.722Z"
            fill="#6E6E6E" fill-opacity="0.15" />
        <path
            d="M484.237 345.286C484.198 360.284 474.351 371.95 462.759 371.919C451.168 371.891 441.379 360.176 441.415 345.176C441.454 330.176 451.304 318.51 462.894 318.541C474.486 318.571 484.276 330.286 484.237 345.286Z"
            fill="white" stroke="#212121" />
        <path
            d="M335.122 362.443L276 356.918C266.121 356.891 245.158 355.096 240.343 348.114C234.323 339.391 236.919 311.992 236.928 308.768C236.934 306.326 238.289 304.309 239.27 303.427C239.4 303.311 239.53 303.197 239.649 303.071L256.077 285.725L276.067 264.912C276.266 264.706 276.409 264.491 276.654 264.345C282.938 260.647 378.19 258.599 414.726 260.943C415.74 261.008 416.624 261.527 417.225 262.375C423.966 271.894 434.785 288.073 437.412 292.456C439.52 295.973 440.896 297.692 441.681 298.158C441.98 298.335 442.35 298.274 442.695 298.276L470.407 298.348C484.399 298.386 495.808 307.188 495.446 321.637C495.184 332.181 493.958 343.85 492.107 351.891C491.31 355.345 488.272 357.579 484.835 357.733L377.542 362.555L335.122 362.443Z"
            fill="white" stroke="#212121" />
        <path
            d="M401.321 322.167C401.308 326.83 398.282 330.128 395.087 330.122C391.889 330.113 388.881 326.8 388.892 322.135C388.902 317.471 391.93 314.173 395.126 314.182C398.323 314.19 401.332 317.504 401.321 322.167Z"
            fill="white" stroke="#212121" />
        <path
            d="M490.073 321.277C490.06 325.94 487.034 329.239 483.839 329.232C480.641 329.224 477.631 325.91 477.644 321.245C477.654 316.582 480.682 313.283 483.878 313.292C487.075 313.301 490.084 316.614 490.073 321.277Z"
            fill="white" stroke="#212121" />
        <path
            d="M329.989 267.38C329.922 267.109 330.128 266.846 330.407 266.846L412.051 267.055C412.193 267.055 412.323 267.124 412.403 267.238L433.667 297.81C433.866 298.095 433.663 298.489 433.315 298.487L337.941 298.243C337.744 298.243 337.572 298.11 337.525 297.918L329.989 267.38Z"
            fill="#FAFAFA" stroke="#212121" />
        <path
            d="M280.163 270.156C280.239 270.078 280.342 270.031 280.451 270.026L322.842 267.754C323.069 267.741 323.264 267.907 323.292 268.134L326.837 298.186C326.867 298.443 326.668 298.667 326.41 298.667L253.998 298.484C253.621 298.481 253.427 298.026 253.689 297.753L280.163 270.156L279.236 269.259L280.163 270.156Z"
            fill="#FAFAFA" />
        <path
            d="M280.163 270.156C280.239 270.078 280.342 270.031 280.451 270.026L322.842 267.754C323.069 267.741 323.264 267.907 323.292 268.134L326.837 298.186C326.867 298.443 326.668 298.667 326.41 298.667L253.998 298.484C253.621 298.481 253.427 298.026 253.689 297.753L280.163 270.156ZM280.163 270.156L279.236 269.259L280.163 270.156Z"
            stroke="#212121" />
        <path
            d="M410.487 340.009C410.485 339.776 410.665 339.585 410.897 339.574L472.847 336.862C473.09 336.851 473.294 337.045 473.296 337.289L473.386 350.125C473.388 350.355 473.206 350.547 472.976 350.558L411.023 353.119C410.781 353.13 410.577 352.936 410.577 352.692L410.487 340.009Z"
            :fill="color" stroke="#212121" />
        <path
            d="M300.051 308.169L291.8 308.148C290.852 308.146 290.082 308.916 290.079 309.868L290.076 311.164C290.073 312.116 290.84 312.89 291.788 312.893L300.039 312.914C300.988 312.916 301.758 312.146 301.761 311.194L301.764 309.898C301.766 308.945 301 308.171 300.051 308.169Z"
            fill="#212121" />
        <path d="M302.425 268.541L297.202 298.951" stroke="#212121" stroke-width="2" stroke-linejoin="round" />
        <path
            d="M280.3 349.333C280.285 355.527 278.388 361.082 275.393 365.055C272.399 369.027 268.369 371.361 264.028 371.351C259.689 371.34 255.669 368.986 252.695 364.997C249.721 361.009 247.852 355.445 247.867 349.251C247.882 343.057 249.781 337.504 252.775 333.528C255.768 329.557 259.8 327.223 264.139 327.233C268.481 327.246 272.5 329.598 275.472 333.587C278.446 337.575 280.317 343.139 280.3 349.333Z"
            fill="white" stroke="#212121" stroke-width="2" />
        <path
            d="M275.337 349.275C275.326 353.606 273.992 357.473 271.904 360.229C269.818 362.984 267.035 364.568 264.067 364.56C261.101 364.553 258.326 362.954 256.253 360.19C254.178 357.424 252.863 353.548 252.874 349.219C252.886 344.888 254.219 341.02 256.307 338.265C258.395 335.51 261.178 333.925 264.144 333.934C267.112 333.94 269.886 335.54 271.959 338.304C274.035 341.069 275.35 344.946 275.337 349.275Z"
            fill="white" stroke="#212121" stroke-width="2" />
        <path
            d="M373.758 350.586C373.741 358.056 371.432 364.765 367.775 369.574C364.121 374.384 359.175 377.238 353.821 377.226C348.466 377.21 343.533 374.332 339.902 369.503C336.271 364.676 333.996 357.954 334.016 350.484C334.035 343.014 336.342 336.305 339.999 331.496C343.653 326.686 348.602 323.832 353.956 323.847C359.31 323.86 364.241 326.74 367.872 331.567C371.503 336.394 373.778 343.116 373.758 350.586Z"
            fill="white" stroke="#212121" />
        <path
            d="M367.605 350.638C367.592 355.893 365.974 360.6 363.431 363.958C360.89 367.319 357.48 369.277 353.821 369.266C350.165 369.258 346.765 367.283 344.24 363.911C341.714 360.537 340.12 355.824 340.132 350.569C340.147 345.313 341.763 340.609 344.306 337.248C346.849 333.889 350.259 331.931 353.916 331.94C357.572 331.951 360.974 333.925 363.499 337.297C366.025 340.669 367.617 345.384 367.605 350.638Z"
            fill="white" stroke="#212121" />
        <path
            d="M138.056 22.0372C139.312 17.7361 143.795 15.2722 148.074 16.525L369.593 81.383C373.882 82.6391 376.347 87.1567 375.089 91.4692L286.962 393.295C285.704 397.602 281.213 400.064 276.931 398.802L55.5252 333.558C51.2423 332.297 48.7856 327.784 50.043 323.476L138.056 22.0372Z"
            fill="#FAFAFA" stroke="#212121" />
        <path
            d="M184.031 100.604L157.902 92.9047C156.537 92.5025 155.107 93.2879 154.706 94.6589L147.042 120.909C146.642 122.28 147.424 123.717 148.788 124.119L174.917 131.819C176.282 132.221 177.713 131.436 178.113 130.065L185.777 103.815C186.178 102.444 185.396 101.007 184.031 100.604Z"
            fill="#FEFEFE" />
        <path
            d="M183.669 101.846L157.54 94.1458C156.858 93.9447 156.142 94.3374 155.942 95.023L148.278 121.273C148.078 121.958 148.469 122.677 149.151 122.878L175.28 130.578C175.962 130.779 176.678 130.386 176.878 129.701L184.542 103.451C184.742 102.765 184.351 102.047 183.669 101.846Z"
            stroke="#FFD51C" stroke-opacity="0.8" stroke-width="2" />
        <path
            d="M162.888 173.023L136.759 165.323C135.394 164.921 133.964 165.706 133.563 167.077L125.826 193.577C125.426 194.948 126.208 196.385 127.572 196.787L153.701 204.487C155.066 204.889 156.497 204.104 156.897 202.733L164.634 176.233C165.035 174.862 164.253 173.425 162.888 173.023Z"
            fill="#FEFEFE" />
        <path
            d="M162.526 174.263L136.397 166.564C135.714 166.363 134.999 166.755 134.799 167.441L127.062 193.94C126.861 194.626 127.252 195.345 127.935 195.546L154.064 203.245C154.746 203.446 155.461 203.054 155.662 202.368L163.399 175.869C163.599 175.183 163.208 174.465 162.526 174.263Z"
            stroke="#FFD51C" stroke-opacity="0.8" stroke-width="2" />
        <path
            d="M140.503 249.686L114.374 241.986C113.01 241.584 111.579 242.37 111.179 243.741L103.514 269.99C103.114 271.361 103.896 272.799 105.26 273.201L131.389 280.901C132.754 281.303 134.185 280.517 134.585 279.146L142.249 252.897C142.65 251.526 141.868 250.088 140.503 249.686Z"
            fill="#FEFEFE" />
        <path
            d="M140.141 250.926L114.012 243.226C113.33 243.025 112.615 243.418 112.414 244.104L104.75 270.353C104.55 271.039 104.941 271.758 105.623 271.959L131.752 279.658C132.435 279.859 133.15 279.467 133.35 278.781L141.014 252.531C141.215 251.846 140.824 251.127 140.141 250.926Z"
            stroke="#FFD51C" stroke-opacity="0.8" stroke-width="2" />
        <path
            d="M318.441 142.653L203.14 108.676C200.865 108.006 198.481 109.315 197.813 111.6L197.459 112.814C196.792 115.099 198.095 117.495 200.369 118.165L315.671 152.142C317.945 152.813 320.33 151.504 320.997 149.219L321.352 148.004C322.019 145.719 320.716 143.323 318.441 142.653Z"
            fill="#212121" fill-opacity="0.2" />
        <path
            d="M297.224 215.321L181.923 181.343C179.648 180.673 177.263 181.982 176.596 184.267L176.242 185.482C175.574 187.767 176.877 190.162 179.152 190.833L294.453 224.81C296.728 225.48 299.113 224.171 299.78 221.886L300.134 220.672C300.802 218.387 299.499 215.991 297.224 215.321Z"
            fill="#212121" fill-opacity="0.2" />
        <path
            d="M274.913 291.734L159.611 257.757C157.337 257.087 154.952 258.396 154.285 260.681L153.931 261.895C153.263 264.18 154.566 266.576 156.841 267.246L272.142 301.223C274.417 301.894 276.802 300.585 277.469 298.3L277.823 297.085C278.491 294.8 277.188 292.404 274.913 291.734Z"
            fill="#212121" fill-opacity="0.2" />
        <path
            d="M256.839 143.756L197.963 126.406C195.688 125.735 193.303 127.045 192.636 129.33L192.282 130.544C191.614 132.829 192.917 135.225 195.192 135.895L254.069 153.245C256.343 153.915 258.728 152.606 259.395 150.321L259.75 149.107C260.417 146.822 259.114 144.426 256.839 143.756Z"
            fill="#212121" fill-opacity="0.2" />
        <path
            d="M261.795 223.865L176.819 198.824C174.544 198.154 172.159 199.463 171.492 201.748L171.138 202.962C170.47 205.247 171.773 207.643 174.048 208.313L259.024 233.354C261.299 234.024 263.683 232.715 264.351 230.43L264.705 229.216C265.372 226.931 264.069 224.535 261.795 223.865Z"
            fill="#212121" fill-opacity="0.2" />
        <path
            d="M213.311 292.836L154.435 275.487C152.16 274.816 149.776 276.125 149.108 278.41L148.754 279.625C148.087 281.91 149.39 284.306 151.664 284.976L210.541 302.326C212.815 302.996 215.2 301.687 215.867 299.402L216.222 298.187C216.889 295.902 215.586 293.507 213.311 292.836Z"
            fill="#212121" fill-opacity="0.2" />
        <g filter="url(#filter0_i_294_7435)">
            <path
                d="M69.2022 390.961C75.1853 392.572 81.3353 389.005 82.9385 382.994C84.5416 376.983 80.991 370.805 75.0078 369.194C69.0246 367.583 62.8747 371.151 61.2715 377.161C59.6683 383.172 63.219 389.351 69.2022 390.961Z"
                :fill="color" />
        </g>
        <path
            d="M97.7493 390.816C101.28 384.366 103.314 376.947 103.314 369.046C103.314 344.267 83.4637 324.164 58.9587 324.164C34.454 324.164 14.604 344.267 14.604 369.046C14.604 376.947 16.6371 384.366 20.1684 390.816H97.7493Z"
            fill="white" stroke="#212121" stroke-miterlimit="10" />
        <path
            d="M41.1425 350.286L40.5541 350.986C39.4839 352.222 39.591 354.104 40.8215 355.177L55.2676 367.916C55.2676 368.024 55.2676 368.078 55.2676 368.186C55.2676 371.249 57.7288 373.722 60.7786 373.722C63.8283 373.722 66.2895 371.249 66.2895 368.186C66.2895 365.122 63.8283 362.65 60.7786 362.65C60.4575 362.65 60.1365 362.704 59.8689 362.757L45.423 350.018C44.1389 348.943 42.2661 349.051 41.1425 350.286Z"
            fill="#212121" />
        <path
            d="M24.8694 371.627H16.8439C15.3458 371.627 14.1152 370.391 14.1152 368.833C14.1152 367.326 15.3458 366.037 16.8439 366.037H24.8694C26.3677 366.037 27.5982 367.272 27.5982 368.833C27.5982 370.391 26.3677 371.627 24.8694 371.627Z"
            fill="#212121" />
        <path
            d="M27.557 354.729L21.0295 350.051C19.7989 349.191 19.4779 347.419 20.3874 346.181C21.2435 344.946 22.9555 344.623 24.1862 345.483L30.7137 350.159C31.9444 351.019 32.2654 352.793 31.3557 354.029C30.4997 355.32 28.7875 355.587 27.557 354.729Z"
            fill="#212121" />
        <path
            d="M39.4724 340.216L35.1387 333.442C34.3362 332.153 34.6572 330.433 35.9411 329.626C37.2252 328.82 38.9375 329.143 39.74 330.433L44.0737 337.206C44.8763 338.495 44.5553 340.216 43.2711 341.022C41.9872 341.828 40.275 341.451 39.4724 340.216Z"
            fill="#212121" />
        <path
            d="M56.7129 335.044L56.3919 326.927C56.3385 325.423 57.5155 324.08 59.0136 324.026C60.5119 323.972 61.7958 325.153 61.8494 326.66L62.1704 334.721C62.2239 336.228 61.0468 337.571 59.5488 337.625C58.0505 337.786 56.7666 336.603 56.7129 335.044Z"
            fill="#212121" />
        <path
            d="M74.3968 338.129L78.4096 331.142C79.1586 329.799 80.8708 329.368 82.1549 330.12C83.4388 330.872 83.9203 332.593 83.1714 333.938L79.1586 340.925C78.4096 342.268 76.6974 342.699 75.4133 341.947C74.0757 341.141 73.5942 339.42 74.3968 338.129Z"
            fill="#212121" />
        <path
            d="M87.1538 349.965L94.1092 345.933C95.3933 345.181 97.1055 345.612 97.8545 346.955C98.6036 348.298 98.1755 349.965 96.838 350.771L89.8824 354.802C88.5985 355.555 86.8862 355.126 86.1371 353.781C85.3881 352.438 85.816 350.717 87.1538 349.965Z"
            fill="#212121" />
        <path
            d="M93.2405 366.789H101.266C102.764 366.789 103.995 368.027 103.995 369.585C103.995 371.09 102.764 372.379 101.266 372.379H93.2405C91.7422 372.379 90.5117 371.144 90.5117 369.585C90.5117 368.027 91.7422 366.789 93.2405 366.789Z"
            fill="#212121" />
        <path
            d="M77.8409 377.917H40.1879C39.2398 377.917 38.4712 378.69 38.4712 379.642V383.068C38.4712 384.021 39.2398 384.793 40.1879 384.793H77.8409C78.789 384.793 79.5577 384.021 79.5577 383.068V379.642C79.5577 378.69 78.789 377.917 77.8409 377.917Z"
            :fill="color" stroke="#212121" />
        <defs>
            <filter id="filter0_i_294_7435" x="60.8867" y="368.807" width="26.4365" height="26.541"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="6" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_294_7435" />
            </filter>
        </defs>
    </svg>

</template>

<script>
    export default {
        name: "PolicySVG",
        props: {
            color: {
                type: String,
                default: "#FFD51C"
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>