/* eslint-disable no-console */
 import store from "./store";
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.info(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.info('Service worker has been registered.');
    },
    cached() {
      console.info('Content has been cached for offline use.');
    },
    updatefound() {
      // store.commit("ADD_NOTIFICATION", {
      //   text: "Downloading new Version",
      //   timeout: 3000
      // });
      store.commit("UPDATE_FOUND");
      console.info('New content is downloading.');
    },
    updated(registration) {
      console.info('New content is available; please refresh.');
      registration.waiting.postMessage({action: "skipWaiting"});
      // store.commit("ADD_NOTIFICATION", {
      //    text: "Version downloaded",
      //    timeout: 10000,
      //    btn_click: ()=>{window.location.reload();},
      //    btn_label: "upgrade now",
      //    btn_icon:"mdi-sync"
      //  });
      store.commit("UPDATED_PWA");
    },
    offline() {
      console.info('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}