<template>
    <v-card tile flat full-width fill-height class="card-center" color="primary">
        <v-spacer />
        <v-card-text>
            <BrandLogoLoader ripple circle infinite color="accent" />
        </v-card-text>
        <v-spacer />
        <v-card-text class="shrink">
            <h3 class="mb-5" :class="{'white--text':isPrimaryColorDark}">Upgrading your experience</h3>
            <CountDownTimer :time="time" :interval="100" @ended="_redirect" color="accent" height="52" rounded class="timer" />
        </v-card-text>
    </v-card>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import CountDownTimer from "@/components/global/CountDownTimer.vue";
    export default {
        name: "VersionUpdate",

        components: {
            CountDownTimer,
        },

        data() {
            return {
                time: this._getRandomTime(8, 10) * 1000
            }
        },

        computed: {
            ...mapGetters(["isPrimaryColorDark", "isMobileDevice"]),
            countDownColor(){
                return this.isPrimaryColorDark ? "grey" : "grey darken-4";
            }
        },

        methods: {
            _getRandomTime(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min) + min);
            },
            _redirect() {
                document.location.href = "/";
                // this.$router.push("/");
            }
        },
    }
</script>

<style scoped>
    .timer {
        font-size: 2rem;
        line-height: 2.8rem;
    }

    h3 {
        /* font-family: 'Lobster', cursive; */
        font-weight: normal;
        letter-spacing: 3px;
    }
</style>