<template>
    <v-timeline dense reverse :class="{'loaded':loaded,'empty':discounts.length == 0 }" v-if="!vehicle || vehicle.isActiveOnPolicy">

        <v-timeline-item fill-dot small v-if="milageDiscounts && activated" key="future-low-mileage">
            <TimelineCard>
                <template v-slot:avatar>
                    <RewardSVG color="grey darken-4" />
                </template>
                <template v-slot:title>Bonus</template>
                <template v-slot:subtitle>Depending on <router-link to="/rewards">low-mileage tier</router-link>
                </template>
            </TimelineCard>
        </v-timeline-item>

        <v-timeline-item fill-dot small v-if="checkInDiscount" key="future-checkin">
            <TimelineCard>
                <template v-slot:avatar>
                    <RewardSVG color="grey darken-4" />
                </template>
                <template v-slot:title>Reward</template>
                <template v-slot:subtitle>
                    {{ (activated ? checkInDiscount.amountPerVehicle : activationDiscount.amountPerVehicle) | currency(currency,0)}}
                    Check-in reward
                </template>
            </TimelineCard>
        </v-timeline-item>

    </v-timeline>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import api from "@/services/api-service";
    import TimelineCard from "@/components/timeline/TimelineCard"
    import RewardSVG from "@/components/timeline/RewardSVG"

    export default {
        name: "FutureRewards",

        components: {
            TimelineCard,
            RewardSVG
        },

        props: {
            vehicle: {
                type: Object,
                required: false
            },
            policy: {
                type: Object,
                required: true
            },
        },

        computed: {
            ...mapGetters(["discounts"]),

            currency() {
                return this.policy.discountsProviderCurrency;
            },
            activated() {
                const active_policy = this.policy.numberOfVehiclesActivated == this.policy.numberOfVehiclesOnPolicy;
                const active_vehicle = this.vehicle && this.vehicle.isActiveOnPolicy;
                return active_vehicle || active_policy;
            },
            milageDiscounts() {
                let min_milage = 0;
                let milageDiscounts = this.discounts && this.discounts.length > 0 && this.discounts.filter(d => d
                    .type ==
                    "Mileage");

                for (let index = 0; index < milageDiscounts.length; index++) {
                    const discount = milageDiscounts[index];
                    discount.maxMilage = discount.max;
                    discount.minMilage = min_milage;
                    min_milage = discount.maxMilage > 0 ? discount.maxMilage + 1 : 0;
                }

                return milageDiscounts;
            },
            activationDiscount() {
                return this.discounts.find(d => d.type == "Enrollment");
            },
            checkInDiscount() {
                return this.discounts.find(d => d.type == "CheckIn");
            },
        },

        data() {
            return {
                loaded: false,
                loading: false,
            }
        },


        methods: {
            async loadDiscounts() {
                try {
                    this.loading = true;
                    // Fetch customer by login e-mail:
                    const uri = `/policies/${this.policy.id}/discounts`;
                    const response = await api.get(uri, {
                        requestId: uri
                    });

                    if (response.status == 200 && response.data) {
                        this.$store.commit("UPDATE_DISCOUNTS", response.data);
                        this.loaded = true;
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "loadDiscounts"
                    }, error);
                } finally {
                    this.loading = false;
                }
            },
        },

        mounted() {
            this.loadDiscounts();
        }
    }
</script>

<style scoped>
    /* .v-timeline {
        transform: translateX(30%);
        opacity: 0;
    }

    .v-timeline.loaded {
        opacity: 1;
        transform: translateX(0);
        transition: 350ms ease-in;
    }

    .v-timeline.empty {
        opacity: 0;
        min-height: 0;
        transform: translateX(20%);
        transition: 350ms ease-in;
    } */

    .theme--light>>>.v-timeline-item__body {
        border: solid 2px #ddd !important;
    }

    .v-timeline-item >>> .v-list-item__title, .v-timeline-item >>> .v-list-item__avatar{
        opacity: 0.6;
    }


    .theme--light.v-timeline::before {
        background: transparent !important;
    }
</style>