import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"pr-0",staticStyle:{"max-width":"80px"}},[_c(VSelect,_vm._b({attrs:{"label":"","hint":"","item-text":"name","return-object":"","clearable":false,"disabled":!_vm.allowCountryChange,"items":_vm.countries},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VAvatar,{attrs:{"size":24}},[_c(VImg,{attrs:{"src":item.flag}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VAvatar,{staticClass:"mr-2",attrs:{"size":24}},[_c(VImg,{attrs:{"src":item.flag}})],1),_vm._v(" +"+_vm._s(item.dial)+" ")]}}]),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},'v-select',_vm.$attrs,false))],1),_c(VCol,[_c(VTextField,_vm._b({attrs:{"type":"tel","rules":_vm.phoneRules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.enter.apply(null, arguments)}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},'v-text-field',_vm.$attrs,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }