<template>

<svg width="208" height="165" viewBox="0 0 208 165" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1786_48884)">
<path d="M150.162 22.5496L149.903 22.8091L146.212 26.5089L145.505 27.2178L144.797 26.5098L141.088 22.801C136.738 18.4516 130.839 16.0084 124.687 16.0088C118.536 16.0092 112.637 18.4532 108.288 22.8031C103.938 27.153 101.495 33.0526 101.495 39.2039C101.496 45.3552 103.94 51.2544 108.29 55.6037L108.29 55.6038L143.825 91.1392L150.162 22.5496ZM150.162 22.5496C152.263 20.5123 154.73 18.8898 157.436 17.7687C160.254 16.601 163.274 16 166.324 16C169.374 16 172.394 16.601 175.212 17.7687C178.029 18.9363 180.589 20.6478 182.745 22.8052C187.091 27.1543 189.533 33.0509 189.533 39.1993C189.534 45.3474 187.094 51.2443 182.75 55.5945L150.162 22.5496ZM182.749 55.5952L147.183 91.1392C147.183 91.1393 147.183 91.1394 147.183 91.1394C146.737 91.5842 146.134 91.834 145.504 91.834C144.875 91.834 144.271 91.5843 143.826 91.1396L182.749 55.5952Z" fill="#F5F5F5" stroke="#979797" stroke-width="2"/>
</g>
<path d="M135.278 38.0342L135.279 38.0339L145.654 58.8299L146.39 60.3053L147.358 58.9707L156.993 45.6891C157.169 45.4472 157.392 45.2442 157.649 45.0925C157.905 44.9407 158.19 44.8435 158.486 44.8067C158.781 44.7699 159.081 44.7943 159.367 44.8784C159.653 44.9626 159.919 45.1046 160.148 45.296L160.148 45.2961L168.798 52.5198L169.076 52.7522H169.439H187.356C187.933 52.7522 188.486 52.9819 188.895 53.3914C189.303 53.801 189.533 54.357 189.533 54.9372C189.533 55.5175 189.303 56.0735 188.895 56.4831C188.486 56.8926 187.933 57.1223 187.356 57.1223H168.289C167.78 57.1221 167.287 56.9431 166.896 56.6161L166.896 56.6157L159.968 50.8317L159.146 50.1456L158.518 51.0121L147.805 65.7777C147.585 66.0811 147.291 66.3222 146.951 66.4782C146.611 66.6342 146.237 66.6998 145.865 66.669C145.493 66.6381 145.135 66.5117 144.825 66.3017C144.516 66.0917 144.265 65.8052 144.097 65.4696L133.973 45.1777L133.158 43.5439L132.22 45.1106L125.664 56.0634C125.471 56.3867 125.197 56.6539 124.87 56.8394C124.543 57.0248 124.174 57.1222 123.799 57.1223C123.799 57.1223 123.798 57.1223 123.798 57.1223H103.673C103.096 57.1223 102.543 56.8926 102.134 56.4831L101.431 57.1844L102.134 56.4831C101.725 56.0735 101.495 55.5175 101.495 54.9372C101.495 54.357 101.725 53.801 102.134 53.3914C102.543 52.9819 103.096 52.7522 103.673 52.7522H122H122.567L122.858 52.2657L131.463 37.8856C131.463 37.8855 131.464 37.8853 131.464 37.8852C131.665 37.5496 131.952 37.2746 132.295 37.0887C132.638 36.9027 133.025 36.8127 133.414 36.8278C133.804 36.843 134.182 36.9628 134.51 37.1749L135.05 36.3401L134.51 37.1749C134.838 37.387 135.103 37.6837 135.278 38.0342Z" fill="#F5F5F5" stroke="#979797" stroke-width="2"/>
<path d="M97.3759 50.9795L97.3742 50.9795C97.1487 50.9808 96.9253 50.9377 96.7167 50.8526C96.5087 50.7678 96.3196 50.6431 96.1601 50.4856C96.1596 50.4851 96.1591 50.4846 96.1586 50.4841L57.4191 11.7894L56.7124 11.0835L56.0057 11.7894L17.2937 50.4566C16.9703 50.722 16.5599 50.8603 16.1402 50.8441C15.709 50.8275 15.3003 50.6491 14.9957 50.345C14.6911 50.041 14.513 49.6339 14.4964 49.205C14.4802 48.7876 14.6183 48.3789 14.884 48.0564L55.5189 7.49679C55.5192 7.49648 55.5196 7.49617 55.5199 7.49586C55.8402 7.17846 56.2738 7 56.726 7C57.1782 7 57.6117 7.17846 57.9321 7.49587C57.9324 7.49617 57.9327 7.49648 57.933 7.49679L98.5914 48.0797C98.5924 48.0808 98.5935 48.0818 98.5945 48.0829C98.8281 48.322 98.9862 48.6241 99.0492 48.9517C99.1126 49.2807 99.0774 49.621 98.948 49.9303C98.8186 50.2395 98.6006 50.5042 98.321 50.6909C98.0414 50.8777 97.7126 50.9781 97.3759 50.9795Z" fill="#F5F5F5" stroke="#979797" stroke-width="2"/>
<path d="M48.6514 64.4732H47.6514V65.4732V91.5847H29.6674C28.494 91.5847 27.3687 91.1187 26.5391 90.2893C25.7094 89.4599 25.2434 88.3351 25.2434 87.1624V54.581L56.7894 22.9397L88.3313 54.3929V87.1624C88.3313 88.3351 87.8653 89.4599 87.0357 90.2893C86.206 91.1187 85.0807 91.5847 83.9073 91.5847H65.9233V65.4732V64.4732H64.9233H48.6514Z" fill="#F5F5F5" stroke="#979797" stroke-width="2"/>
<path d="M37.233 155.315C35.1794 152.188 37.1993 148 40.9248 147.661L95.7667 142.665L185.979 142.65C188.899 142.649 191.576 144.273 192.925 146.863C195.539 151.88 192.133 157.929 186.488 158.296L115.531 162.906C112.928 163.075 110.316 163.056 107.716 162.849L40.9809 157.534C39.456 157.412 38.0727 156.593 37.233 155.315Z" fill="#6E6E6E" fill-opacity="0.15"/>
<path d="M198.566 134.676C198.54 144.879 191.814 152.77 183.947 152.75C176.08 152.73 169.393 144.805 169.419 134.602C169.445 124.398 176.171 116.508 184.039 116.528C191.906 116.548 198.592 124.472 198.566 134.676Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M96.4283 145.634L56.2569 142C49.5443 141.983 35.3011 140.801 32.0296 136.21C27.9402 130.471 29.704 112.451 29.7094 110.331C29.7121 109.229 30.1465 108.258 30.6343 107.57C31.0897 106.928 31.713 106.425 32.2638 105.862L42.721 95.1736L55.124 82.6726C56.0422 81.7471 57.027 80.8928 58.307 80.6454C68.4369 78.6873 118.198 77.6171 144.206 78.5712C148.892 78.7432 153.182 81.1112 155.893 84.9381C160.016 90.7571 164.576 97.4139 165.931 99.6012C166.513 100.541 167.013 101.286 167.437 101.859C168.385 103.142 169.977 103.43 171.573 103.434L188.349 103.477C197.856 103.501 205.609 109.291 205.363 118.794C205.189 125.528 204.399 132.959 203.202 138.226C202.619 140.789 200.299 142.443 197.673 142.558L125.251 145.708L96.4283 145.634Z" :fill="primary" stroke="#212121" stroke-width="2"/>
<path d="M141.676 118.887C141.668 122.031 139.623 124.208 137.516 124.203C135.408 124.198 133.374 122.01 133.382 118.866C133.39 115.722 135.435 113.545 137.543 113.55C139.65 113.555 141.684 115.743 141.676 118.887Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M202.569 118.28C202.561 121.424 200.516 123.601 198.408 123.596C196.301 123.59 194.267 121.403 194.275 118.259C194.283 115.114 196.328 112.937 198.435 112.943C200.543 112.948 202.577 115.135 202.569 118.28Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M95.0683 89.9293C93.9719 85.5074 97.3246 81.2332 101.88 81.2447L145.61 81.3556C147.89 81.3614 150.024 82.4771 151.33 84.3459L156.368 91.5557C159.615 96.2023 156.281 102.58 150.613 102.565L103.63 102.446C100.42 102.438 97.6264 100.247 96.8538 97.1308L95.0683 89.9293Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M56.884 85.3818C58.1138 84.1059 59.7817 83.3438 61.5512 83.2494L81.568 82.1816C85.2531 81.985 88.4578 84.683 88.8922 88.3477L89.6662 94.877C90.1606 99.0476 86.8968 102.712 82.6971 102.701L56.706 102.635C50.5402 102.619 47.4048 95.2169 51.6836 90.7774L56.884 85.3818Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M148.227 135.25C148.21 132.822 150.116 130.816 152.542 130.71L186.339 129.237C188.916 129.124 191.072 131.173 191.091 133.753C191.108 136.207 189.177 138.233 186.725 138.334L152.924 139.725C150.374 139.83 148.245 137.802 148.227 135.25Z" :fill="secondary" stroke="#212121" stroke-width="2"/>
<rect x="62.353" y="109.309" width="8.017" height="3.24057" rx="1.62029" transform="rotate(0.145262 62.353 109.309)" fill="#212121"/>
<path d="M70.8201 82.2624L67.2361 103.031" stroke="#212121" stroke-width="2" stroke-linejoin="round"/>
<path d="M55.524 137.44C55.5134 141.647 54.2182 145.415 52.1805 148.107C50.143 150.798 47.4089 152.367 44.476 152.36C41.543 152.352 38.817 150.769 36.7932 148.068C34.7691 145.366 33.4931 141.591 33.5038 137.384C33.5144 133.177 34.8095 129.409 36.8473 126.717C38.8848 124.026 41.6188 122.457 44.5518 122.464C47.4847 122.471 50.2108 124.055 52.2346 126.756C54.2587 129.458 55.5347 133.232 55.524 137.44Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M52.1185 137.401C52.1111 140.335 51.2025 142.951 49.7867 144.811C48.3713 146.67 46.4935 147.728 44.5027 147.723C42.5118 147.718 40.6394 146.65 39.2335 144.784C37.8271 142.917 36.9318 140.296 36.9393 137.362C36.9467 134.428 37.8553 131.811 39.2711 129.952C40.6864 128.093 42.5643 127.034 44.5551 127.039C46.5459 127.044 48.4184 128.112 49.8243 129.978C51.2307 131.845 52.1259 134.466 52.1185 137.401Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M122.764 138.295C122.752 143.374 121.175 147.931 118.683 151.194C116.192 154.456 112.83 156.381 109.201 156.372C105.572 156.363 102.219 154.421 99.7446 151.146C97.2695 147.87 95.716 143.306 95.7289 138.227C95.7418 133.148 97.3184 128.591 99.8101 125.328C102.301 122.066 105.663 120.141 109.293 120.15C112.922 120.159 116.274 122.102 118.749 125.376C121.224 128.652 122.777 133.217 122.764 138.295Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M118.543 138.331C118.534 141.898 117.431 145.086 115.703 147.359C113.976 149.631 111.668 150.944 109.203 150.937C106.739 150.931 104.438 149.607 102.722 147.326C101.005 145.044 99.9188 141.851 99.9278 138.284C99.9369 134.718 101.039 131.53 102.767 129.257C104.495 126.985 106.803 125.672 109.267 125.678C111.732 125.685 114.033 127.009 115.749 129.29C117.466 131.572 118.552 134.765 118.543 138.331Z" fill="white" stroke="#212121" stroke-width="2"/>
<path d="M73.6018 159.663C76.5747 158.801 75.6582 160.719 75.6582 159.663C75.6582 156.35 59.0162 145.512 38.386 145.512C17.7559 145.512 4.62479e-08 156.35 4.62479e-08 159.663C-0.000254982 158.621 1.0542 158.801 4.0271 159.663H73.6018Z" fill="#6E6E6E" fill-opacity="0.15"/>
<path d="M64.3073 157.839C66.8789 153.164 68.3595 147.787 68.3595 142.059C68.3595 124.097 53.9042 109.525 36.0592 109.525C18.2143 109.525 3.75903 124.097 3.75903 142.059C3.75903 147.787 5.23962 153.164 7.81117 157.839H64.3073Z" fill="white" stroke="#212121" stroke-width="2" stroke-miterlimit="10"/>
<path d="M23.0852 128.462L22.6566 128.969C21.8774 129.865 21.9553 131.229 22.8514 132.008L33.3714 141.242C33.3714 141.32 33.3714 141.359 33.3714 141.437C33.3714 143.658 35.1637 145.45 37.3846 145.45C39.6055 145.45 41.3977 143.658 41.3977 141.437C41.3977 139.216 39.6055 137.424 37.3846 137.424C37.1508 137.424 36.917 137.463 36.7222 137.502L26.2022 128.267C25.2671 127.488 23.9034 127.566 23.0852 128.462Z" fill="#212121"/>
<path d="M11.2346 143.931H5.39018C4.29922 143.931 3.40308 143.035 3.40308 141.905C3.40308 140.814 4.29922 139.879 5.39018 139.879H11.2346C12.3256 139.879 13.2217 140.775 13.2217 141.905C13.2217 143.035 12.3256 143.931 11.2346 143.931Z" fill="#212121"/>
<path d="M13.1918 131.681L8.4383 128.291C7.54216 127.667 7.30838 126.382 7.97075 125.485C8.59415 124.589 9.84097 124.356 10.7371 124.979L15.4906 128.369C16.3867 128.992 16.6205 130.278 15.9581 131.174C15.3347 132.109 14.0879 132.304 13.1918 131.681Z" fill="#212121"/>
<path d="M21.8687 121.16L18.7127 116.251C18.1282 115.316 18.362 114.069 19.2971 113.485C20.2322 112.9 21.479 113.134 22.0635 114.069L25.2195 118.978C25.8039 119.914 25.5701 121.16 24.635 121.745C23.6999 122.329 22.4531 122.056 21.8687 121.16Z" fill="#212121"/>
<path d="M34.4235 117.412L34.1898 111.529C34.1508 110.438 35.008 109.464 36.0989 109.425C37.1899 109.386 38.125 110.243 38.164 111.334L38.3977 117.179C38.4367 118.27 37.5795 119.244 36.4886 119.283C35.3976 119.4 34.4625 118.542 34.4235 117.412Z" fill="#212121"/>
<path d="M47.3019 119.649L50.2241 114.583C50.7696 113.609 52.0164 113.298 52.9515 113.843C53.8866 114.389 54.2373 115.635 53.6918 116.61L50.7696 121.675C50.2241 122.649 48.9773 122.96 48.0422 122.415C47.0681 121.831 46.7174 120.584 47.3019 119.649Z" fill="#212121"/>
<path d="M56.5915 128.228L61.6567 125.306C62.5918 124.761 63.8386 125.072 64.3841 126.046C64.9296 127.02 64.6179 128.228 63.6438 128.813L58.5786 131.735C57.6435 132.28 56.3967 131.969 55.8512 130.995C55.3057 130.021 55.6174 128.774 56.5915 128.228Z" fill="#212121"/>
<path d="M61.024 140.424H66.8684C67.9594 140.424 68.8555 141.32 68.8555 142.45C68.8555 143.541 67.9594 144.476 66.8684 144.476H61.024C59.933 144.476 59.0369 143.58 59.0369 142.45C59.0369 141.32 59.933 140.424 61.024 140.424Z" fill="#212121"/>
<rect x="21.5146" y="148.863" width="29.1703" height="4.23406" rx="2.11703" :fill="secondary" stroke="#212121" stroke-width="2"/>
<defs>
<filter id="filter0_d_1786_48884" x="100.495" y="0" width="107.038" height="93.834" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="9" dy="-7"/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1786_48884"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1786_48884" result="shape"/>
</filter>
</defs>
</svg>


</template>

<script>
    export default {
        name: 'StepTowSVG',
        props: {
            primary: {
                type: String,
                default: "#6200EE"
            },
            secondary: {
                type: String,
                default: "#15B9A6"
            },
        },
    }
</script>