<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1029.45 419.42">
        <path
            d="M1472,716.73c9.2-2.63,14.5-5.66,14.5-8.89,0-10.13-51.74-18.34-115.61-18.34s-129.5,8.21-129.5,18.34c0,3.23,4.69,6.26,13.89,8.89Z"
            transform="translate(-457.02 -308.21)" style="fill:#6e6e6e;fill-opacity:0.150000005960464" />
        <path
            d="M489.66,683.27a22.28,22.28,0,0,1,18.49-31.63l270-20.62a209.13,209.13,0,0,1,37.78.54l496.25,52-7.48,2.46a544.92,544.92,0,0,1-141.06,26.44l-285.7,15.22L508,696A22.29,22.29,0,0,1,489.66,683.27Z"
            transform="translate(-457.02 -308.21)" style="fill:#6e6e6e;fill-opacity:0.150000005960464" />
        <path
            d="M1295.43,595.57c0,50-32.88,88.65-71.24,88.65S1153,645.62,1153,595.57s32.88-88.66,71.23-88.66S1295.43,545.51,1295.43,595.57Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M793.18,650.8,595.33,633.41c-33.05,0-103.2-5.65-119.36-28.21-20.21-28.21-11.75-117-11.75-127.4a24,24,0,0,1,4.41-13.45c2.28-3.28,5.4-5.91,8.09-8.87l49.06-54.16,58.42-65.73a65,65,0,0,1,48.69-21.94h406.5c17.33,0,32.92,7.68,42.79,21.94,19.56,28.26,50.74,74.74,57.25,85.21,8.65,13.87,24.09,21.27,40.44,21.27h65.39c46.81,0,85.06,28.41,84,75.21a591.59,591.59,0,0,1-6.46,74.7c-4,25.61-26.56,42.2-52.46,43.39L935.1,650.8Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M1015.13,518.53c0,15.3-9.9,25.71-19.9,25.71s-19.89-10.41-19.89-25.71,9.9-25.7,19.89-25.7S1015.13,503.23,1015.13,518.53Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M1315,514.78c0,15.3-9.91,25.7-19.9,25.7s-19.89-10.4-19.89-25.7,9.9-25.7,19.89-25.7S1315,499.48,1315,514.78Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M782.69,334.21h273.15a5.47,5.47,0,0,1,4.46,2.31L1125.24,429a5.45,5.45,0,0,1-4.46,8.58H805.86a5.46,5.46,0,0,1-5.29-4.12L777.41,341A5.45,5.45,0,0,1,782.69,334.21Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M608.63,337H746.14a5.45,5.45,0,0,1,5.41,4.79l11,91.14a5.45,5.45,0,0,1-5.41,6.1H528.73a5.45,5.45,0,0,1-4.1-9l79.9-91.13A5.47,5.47,0,0,1,608.63,337Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M1053.68,577.86l199.09-9.19a5.46,5.46,0,0,1,5.7,5.39l.31,33a5.45,5.45,0,0,1-5.21,5.5l-199.08,8.7a5.45,5.45,0,0,1-5.69-5.39l-.31-32.47A5.43,5.43,0,0,1,1053.68,577.86Z"
            transform="translate(-457.02 -308.21)"
            :style="`fill:${color};stroke:#212121;stroke-width:10.8940697505939px`" />
        <path d="M656.43,472.37H632.92a8,8,0,1,0,0,15.95h23.51a8,8,0,1,0,0-15.95Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path d="M666.28,339.09,648.9,441.39" transform="translate(-457.02 -308.21)"
            style="fill:none;stroke:#212121;stroke-linejoin:round;stroke-width:10.8940697505939px" />
        <path
            d="M591.14,611c0,20.61-6.3,39.06-16.22,52.24S551.69,684,537.45,684s-27.54-7.65-37.46-20.83S483.76,631.58,483.76,611s6.3-39.07,16.23-52.25,23.22-20.83,37.46-20.83,27.54,7.65,37.47,20.83S591.14,590.35,591.14,611Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M574.37,610.81c0,14.35-4.41,27.13-11.28,36.2s-16,14.2-25.57,14.2S518.83,656.09,512,647s-11.28-21.85-11.28-36.2,4.41-27.12,11.28-36.2,16-14.2,25.56-14.2,18.7,5.13,25.57,14.2S574.37,596.47,574.37,610.81Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M922.23,614.34c0,24.9-7.67,47.24-19.83,63.24S873.87,703,856.19,703s-34.06-9.41-46.21-25.41-19.82-38.34-19.82-63.24,7.67-47.25,19.82-63.25,28.54-25.41,46.21-25.41,34.06,9.42,46.21,25.41S922.23,589.43,922.23,614.34Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M901.45,614.57c0,17.45-5.36,33-13.76,44.16s-19.61,17.5-31.54,17.5-23.15-6.38-31.55-17.5S810.84,632,810.84,614.57s5.36-33.06,13.76-44.17,19.61-17.5,31.55-17.5,23.14,6.39,31.54,17.5S901.45,597.11,901.45,614.57Z"
            transform="translate(-457.02 -308.21)" style="fill:#fff;stroke:#212121;stroke-width:10.8940697505939px" />
        <path
            d="M1451.31,702.06a101.25,101.25,0,0,0,12.56-48.92c0-55.67-44.81-100.84-100.12-100.84s-100.13,45.17-100.13,100.84a101.15,101.15,0,0,0,12.57,48.92Z"
            transform="translate(-457.02 -308.21)"
            style="fill:#fff;stroke:#212121;stroke-miterlimit:10;stroke-width:10.8940697505939px" />
        <path
            d="M1323.53,611l-1.33,1.57a6.67,6.67,0,0,0,.6,9.42l32.61,28.63v.6a12.44,12.44,0,1,0,12.44-12.44,10.68,10.68,0,0,0-2,.24l-32.61-28.62A7,7,0,0,0,1323.53,611Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1288.39,658.94h-18.12a6.21,6.21,0,0,1-6.16-6.28,6.29,6.29,0,0,1,6.16-6.28h18.12a6.28,6.28,0,0,1,0,12.56Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1295.52,621.5,1280.78,611a6.25,6.25,0,0,1,7.13-10.26l14.73,10.51a6.2,6.2,0,0,1,1.45,8.69A6.08,6.08,0,0,1,1295.52,621.5Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1321.35,591.55l-9.78-15.22a6.2,6.2,0,0,1,10.39-6.76l9.78,15.22a6.2,6.2,0,0,1-1.81,8.57A6.32,6.32,0,0,1,1321.35,591.55Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1358.68,578.87l-.73-18.24a6.3,6.3,0,0,1,5.92-6.52,6.19,6.19,0,0,1,6.4,5.92l.73,18.11a6.3,6.3,0,0,1-5.92,6.52A5.85,5.85,0,0,1,1358.68,578.87Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1396.47,585.27l9.06-15.7a6.17,6.17,0,0,1,8.45-2.3,6.4,6.4,0,0,1,2.3,8.58l-9.06,15.7a6.23,6.23,0,1,1-10.75-6.28Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1427.39,610.27l15.7-9.06a6.18,6.18,0,0,1,8.46,2.3,6.31,6.31,0,0,1-2.3,8.57l-15.7,9.06a6.17,6.17,0,0,1-8.45-2.3A6.28,6.28,0,0,1,1427.39,610.27Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1440.06,648.07h18.12a6.21,6.21,0,0,1,6.16,6.28,6.29,6.29,0,0,1-6.16,6.28h-18.12a6.28,6.28,0,0,1,0-12.56Z"
            transform="translate(-457.02 -308.21)" style="fill:#212121" />
        <path
            d="M1406.73,673.86H1321a2.72,2.72,0,0,0-2.72,2.72V685a2.72,2.72,0,0,0,2.72,2.73h85.72a2.73,2.73,0,0,0,2.73-2.73v-8.43A2.72,2.72,0,0,0,1406.73,673.86Z"
            transform="translate(-457.02 -308.21)"
            :style="`fill:${color};stroke:#212121;stroke-width:5.44703487529697px`" /></svg>
</template>

<script>
    export default {
        name: "RewardsCarSVG",
        props: {
            color: {
                type: String,
                default: "#FFD51C"
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>