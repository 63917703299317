import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,_vm._b({staticClass:"card-center"},'v-card',_vm.$attrs,false),[_c(VCardTitle,{staticClass:"grow"},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-progress-download")]),_c('h1',{staticClass:"mt-5"},[_vm._v("Great news!!")])],1),_c(VCardText,[_c('h3',[_vm._v("We have a brand new version for you.")]),_c('p',[_vm._v(" click on the "),_c('strong',[_vm._v("upgrade now")]),_vm._v(" button"),_c('br'),_vm._v(" to enjoy a better experience in our app. ")])]),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","x-large":"","elevation":"10","color":"primary"},on:{"click":_vm.upgrade}},[_vm._v(" upgrade now "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-progress-download")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }