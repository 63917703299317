<template>
    <v-app-bar dense app :fixed="false" hide-on-scroll :flat="$vuetify.breakpoint.lgAndUp"
        :clipped-left="$vuetify.breakpoint.lgAndUp" color="white">
        <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>

        <v-toolbar-title v-html="title"></v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <v-icon color="accent" class="mr-3" v-if="offline">$vuetify.icons.global_offline</v-icon>
        <v-icon color="accent" class="mr-3" v-if="isWeakSignal">$vuetify.icons.global_weak_signal</v-icon> -->

        <v-btn icon small to="/vehicles">
            <v-avatar :size="36" v-if="!activeHttpRequests">
                <BrandLogo />
            </v-avatar>
            <BrandLogoLoader :size="36" :gutter="0" v-else />
        </v-btn>
        <span></span>
    </v-app-bar>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        name: "NavbarTop",

        props: {
            value: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            ...mapGetters(["activeHttpRequests", "connection", "offline"]),
            isWeakSignal() {
                return this.connection != "3g" && this.connection != "4g";
            },

            title() {
                return this.$route.meta.title;
            },
        },

        methods: {
            toggleDrawer() {
                this.$emit("input", !this.value);
            }
        },
    }
</script>