<template>
    <div class="router-view-center">
        <transition name="fade" mode="out-in">
            <v-card tile flat full-width fill-height class="card-center" color="primary">
                <v-spacer />
                <v-card-text>
                    <BrandLogoLoader :infinite="loading" :color="isPrimaryColorDark ? 'white' :'black'" />
                    <h4 class="opacity-low mt-3">Make Yourself Woth!</h4>
                    <h3 v-if="user">{{ user.name || user.email }}</h3>
                </v-card-text>
                <v-spacer />
            </v-card>
        </transition>
    </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        name: "DeviceInstalledStart",

        computed: {
            ...mapGetters(["user", "activeHttpRequests", "isPrimaryColorDark"]),
            loading() {
                return this.activeHttpRequests;
            }
        },

        watch: {
            loading(v) {
                if (!v) {
                    this.$router.push("/vehicles");
                }
            }
        },

        mounted() {
            if (!this.user) {
                this.$router.push("/");
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>