import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"dense":"","app":"","fixed":false,"hide-on-scroll":"","flat":_vm.$vuetify.breakpoint.lgAndUp,"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"color":"white"}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer.apply(null, arguments)}}}),_c(VToolbarTitle,{domProps:{"innerHTML":_vm._s(_vm.title)}}),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":"","to":"/vehicles"}},[(!_vm.activeHttpRequests)?_c(VAvatar,{attrs:{"size":36}},[_c('BrandLogo')],1):_c('BrandLogoLoader',{attrs:{"size":36,"gutter":0}})],1),_c('span')],1)}
var staticRenderFns = []

export { render, staticRenderFns }