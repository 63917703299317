<template>
    <div class="router-view-center">
        <v-card tile flat full-width fill-height class="card-center" color="primary">
            <v-spacer />
            <v-card-text>
                <BrandLogoLoader ripple circle infinite color="accent" />
                <h3 v-if="user">{{ user.name || user.email }}</h3>
            </v-card-text>
            <v-spacer />
            <v-card-actions v-if="error">
                <v-btn large block depressed outlined color="accent" to="/login">Try Again</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import {
        getAuth,
        getRedirectResult,
    } from "firebase/auth";

    // import api from "@/services/api-service";

    export default {
        name: 'Home',

        computed: {
            ...mapGetters(["user", "partner", "customer", "device", "activeHttpRequests", "isPrimaryColorDark"]),
        },

        watch: {
            error(v, ov) {
                if (v) {
                    this.$store.commit("ADD_NOTIFICATION", {
                        text: v,
                        timeout: 3000
                    })
                    this._redirect();
                }
            }
        },


        data() {
            return {
                loading: true,
                error: false
            }
        },

        methods: {
            _redirect(){
                const show = localStorage.getItem('show-onboarding');
                if (!show && !this.partner){
                    this.$router.replace("/on-boarding");
                }else{
                    this.$router.replace("/login");
                }
            },

            async _getRedirectResult() {
                try {

                    this.loading = true;
                    const auth = getAuth();
                    let result = await getRedirectResult(auth);

                    if(!result && !this.user){
                        setTimeout(() => {
                            this._redirect();
                        }, 1250);
                    }

                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "_getRedirectResult"
                    }, error);
                    this.error = "Ooops! please try again."
                } finally {
                    this.displayLogin = true;
                    this.loading = false;
                }
            },
        },

        mounted() {
            this._getRedirectResult();
        }
    }
</script>

<style scoped>
    .login-btns .v-btn {
        text-transform: initial !important;
        font-weight: bolder;
        text-align: left;
        /* background-color: white; */
    }

    .login-btns>>>.v-btn__content {
        align-items: center;
        justify-content: flex-start;
    }
</style>