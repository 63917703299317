<template>
  <v-card tile flat full-width fill-height class="card-center">
    <v-card-text class="pa-0 grow">
      <v-carousel :show-arrows="false" :continuous="false" hide-delimiters v-model="step" height="60vh">
        <v-carousel-item>
          <v-card class="card-center" fill-height flat>
            <v-card-title class="pa-0">
              <BrandLogoLoader :infinite="false" v-if="partner" style="margin:12vh auto" />
              <StepOneSVG id="step-1-svg" v-else />
            </v-card-title>
            <v-card-text class="pt-10">
              <h1>So glad you’re here!</h1>
              <p>We’re on a mission to help you be in control of your personal data, understand its value, and earn from
                it.</p>
            </v-card-text>
          </v-card>
        </v-carousel-item>

        <v-carousel-item>
          <v-card class="card-center" fill-height flat>
            <v-card-title class="pa-0">
              <StepTowSVG id="step-2-svg" :primary="$vuetify.theme.currentTheme.primary"
                :secondary="$vuetify.theme.currentTheme.secondary" />
            </v-card-title>
            <v-card-text class="pt-10">
              <h1><b class="primary--text">Own</b> your data<br /> and earn from it.</h1>
              <p>Certify and sell your personal data to companies that want to work directly with you.</p>
            </v-card-text>
          </v-card>
        </v-carousel-item>

        <v-carousel-item>
          <v-card class="card-center" fill-height flat>
            <v-card-title class="pa-0">
              <StepThreeSVG id="step-3-svg" :primary="$vuetify.theme.currentTheme.primary"
                :secondary="$vuetify.theme.currentTheme.secondary" />
            </v-card-title>
            <v-card-text class="pt-10">
              <h1>Start with mileage.</h1>
              <p>Simply snap photos of your vehicle and odometer to start self-reporting your monthly mileage
                data.<br /><br />Let’s get started!</p>
            </v-card-text>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>

    <v-spacer />
    <v-card-actions class="px-5">
      <v-btn text @click="skip">skip</v-btn>
      <v-spacer />
      <v-radio-group v-model="step" row dense>
        <v-radio :value="0" color="primary"></v-radio>
        <v-radio :value="1" color="primary"></v-radio>
        <v-radio :value="2" color="primary"></v-radio>
      </v-radio-group>
      <v-spacer />
      <v-btn text color="primary" @click="nextStep">next</v-btn>
    </v-card-actions>
    <v-spacer />
  </v-card>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  import StepOneSVG from '@/components/onboarding/StepOneSVG'
  import StepTowSVG from '@/components/onboarding/StepTowSVG'
  import StepThreeSVG from '@/components/onboarding/StepThreeSVG'
  import BrandLogo from '../components/appshell/BrandLogo.vue'
  import BrandLogoLoader from '../components/appshell/BrandLogoLoader.vue'

  export default {
    name: 'OnBoarding',

    components: {
      StepOneSVG,
      StepTowSVG,
      StepThreeSVG,
      BrandLogo,
      BrandLogoLoader
    },

    data() {
      return {
        step: 0,
        steps: 3,
        items: [{
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
      }
    },

    computed: {
      ...mapGetters(["ipinfo", "user", "partner", "device"]),
    },

    methods: {
      _redirect() {
        localStorage.setItem('show-onboarding', false);
        this.$router.replace("/login");
      },
      nextStep() {
        if (this.step >= this.steps - 1) {
          this._redirect();
          return;
        }
        this.step = this.step + 1;

        this.$Logger.track(`onboarding_flow`, {
          component: this.$options.name,
          step: this.step + 1
        });
      },
      skip() {
        this._redirect();
      }
    },

    mounted() {
      this.$Logger.track(`onboarding_flow`, {
        component: this.$options.name,
        step: this.step + 1
      });
    },
  }
</script>

<style scoped>
  .v-card__title>svg {
    height: 35vh;
  }

  #step-1-svg {
    width: 50%;
  }

  #step-2-svg {
    width: 50%;
  }

  #step-3-svg {
    width: 50%;
  }

  .v-card__text>h1 {
    margin-bottom: 15px;
    letter-spacing: 1px;
    font-size: 28px;
    line-height: 30px;
    /* font-family: 'Merriweather'; */
  }
</style>